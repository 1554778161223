import React,{useEffect,useState} from "react";
import { Table, Button,Modal, Space, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import NotificationService from "../../../services/antNotificationService";
import  { colors}  from "../../../environments/environment"
import moment from 'moment';
import { useLocation  } from 'react-router-dom';
import AlarmRemove from "./alarmRemove";
import { SearchOutlined } from "@ant-design/icons";
const AlarmList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const record = location.state && location.state.record;
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]); // Yeni state
  const [userData, setUserData] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [recordForModal, setRecordForModal] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      const userData = await FirebaseService.getUserByUid(localStorage.getItem("uid"));
      setUserData(userData);
      record.sort((a, b) => new Date(b.firstTransactionDateEdit) - new Date(a.firstTransactionDateEdit));
      setItems(record);
    };
    fetchData();
  }, []);
  const goEditPage = (record) => {
    navigate('/alarmdetail', { state: { record } })
  };
  const handleRemove = (record) => {
    setRecordForModal(record);
    setIsModalVisible(true);
};
  const handleModalCancel = () => {
    setIsModalVisible(false);
  };
  const handleRemoveFinish = () => {
    setIsModalVisible(false);
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    const filteredData = items.filter(item => {
      const value = selectedKeys[0]?.toLowerCase();
      const itemValue = item[dataIndex]?.toString().toLowerCase();
      return (
        !item.isDeleted &&
        (value ? itemValue.includes(value) : true)
      );
    });
    setFilteredItems(filteredData);
  };
  const handleReset = clearFilters => {
    clearFilters();
    setFilteredItems(items);
  };
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          id="search-input"
          placeholder={`Ara`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Ara
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Sıfırla
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890FF' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => document.getElementById('search-input').select(), 100);
      }
    },
    sorter: (a, b) => a[dataIndex].localeCompare(b[dataIndex]),
  });
  const columns = [
    {
      title:"Başlıklar",
      dataIndex: 'title',
      key: 'title',
      width: 300,
      render: (text,record) => <span style={{color:"red" }}>{text}</span>,
      ...getColumnSearchProps('title')
  },
    {
     title:"İş Kodu",
      dataIndex: 'jobCode',
      key: 'jobCode',
      width: 200,
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text === 'raw' ? 'Saç Levha' : text === 'other' ? 'Diğer' : text}
      </span>,
     ...getColumnSearchProps('jobCode')
    },
    {
      title:" ",
       dataIndex: 'isRequestRemoval',
       key: 'isRequestRemoval',
       width: 200,
       render: (text,record) => <span style={{color: record.isRequestRemoval ? "#84BB4C" : "#EF6C6C" }}> {text ? 'Kaldırma Talep Edildi' : "Kaldırma Talep Edilmedi"}
       </span>,
     },
    {
        title: ' ',
        dataIndex: 'edit',
        key: 'edit',
        render: (text, record) => (
          <Button onClick={()=>goEditPage(record)} style={{backgroundColor:"white" ,color:colors.blue,fontWeight:"500",border:"none"}} >
          Detaya git
       </Button>
        ),
      },
      /*
      {
        title: ' ',
        dataIndex: '',
        key: '',
        render: (text, record) => (
         userData && userData.isManager ? (
            <Button onClick={() => handleRemove(record)} style={{ backgroundColor: colors.blue, color: "white", fontWeight: "500" }}>
              Alarm Kaldır
            </Button>
          ) : null
        ),
      }*/
  ];
  return  <div style={{
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '94vh',
        padding:"3%",
      }}>
        <h3  style={{backgroundColor:"white" ,color:colors.blue,fontWeight:"700", border:"none",float:"left"}}>Alarmlar</h3>
          <Table
            locale={{
              emptyText: 'Henüz alarm eklemediniz...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
            dataSource={filteredItems}
            columns={columns}
            showHeader={true}
            pagination={false}
            className="custom-news-table"
            rowClassName="custom-news-row"
        />
        <Modal
            title="Alarmı Kaldırma"
            visible={isModalVisible}
            onCancel={handleModalCancel}
            footer={null}
          >
            <AlarmRemove  record={recordForModal}  onClose={handleRemoveFinish} />
          </Modal>
    </div>
  };
  export default AlarmList;