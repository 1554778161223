import React, { useEffect, useState } from 'react';
import { Table, Button, Input, Space, Spin } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import FirebaseService from "../../services/firebaseService";
import NotificationService from "../../services/antNotificationService";
import { useNavigate } from 'react-router-dom';
const UserList = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, []);
  const goAddPage = () => {
    navigate('/useradd');
  };
  const handleDelete = async (record, isDeleted) => {
    setLoading(true);
    try {
      let result;
      if (isDeleted) {
        result = await FirebaseService.makeUserVisible(record.key);
      } else {
        result = await FirebaseService.deleteUser(record.key);
      }
      if (result.success) {
        NotificationService.openSuccessNotification({
          title: 'İşlem Başarılı',
          description: result.message,
          placement: 'topRight',
        });
        setLoading(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        fetchData();
      } else {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
          placement: 'topRight',
        });
        setLoading(false);
      }
    } catch (error) {
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "Bilinmeyen bir hata ile karşılaşıldı.",
        placement: 'topRight',
      });
      setLoading(false);
    }
  };
  const fetchData = async () => {
    const data = await FirebaseService.getUserData();
    const filteredData = data.filter(item => !item.isDeleted);
    const sortedData = filteredData.sort(
      (a, b) => new Date(b.firstTransactionDate) - new Date(a.firstTransactionDate)
    );
    setUsers(sortedData);
    setFilteredUsers(sortedData);
  };
  const goEditPage = (record) => {
    navigate('/useredit', { state: { record } });
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    const filteredData = users.filter(item => {
      const value = selectedKeys[0]?.toLowerCase();
      const itemValue = item[dataIndex]?.toString().toLowerCase();
      return (
        !item.isDeleted &&
        (value ? itemValue.includes(value) : true)
      );
    });
    setFilteredUsers(filteredData);
  };
  const handleReset = clearFilters => {
    clearFilters();
    setFilteredUsers(users);
  };
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          id="search-input"
          placeholder={`Ara`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Ara
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Sıfırla
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890FF' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => document.getElementById('search-input').select(), 100);
      }
    },
    sorter: (a, b) => a[dataIndex].localeCompare(b[dataIndex]),
  });
  const columns = [
    {
      title: 'İsim Soyisim',
      dataIndex: 'name',
      key: 'name',
      width: 400,
      ...getColumnSearchProps('name'),
      render: (text, record) => (
        <span style={{ color: record.isDeleted ? "#00000080" : "#000000", fontWeight: "bold" }}>
          {record.name}  {record.surname}
        </span>
      ),
    },
    {
      title: 'Ünvan/Ünvanlar',
      dataIndex: 'title',
      key: 'title',
      width: 400,
      ...getColumnSearchProps('title'),
      sorter: (a, b) => {
        const aValue = Array.isArray(a.title) ? a.title.join(', ') : a.title;
        const bValue = Array.isArray(b.title) ? b.title.join(', ') : b.title;
        return (aValue || '').localeCompare(bValue || '');
      },
      render: (text, record) => {
        const titles = Array.isArray(text) ? text.join(', ') : '';
        return (
          <span style={{ color: record.isDeleted ? "#00000080" : "#000000", fontWeight: "bold" }}>
            {titles}
          </span>
        );
      },
    },
    {
      title: 'Birim',
      dataIndex: 'userUnit',
      key: 'userUnit',
      width: 400,
      ...getColumnSearchProps('userUnit'),
      sorter: (a, b) => {
        const aValue = Array.isArray(a.userUnit) ? a.userUnit.join(', ') : a.userUnit;
        const bValue = Array.isArray(b.userUnit) ? b.userUnit.join(', ') : b.userUnit;
        return (aValue || '').localeCompare(bValue || '');
      },
      render: (text, record) => {
        const units = Array.isArray(text) ? text.join(', ') : '';
        return (
          <span style={{ color: record.isDeleted ? "#00000080" : "#000000", fontWeight: "bold" }}>
            {units}
          </span>
        );
      },
    },
    {
      title: 'Birim Sorumlusu',
      dataIndex: 'isManager',
      key: 'isManager',
      width: 400,
      render: (text, record) => (
        <span style={{ color: record.isDeleted ? "#00000080" : "#000000", fontWeight: "bold" }}>
          {text ? 'Evet' : 'Hayır'}
        </span>
      ),
    },
    {
      title: ' ',
      dataIndex: 'delete',
      key: 'delete',
      render: (text, record) => (
        record.isDeleted ?
          <Button style={{ backgroundColor: "#1A446C", color: "white", borderRadius: 0 }} onClick={() => handleDelete(record, true)}>Göster</Button>
          :
          <Button style={{ backgroundColor: "#1A446C", color: "white", borderRadius: 0 }} onClick={() => handleDelete(record, false)}>Sil</Button>
      ),
    },
    {
      title: 'Güncelle',
      dataIndex: 'edit',
      key: 'edit',
      render: (text, record) => (
        <a type="link" onClick={() => goEditPage(record)} style={{
          border: "none",
          backgroundColor: "transparent",
          pointerEvents: record.isDeleted ? "none" : "auto",
          opacity: record.isDeleted ? 0.5 : 1,
        }}>
          <img style={{ width: "20px" }} alt="edit" src="/images/newEditButton.png" />
        </a>
      ),
    },
  ];
  return (
    <div style={{
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '94vh',
      padding: "3%"
    }}>
      <Button onClick={() => goAddPage()} icon={<img alt="plus" src="/images/plusbutton.png" style={{ width: "60%" }} />} className='admin-add-button'>
        Yeni Ekle
      </Button>
      <Input
        style={{
          borderRadius: 0,
          border: 'none',
          borderBottom: '1px solid #1A446C80',
          width: '100%',
          maxWidth: '400px',
          textAlign: 'left',
          outline: 'none',
        }}
        placeholder="Kullanıcı Ara"
        suffix={
          <Space>
            <img width={15} alt="search" src="/images/searchicon.png" />
          </Space>
        }
        onChange={(e) => {
          const searchValue = e.target.value.toLowerCase();
          const filteredData = users.filter(user => {
            const fullName = (user.name + ' ' + user.surname).toLowerCase();
            return fullName.includes(searchValue);
          });
          setFilteredUsers(filteredData);
        }}
      />
      <Table
        locale={{
          emptyText: 'Henüz kullanıcı eklemediniz...',
          filterReset: 'Sıfırla',
          filterTitle: 'Filtre Menüsü',
          selectAll: 'Hepsini Seç',
          selectInvert: 'Tersini Seç',
          selectionAll: 'Tümünü Seç',
          sortTitle: 'Sıralama',
          triggerDesc: 'Azalan sıralama için tıklayın',
          triggerAsc: 'Artan sıralama için tıklayın',
          cancelSort: 'Sıralamayı iptal etmek için tıklayın',
        }}
        pagination={false}
        showHeader={true}
        columns={columns}
        dataSource={filteredUsers}
        className="custom-news-table"
        rowClassName="custom-news-row"
      />
      {loading && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000,
        }}>
          <Spin />
        </div>
      )}
    </div>
  );
};
export default UserList;
