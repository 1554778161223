import React,{useEffect,useState} from "react";
import { Table, Button,Modal, Space, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import NotificationService from "../../../services/antNotificationService";
import  { colors}  from "../../../environments/environment"
import moment from 'moment';
import { useLocation  } from 'react-router-dom';
import ContractManufacturingEdit from "./contractManufacturingEdit";
import ContractManufacturingDisplayDetail from "./contractManufacturingDisplayDetail";
import { SearchOutlined } from "@ant-design/icons";
const ContractManufacturingDisplayList = () => {
  const [items, setItems] = useState('');
  const [filteredItems , setFilteredItems] = useState('');
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      const data = await FirebaseService.getContractManufacturingData();
      const sortedItems = data
      ? [...data].sort((a, b) => {
          const jobCodeA = a.jobCode || '';
          const jobCodeB = b.jobCode || '';
          return jobCodeA.localeCompare(jobCodeB);
        })
      : [];
      setItems(sortedItems);
      setFilteredItems(sortedItems)
    };
    fetchData();
}, []);
  const [recordForModal, setRecordForModal] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const goEditPage = (record) => {
    setRecordForModal(record);
    setIsModalVisible(true);
  };
  const handleFinish = () => {
    setIsModalVisible(false);
  };
  const handleModalCancel = () => {
    setIsModalVisible(false);
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    const filteredData = items.filter(item => {
      const value = selectedKeys[0]?.toLowerCase();
      const itemValue = item[dataIndex];
      // itemValue null ya da undefined ise boş string kullan
      const itemStringValue = itemValue ? itemValue.toString().toLowerCase() : '';
      return (
        !item.isDeleted &&
        (value ? itemStringValue.includes(value) : true)
      );
    });
    setFilteredItems(filteredData);
  };
  const handleReset = clearFilters => {
    clearFilters();
    setFilteredItems(items);
  };
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          id="search-input"
          placeholder={`Ara`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Ara
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Sıfırla
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890FF' : undefined }} />,
    onFilter: (value, record) => {
      const recordValue = record[dataIndex];
      // record[dataIndex] null veya undefined ise güvenli bir işlem yap
      const recordStringValue = recordValue ? recordValue.toString().toLowerCase() : '';
      return recordStringValue.includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => document.getElementById('search-input').select(), 100);
      }
    },
    sorter: (a, b) => {
      const aValue = a[dataIndex] ? a[dataIndex].toString() : '';
      const bValue = b[dataIndex] ? b[dataIndex].toString() : '';
      return aValue.localeCompare(bValue);
    },
  });
  const columns = [
    {
      title: 'Takip Kodu',
      dataIndex: 'code',
      key: 'code',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text ? text : "-"}
      </span>,
      ...getColumnSearchProps('code')
    },
    {
      title: 'Firma',
      dataIndex: 'companyName',
      key: 'companyName',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
      ...getColumnSearchProps('companyName')
  },
  {
    title: 'İş Kodu',
    dataIndex: 'jobCode',
    key: 'jobCode',
    render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text ? text : "-"}
    </span>,
    ...getColumnSearchProps('jobCode')
  },
  {
    title: 'Proje Kodu',
    dataIndex: 'projectCode',
    key: 'projectCode',
    render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text ? text : "-"}
    </span>,
    ...getColumnSearchProps('projectCode')
  },
  {
    title: 'Tedarikçi',
    dataIndex: 'supplierName',
    key: 'supplierName',
    render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000"}}>{text}</span>,
    ...getColumnSearchProps('supplierName')
  },
  {
    title: 'Durum',
    dataIndex: '',
    key: '',
    render: (text, record) => (
      <span style={{ color: record.isRequestCompleted === true ? "green" : "#000000" }}>
        {record.isRequestCompleted === true ? "Talep Tamamlandı" : "Talebi Tamamla"}
      </span>
    ),
  },
  {
    title: 'Proses',
    dataIndex: 'processName',
    key: 'processName',
    render: (text,record) =>
    <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
      {text}
  </span>,
  ...getColumnSearchProps('processName')
  },
    {
        title: 'Termin Tarihi',
        dataIndex: 'deadline',
        key: 'deadline',
        render: (text, record) => {
          const formattedDeadline = record.deadline ? new Date(record.deadline.seconds * 1000).toLocaleDateString('tr-TR') : '';
          return <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>{formattedDeadline}</span>;
        },
      },
      {
        title: ' ',
        dataIndex: 'edit',
        key: 'edit',
        render: (text, record) => (
          <Button onClick={()=>goEditPage(record)} style={{backgroundColor:"white" ,color:colors.blue,fontWeight:"500",border:"none"}} >
          Detaya git
       </Button>
        ),
      },
  ];
  return  <div style={{
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '94vh',
        padding:"3%",
      }}>
        <h3 style={{backgroundColor:"white" ,color:colors.blue,fontWeight:"700", border:"none",float:"left"}}>Fason Üretim Talep</h3>
          <Table
            locale={{
              emptyText: 'Henüz fason üretim talebi eklemediniz...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
            dataSource={filteredItems}
            columns={columns}
            showHeader={true}
            pagination={false}
            className="custom-news-table"
            rowClassName="custom-news-row"
           /* expandable={{
              expandedRowRender: record => (
                <div style={{ display: 'flex' }}>
            <div style={{ marginLeft: "55px" }}>
  <span style={{ fontWeight: "bold" }}>Parçalar:</span>
  <div>
    {record.parts.map((part, index) => (
      <div key={index} style={{ color: record.isDeleted ? "#00000080" : "#000000", marginLeft: "5px" }}>
        {` Parça Adı: ${part.partCode}, Adet: ${part.piece}, Birim Fiyatı: ${part.unitPrice}`}
      </div>
    ))}
  </div>
</div>
            </div>
              ),
              expandIcon: ({ expanded, onExpand, record }) =>
                expanded ? (
                  <a type="link" onClick={e => onExpand(record, e)} style={{border:"none",backgroundColor:"transparent"}}><img style={{width:"20px"}} alt="edit" src="/images/uparrow.png" /></a>
                ) : (
                  <a type="link" onClick={e => onExpand(record, e)} style={{border:"none",backgroundColor:"transparent"}}><img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" /></a>
                ),
                expandIconColumnIndex: 6
            }}
            */
        />
        <Modal
          title="Fason Üretim Detay Görüntüleme"
          visible={isModalVisible}
          onCancel={handleModalCancel}
          footer={null}
        >
          <ContractManufacturingDisplayDetail  record={recordForModal}  onClose={handleFinish} />
        </Modal>
    </div>
  };
  export default ContractManufacturingDisplayList;