

import React, { useEffect, useState } from "react";
import { Table, Input, Button, Space,Modal,Form,DatePicker, Tooltip, Image } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import { colors, parsCollections, placeholderMap } from "../../../environments/environment";
import { useLocation } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import NotificationService from "../../../services/antNotificationService";
import { bool } from "prop-types";
import moment from "moment";

const ProcessStatusList = () => {
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
      
      const setupData = await FirebaseService.getSetupData();
      const data = setupData.filter(item => 
        !item.isDeleted &&
        ((item.stock?.length || 0) !== 0 || (item.wastage?.length || 0) !== 0)
      );
      const partsData = await FirebaseService.getPartsData();
  
      const partsMap = new Map();
      partsData.forEach(part => {
        const key = `${part.jobCode}_${part.partNo}`;
        partsMap.set(key, part);
      });
  
      const processedData = data.map(item => {
        const partsStatus = item.parts.map(part => {
          const key = `${item.jobCode}_${part.partNo}`;
          const additionalData = partsMap.get(key);
          const status = item.plates.find(p => p.programNo === part.programNo)?.status || "Unknown";
  
          return { 
            ...part,
            ...additionalData,
            status
          };
        });
  
        return {
          ...item,
          parts: partsStatus
        };
      });
      
      setItems(processedData);
      setFilteredItems(processedData);

      
    };
    
    fetchData();
  }, []);


 

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    const filteredData = items.filter((item) => {
      const value = selectedKeys[0]?.toLowerCase();
      
      if (dataIndex === 'operator') {
        // Search in laser operators
        const laserOperators = item.plates
          ?.map(plate => plate.lazerUserNameSurname)
          .filter(name => name && name !== null)
          .join(', ')
          .toLowerCase();

        // Search in abkant operators
        const abkantOperators = item.parts
          ?.flatMap(part => 
            part.operations
              ?.filter(op => op.isQrScan)
              ?.map(op => op.firstUserNameSurname)
              ?.filter(name => name && name !== null)
          )
          .join(', ')
          .toLowerCase();

        return !item.isDeleted && 
          (value ? (laserOperators?.includes(value) || abkantOperators?.includes(value)) : true);
      }

      // Original search logic for other columns
      const itemValue = item[dataIndex]?.toString().toLowerCase();
      return !item.isDeleted && (value ? itemValue?.includes(value) : true);
    });
    setFilteredItems(filteredData);
  };
  
  const handleReset = (clearFilters) => {
    clearFilters();
    setFilteredItems(items);
  };
  
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          id="search-input"
          placeholder={dataIndex === 'operator' ? 'Operatör Ara' : `${placeholderMap[dataIndex] || dataIndex} Ara`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, background: colors.blue }}
          >
            Ara
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Sıfırla
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      if (dataIndex === 'operator') {
        const laserOperators = record.plates
          ?.map(plate => plate.lazerUserNameSurname)
          .filter(name => name && name !== null)
          .join(', ')
          .toLowerCase();

        const abkantOperators = record.parts
          ?.flatMap(part => 
            part.operations
              ?.filter(op => op.isQrScan)
              ?.map(op => op.firstUserNameSurname)
              ?.filter(name => name && name !== null)
          )
          .join(', ')
          .toLowerCase();

        return laserOperators?.includes(value.toLowerCase()) || 
               abkantOperators?.includes(value.toLowerCase());
      }

      const recordValue = record[dataIndex]?.toString().toLowerCase();
      return recordValue?.includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => document.getElementById('search-input').select(), 100);
      }
    },
    sorter: (a, b) => {
      if (dataIndex === 'operator') {
        const getOperators = (record) => {
          const laser = record.plates
            ?.map(plate => plate.lazerUserNameSurname)
            .filter(name => name && name !== null)
            .join(', ');
          
          const abkant = record.parts
            ?.flatMap(part => 
              part.operations
                ?.filter(op => op.isQrScan)
                ?.map(op => op.firstUserNameSurname)
                ?.filter(name => name && name !== null)
            )
            .join(', ');
          
          return `${laser} ${abkant}`.toLowerCase();
        };

        return getOperators(a).localeCompare(getOperators(b));
      }

      const aValue = a[dataIndex];
      const bValue = b[dataIndex];

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return aValue.localeCompare(bValue);
      }
      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return aValue - bValue;
      }
      return 0;
    },
  });


 
  const columns = [
    
      {
        title: 'Müşteri Firma',
      dataIndex: 'companyName',
      key: 'companyName',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
      ...getColumnSearchProps('companyName')
      
    },
    {
      title: 'İş Kodu',
      dataIndex: 'jobCode',
      key: 'jobCode',
      render: (text, record) => <span style={{ color: items.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
      width: 200,
      ...getColumnSearchProps('jobCode'),
    },
    {
      title: 'Proje Kodu',
      dataIndex: 'projectCode',
      key: 'projectCode',
      render: (text, record) => <span style={{ color: items.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
      width: 200,
      ...getColumnSearchProps('projectCode'),
    },
    {
      title: 'Programcı',
      dataIndex: 'programmerName',
      key: 'programmerName',
      render: (text, record) => {
        // Eğer programmerName bir dizi ise, ilk elemanı al.
        const name = record.programmerName ? record.programmerName : 'Bilinmiyor';
        return name;
      },
      ...getColumnSearchProps('programmerName')
    },
    
    {
      title: 'Operatör',
      dataIndex: ' ',
      key: ' ',
      render: (text, record) => {
        
        const laserOperators = [];
        // Lazer operatörlerini toplamak için döngü
        if (record.plates) {
          for (const plate of record.plates) {
            const name = plate.lazerUserNameSurname;
            // Eğer name tanımlı ve null değilse listeye ekle
            if (name && name !== null) {
              if (!laserOperators.includes(name)) {
                laserOperators.push(name);
              }
            }
          }
        }
    
        
        const abkantOperators = [];
        let hasAbkantOperation = false
         // Abkant operatörlerini toplamak için döngü
         if (record.parts) {
          for (const part of record.parts) {
            if (part.operations) {
              for (const operation of part.operations) {
                const userName = operation.firstUserNameSurname;
                if (operation.isQrScan && userName && userName !== null && userName !== undefined) {
                  if (!abkantOperators.includes(userName)) {
                    abkantOperators.push(userName);
                  }
                }
        
                // processName'in tanımlı olup olmadığını kontrol ediyoruz
                if (operation.processName && operation.processName.toLowerCase() === 'abkant' && !hasAbkantOperation) {
                  hasAbkantOperation = true;
                }
              }
            }
          }
        }
        
    
        return (
          <div style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
            <div>Lazer - {laserOperators.length > 0 ? laserOperators.join(', ') : 'Başlamadı'}</div>
           {hasAbkantOperation && ( <div>Abkant - {abkantOperators.length > 0 ? abkantOperators.join(', ') : 'Başlamadı'}</div> )}
          </div>
        );
      },
      ...getColumnSearchProps('operator')
    },

    {
      title: 'Termin Tarihi',
      dataIndex: 'deadline',
      key: 'deadline',
      render: (text, record) => {
        const currentDate = moment().startOf('day');
        const isPastDue = moment(record.deadline.seconds * 1000).isBefore(currentDate);
  
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={{
                color: isPastDue ? 'red' : 'black',
                marginRight: isPastDue ? '5px' : '0',
              }}
            >
              {record.deadline ? moment(record.deadline.seconds * 1000).format('DD/MM/YYYY') : 'Bilinmiyor'}
            </span>
            {isPastDue && (
              <Tooltip title="Termin tarihi geçmiştir">
                <Image
                  width={25}
                  height={20}
                  src="/images/important.png"
                  preview={false}
                  marginBottom="25px"
                  marginLeft="10px"
                />
              </Tooltip>
            )}
          </div>
        );
      },
      sorter: (a, b) => {
        const dateA = a.deadline ? new Date(a.deadline.seconds * 1000) : null;
        const dateB = b.deadline ? new Date(b.deadline.seconds * 1000) : null;
  
        return dateA - dateB; // Ascending order
      },
    },

    //termin tarihi ekle
    
    {
        title: 'Durum',
        dataIndex: '',
        key: '',
        render: (text, record) => {
          // Lazer durumunu belirle
          let laserStatusText = "Başlamadı";
          let laserColor = "black";
        
          const allLaserFinish = record.plates.every(plate => plate.isFinish);
          const halfLaserFinish = record.plates.some(plate => plate.isFinish) && !allLaserFinish;
        
          if (allLaserFinish) {
            laserStatusText = "Tamamlandı";
            laserColor = "green";
          } else if (halfLaserFinish) {
            laserStatusText = "Devam Ediyor";
            laserColor = "orange";
          }
        
          let abkantStatusText = "Başlamadı";
          let abkantColor = "orange";
        
       
          if (Array.isArray(record.abkantIsFinish)) {
            abkantStatusText = record.abkantIsFinish.every(item => item.isFinish)
                ? "Tamamlandı"
                : "Tamamlanmadı";
            abkantColor = record.abkantIsFinish.every(item => item.isFinish)
                ? "green"
                : abkantColor;
        } else {
            console.error("record.abkantIsFinish is not an array:", record.abkantIsFinish, record.key);
            abkantStatusText = "Bilinmiyor"; 
            abkantColor = "black"; 
        }
        
         
          let otherOperations = [];
          const operationSet = new Set(); 

          if (record.parts?.length > 0) {
            record.parts.forEach(part => {
              // part.operations boşsa kontrol et
              if (part.operations?.length > 0) {
                part.operations.forEach(operation => {
                  // Eğer processName 'Abkant' değilse ve işlem Set'te yoksa ekle
                  if (operation.processName !== 'Abkant' && !operationSet.has(operation.processName)) {
                    operationSet.add(operation.processName); // İşlemi Set'e ekle
                    otherOperations.push(
                      <div 
                        key={operation.processName} 
                        style={{ color: "black", textAlign: "center", padding: "5px" }}
                      >
                        {operation.processName}
                      </div>
                    );
                  }
                });
              }
            });
          }
          

                  
          return (
            <div style={{ display: "flex", alignItems: "center", padding: "10px" }}>
              <div style={{ color: laserColor, padding: "10px", marginRight: "5px", textAlign: "center" }}>
                Lazer
              </div>

              <div style={{ color: abkantColor, padding: "10px", marginRight: "5px", textAlign: "center" }}>
                {otherOperations.some(operation => operation.key && operation.key.toLowerCase() === 'abkant') ? "Abkant" : null}
              </div>


              <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
                {otherOperations
                  .filter(operation => operation.key && operation.key.toLowerCase() !== 'abkant') // 'Abkant' işlemine sahip olanları filtrele
                  .map(filteredOperation => (
                    <div key={filteredOperation.key} style={{ color: "black", textAlign: "center", padding: "5px" }}>
                      {filteredOperation.key}
                    </div>
                  ))
                }
              </div>


            </div>
          );
                },
      
      }
      
     
  ];


  return <div style={{
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '94vh',
    padding: "3%",
  }}>
    <h3 style={{ textAlign: "left", color: colors.blue }}>İş Durum Listesi</h3>

    <div style={{color:colors.blue, textAlign:"left", fontSize:"12px"}}> **Belirtilen renklerden sarı: işlemin devam ettiğiniz, yeşil: işlemin bittiğini, siyah: işlemin daha başlamadığını göstermektedir.</div>

    <Table
      rowKey={(record) => record.jobCode }
      locale={{
        emptyText: 'Henüz iş bulunmamaktadır...',
        filterReset: 'Sıfırla',
        filterTitle: 'Filtre Menüsü',
        selectAll: 'Hepsini Seç',
        selectInvert: 'Tersini Seç',
        selectionAll: 'Tümünü Seç',
        sortTitle: 'Sıralama',
        triggerDesc: 'Azalan sıralama için tıklayın',
        triggerAsc: 'Artan sıralama için tıklayın',
        cancelSort: 'Sıralamayı iptal etmek için tıklayın',
      }}
      dataSource={filteredItems}
      columns={columns}
      showHeader={true}
      pagination={false}
      className="custom-news-table"
      rowClassName="custom-news-row"
      expandable={{
        expandedRowRender: record => (
          <div style={{ display: 'flex' }}>
            {/* Sol Parça */}
            <div style={{ flex: 1, marginLeft: "55px", marginBottom: "10px" }}>
              <span style={{ fontWeight: "bold", color: colors.gray, }}>Parça Kodları</span>
              <span style={{ color: record.isDeleted ? "#00000080" : "#000000", marginLeft: "5px" }}>
                {record.parts.map((part, index) => (
                  <div key={part.partNo}>
                    {part.partNo}
                    {index !== record.parts.length - 1 && <hr />} {/* Son parçadan sonra çizgi eklememek için kontrol */}
                  </div>
                ))}
              </span>
            </div>
        
            {/* Orta Parça */}
            <div style={{ flex: 1, marginLeft: "55px", marginBottom: "10px" }}>
              <span style={{ fontWeight: "bold", color: colors.gray,  }}>Operasyonlar</span>
              <span style={{ color: record.isDeleted ? "#00000080" : "#000000", marginLeft: "5px" }}>
              {record.parts.map((part, partIndex) => {
              let partColor = "#000000";
          const normalizeProgramNo = (programNo) => programNo.replace(/_0+/, "_");

          const matchingPlate = record.plates.find(plate => 
            (plate.programNo) === (part.programNo)
          );


          if (matchingPlate) {
            if (matchingPlate.status === "Kesildi") {
              partColor = "green";
            } else if (matchingPlate.status === "Kesilecek") {
              partColor = "#000000";
            }
          } else {
            console.log("No matching plate found for part with programNo:", part.programNo);
          }


  return (
    <div key={part.partNo}>
      <div style={{ display: "flex" }}>
        <div style={{ color: partColor }}>
          {matchingPlate ? ` Lazer |` : "Lazer |"}
        </div>

        {part.operations.length > 0 && (
          <div style={{ marginLeft: "10px", display: "flex", flexWrap: "wrap" }}>
            {part.operations.map((operation, operationIndex) => {
              let operationColor = "#000000";
              if (operation.processName && operation.processName.toLowerCase() === "abkant") {
                if (record.abkantIsFinish) {
                  operationColor = "green";
                }
                else if (operation.isQrScan) {
                  operationColor = "orange";
                }
              
              } else {
                if (operation.isCompleted) {
                  operationColor = "green";
                } else if (operation.isContractManufacturing) {
                  operationColor = "orange";
                }
              }

              return (
                <React.Fragment key={operation.processName}>
                  <span style={{ color: operationColor, marginRight: "5px" }}>
                    {operation.processName} |
                  </span>
                  {operationIndex !== part.operations.length - 1 && ''}
                </React.Fragment>
              );
            })}
          </div>
        )}
      </div>
      {partIndex !== record.parts.length - 1 && <hr />}
    </div>
  );
})}

              </span>
            </div>
          </div>
        ),
        
        expandIcon: ({ expanded, onExpand, record }) =>
          expanded ? (
            <a type="link" onClick={e => onExpand(record, e)} style={{border:"none",backgroundColor:"transparent"}}><img style={{width:"20px"}} alt="edit" src="/images/uparrow.png" /></a>
          ) : (
            <a type="link" onClick={e => onExpand(record, e)} style={{border:"none",backgroundColor:"transparent"}}><img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" /></a>
          ),
          expandIconColumnIndex: columns.length
      }}
    />

      

  </div>
};

export default ProcessStatusList;



/*

 <div style={{ color: abkantColor, padding: "10px", marginRight: "5px", textAlign: "center" }}>
                Abkant
              </div>


                 // Abkant durumunu belirle
         
          let abkantStatusText = "Başlamadı";
          let abkantColor = "black";
        
          const allAbkantFinish = record.priorityAbkant != null ? record.abkantIsFinish : true;
        
          if (allAbkantFinish) {
            abkantStatusText = "Tamamlandı";
            abkantColor = "green";
          } else if (record.priorityAbkant != null) {
            abkantStatusText = "Devam Ediyor";
            abkantColor = "orange";
          }
          */
        
