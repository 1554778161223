import React, { useEffect, useState } from "react";
import { Table, Button, Spin, Input, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../services/firebaseService";
import NotificationService from "../../services/antNotificationService";
import { SearchOutlined } from "@ant-design/icons";
const TitleList = () => {
  const [items, setItems] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [filteredItems , setFilteredItems] = useState([])
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      const data = await FirebaseService.getTitlesData();
      const filteredData = data.filter(item => !item.isDeleted);
      const sortedData = filteredData.sort((a, b) => new Date(b.firstTransactionDate) - new Date(a.firstTransactionDate));
      setItems(sortedData);
      setFilteredItems(sortedData);
    };
    fetchData();
  }, []);
  const goAddPage = () => {
    navigate('/titleadd');
  };
  const goEditPage = (record) => {
    navigate('/titleedit', { state: { record } });
  };
  const handleDelete = async (record, isDeleted) => {
    setLoading(true);
    try {
      let result;
      if (isDeleted) {
        result = await FirebaseService.makeTitleVisible(record.key);
      } else {
        result = await FirebaseService.deleteTitle(record.key);
      }
      if (result.success) {
        NotificationService.openSuccessNotification({
          title: 'İşlem Başarılı',
          description: result.message,
          placement: 'topRight'
        });
        setLoading(false);
        const data = await FirebaseService.getTitlesData();
        const filteredData = data.filter(item => !item.isDeleted);
        setItems(filteredData);
      } else {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
          placement: 'topRight'
        });
        setLoading(false);
      }
    } catch (error) {
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "Bilinmeyen bir hata ile karşılaşıldı.",
        placement: 'topRight'
      });
      setLoading(false);
    }
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    const filteredData = items.filter(item => {
      const value = selectedKeys[0]?.toLowerCase();
      const itemValue = item[dataIndex]?.toString().toLowerCase();
      return !item.isDeleted && (value ? itemValue.includes(value) : true);
    });
    setFilteredItems(filteredData);
  };
  const handleReset = clearFilters => {
    clearFilters();
    setFilteredItems(items);
  };
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          id="search-input"
          placeholder={`Ara`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Ara
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Sıfırla
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890FF' : undefined }} />,
    onFilter: (value, record) => {
      const itemValue = record[dataIndex]?.toString().toLowerCase();
      return itemValue.includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => document.getElementById('search-input').select(), 100);
      }
    },
    sorter: (a, b) => {
      const valueA = a[dataIndex];
      const valueB = b[dataIndex];
      if (valueA < valueB) return -1;
      if (valueA > valueB) return 1;
      return 0;
    },
  });
  const columns = [
    {
      title: 'Ünvan',
      dataIndex: 'item',
      key: 'item',
      render: (text, record) => <span style={{ fontWeight: "bold", color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
      width: 400,
      ...getColumnSearchProps('item'),
    },
    {
      title: ' ' ,
      dataIndex: 'delete',
      key: 'delete',
      render: (text, record) => (
        record.isDeleted ?
          <Button style={{ backgroundColor: "#1A446C", color: "white", borderRadius: 0 }} onClick={() => handleDelete(record, true)}>Göster</Button> :
          <Button style={{ backgroundColor: "#1A446C", color: "white", borderRadius: 0 }} onClick={() => handleDelete(record, false)}>Sil</Button>
      ),
    }
  ];
  return (
    <div style={{
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '94vh',
      padding: "3%"
    }}>
      <Button onClick={goAddPage} icon={<img alt="plus" src="/images/plusbutton.png" style={{ width: "60%" }} />} className='admin-add-button'>
        Yeni Ekle
      </Button>
      <Table
        locale={{
          emptyText: 'Henüz ünvan eklemediniz...',
          filterReset: 'Sıfırla',
          filterTitle: 'Filtre Menüsü',
          selectAll: 'Hepsini Seç',
          selectInvert: 'Tersini Seç',
          selectionAll: 'Tümünü Seç',
          sortTitle: 'Sıralama',
          triggerDesc: 'Azalan sıralama için tıklayın',
          triggerAsc: 'Artan sıralama için tıklayın',
          cancelSort: 'Sıralamayı iptal etmek için tıklayın',
        }}
        dataSource={filteredItems}
        columns={columns}
        showHeader={true}
        pagination={false}
        className="custom-news-table"
        rowClassName="custom-news-row"
      />
      {loading && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000,
        }}>
          <Spin />
        </div>
      )}
    </div>
  );
};
export default TitleList;