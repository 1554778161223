import React,{useEffect,useState} from "react";
import { Table, Button , Popover} from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import NotificationService from "../../../services/antNotificationService";
import  { colors}  from "../../../environments/environment"
import moment from 'moment';

const FutureRawList = () => {
  const [items, setItems] = useState([]);
  const navigate = useNavigate();

  const groupByOfferJobCode = (data) => {
    return data.reduce((groupedData, item) => {
      const { offerJobCode, companyName, deadline,totalKg,supplierName, ...rest } = item;
      const newItem = { offerJobCode, companyName,deadline, totalKg,supplierName, data: [{ ...rest }] };
      if (!groupedData[offerJobCode]) {
        groupedData[offerJobCode] = newItem;
      } else {
        groupedData[offerJobCode].data.push({ ...rest });
      }
      return groupedData;
    }, {});
  };
  
  

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
      const data = await FirebaseService.getFutureRawData();
      const filteredData = data.filter((item) => !item.isDeleted);
    setItems(filteredData)
    };

    fetchData();
  }, []);

  const groupedItems = Object.values(groupByOfferJobCode(items));



  const columns = [

    {
      title: 'Sipariş Kod',
      dataIndex: 'offerJobCode',
      key: 'offerJobCode',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text}
      </span>,
    },
   
    {
      title: 'Müşteri Firma',
      dataIndex: 'companyName',
      key: 'companyName',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
  },
  {
    title: 'Tedarikçi Firma',
    dataIndex: 'supplierName',
    key: 'supplierName',
    render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
},
    {
        title: 'Termin Tarihi',
        dataIndex: 'deadline',
        key: 'deadline',
        render: (text, record) => {
          const formattedDeadline = record.deadline ? new Date(record.deadline.seconds * 1000).toLocaleDateString('tr-TR') : '';
          return <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>{formattedDeadline}</span>;
        },
        sorter: (a, b) => {
          const deadlineA = a.deadline ? a.deadline.seconds : 0;
          const deadlineB = b.deadline ? b.deadline.seconds : 0;
          return deadlineA - deadlineB;
      },
      },
      {
        title: 'Toplam Kilo',
        dataIndex: 'totalKg',
        key: 'totalKg',
        render: (text, record) => (
          <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
            {(record.data.reduce((acc, dataItem) => 
              acc + (parseFloat(dataItem.unitKilo) * parseFloat(dataItem.piece) || 0), 0)
            ).toFixed(2)} Kg
          </span>
        ),
      },
      
      {
        title: ' ',
        dataIndex: 'important',
        key: 'important',
        render: (text, record) => {
          const formattedDeadline = record.deadline ? new Date(record.deadline.seconds * 1000).toLocaleDateString('tr-TR') : '';
          const isBeforeToday = record.deadline ? moment(formattedDeadline, 'DD.MM.YYYY').isBefore(moment(), 'day') : false;
      
          return (
            <>
              {isBeforeToday ? (
                <img width={30} height={20} alt="logo" src="/images/important.png" style={{ paddingBottom: "5%" }} />
              ) : null}
            </>
          );
        },
      },       
    {
        title: ' ',
        dataIndex: 'edit',
        key: 'edit',
        render: (text, record) => (
            <Button  onClick={()=>goDetail(record)} style={{ backgroundColor: "white", color: colors.blue, fontWeight: "500", border: "none" }}>
            Detaya Git
          </Button>
        ),
      }
  ];

  const goDetail = (record) => {
    navigate('/futurerawdetail', { state: { record } })
  };

  return  <div style={{
       justifyContent: 'center',
        alignItems: 'center',
      minHeight: '94vh',
      padding: "3%",
      
      }}>
     <h3 style={{textAlign:"left", color:colors.blue}}>Gelecek Hammade</h3>
    
       
          <Table
            locale={{
              emptyText: 'Henüz  gelecek hammade bulunmamaktadır...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
            dataSource={groupedItems}
            columns={columns}
            showHeader={true}
            pagination={false}
            className="custom-news-table"
            rowClassName="custom-news-row"
          />
       
  
    </div>

  };
  export default FutureRawList;

    