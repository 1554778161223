import { notification } from "antd";

const openInfoNotification = (notificationModel) => {
  notification.info({
        message: notificationModel.title,
        description:notificationModel.description,
        placement:"top",
        duration: 2
      });
    };

const openSuccessNotification = (notificationModel) => {
  notification.success({
        message: notificationModel.title,
        description:notificationModel.description,
        placement:"top",
        duration: 2
      });
    };

const openErrorNotification = (notificationModel) => {
  notification.error({
        message: notificationModel.title,
        description:notificationModel.description,
        placement:"top",
        duration: 2
      });
    };

    const openSuccessNotificationLongTime = (notificationModel) => {
      notification.success({
            message: notificationModel.title,
            description:notificationModel.description,
            placement:"top",
            duration: 500
          });
        };

const openErrorNotificationLongTime  = (notificationModel) => {
  notification.error({
        message: notificationModel.title,
        description:notificationModel.description,
        placement:"top",
        duration: 500
      });
    };


const NotificationService = {
    openInfoNotification,
    openSuccessNotification,
    openErrorNotification,
    openSuccessNotificationLongTime,
    openErrorNotificationLongTime
};

export default NotificationService;

//        placement:notificationModel.placement,
