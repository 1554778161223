import React from 'react';
import { useLocation } from 'react-router-dom';
import { colors } from '../../../environments/environment';


const SetupPlatesView = () => {
  const location = useLocation();
  const filteredSetup = location.state?.filteredSetup || {}; // filteredSetup bir nesne
  const plates = filteredSetup.plates || []; // plates'i diziden al

  console.log("Filtered Setup:", filteredSetup);

  return (
    <div style={{ padding: '5%' }}>
      <h2 style={{ fontWeight: 'bold', color: colors.blue, textAlign: 'left' }}>Yerleşimler</h2>

      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        <div style={{ textAlign: 'left', flex: '1 0 16.66%' }}>
          <p style={{ fontWeight: 'bold', color: colors.blue }}>Firma</p>
          {filteredSetup.companyName || '—'}
        </div>

        <div style={{ textAlign: 'left', flex: '1 0 16.66%' }}>
          <p style={{ fontWeight: 'bold', color: colors.blue }}>İş Kodu</p>
          {filteredSetup.jobCode || '—'}
        </div>

        <div style={{ textAlign: 'left', flex: '1 0 16.66%' }}>
          <p style={{ fontWeight: 'bold', color: colors.blue }}>Setup İsmi</p>
          {filteredSetup.setupName || '—'}
        </div>

        <div style={{ textAlign: 'left', flex: '1 0 16.66%' }}>
          <p style={{ fontWeight: 'bold', color: colors.blue }}>Genel Açıklama</p>
          {filteredSetup.description || '—'}
        </div>

        <div style={{ textAlign: 'left', flex: '1 0 16.66%' }}>
          <p style={{ fontWeight: 'bold', color: colors.blue }}>Termin Tarihi</p>
          {filteredSetup.deadline
            ? new Date(filteredSetup.deadline.seconds * 1000).toLocaleDateString('tr-TR')
            : '—'}
        </div>
      </div>

      <div>
  {plates.length > 0 ? (
    plates.map((plate, plateIndex) => (
      <div
        className="setup-item-plates"
        key={plateIndex}
        style={{
          display: 'flex', // Resim, metin ve fireler yan yana hizalanacak
          border: '1px solid',
          borderColor: colors.blue,
          textAlign: 'left',
          padding: '10px',
          marginBottom: '10px',
          gap: '20px', // Bileşenler arasında boşluk
        }}
      >
        {/* Resim */}
        <img
          src={plate.imageUrl}
          alt={plate.imageFileName}
          style={{ width: '500px', height: '300px', marginBottom: '10px' }}
        />

        {/* Metin Bilgileri */}
        <div style={{ flex: 2 }}>
          <div>
            <p style={{ borderBottom: '1px solid', borderColor: colors.blue }}>
              <strong>Program No:</strong> {plate.programNo || '—'}
            </p>
            <p style={{ borderBottom: '1px solid', borderColor: colors.blue }}>
              <strong>Ebat:</strong>{' '}
              {plate.height && plate.width && plate.thickness
                ? `${plate.height} x ${plate.width} x ${plate.thickness} ${plate.unit || ''}`
                : '—'}
            </p>
            <p style={{ borderBottom: '1px solid', borderColor: colors.blue }}>
              <strong>Süre:</strong> {plate.processingTime || '—'}
            </p>
            <p style={{ borderBottom: '1px solid', borderColor: colors.blue }}>
              <strong>Kg (Plaka Ağırlığı):</strong> {plate.kg || '—'}
            </p>
            <p style={{ borderBottom: '1px solid', borderColor: colors.blue }}>
              <strong>Adet:</strong> {plate.piece || '—'}
            </p>
            <p style={{ borderBottom: '1px solid', borderColor: colors.blue }}>
              <strong>Açıklama:</strong> {plate.description || '—'}
            </p>
          </div>
        </div>

        {/* Fireler */}
        <div
            style={{
              flex: 1,
              border: '1px solid',
              borderColor: colors.blue,
              padding: '10px',
              maxWidth: '250px',
            }}
          >
            <p style={{ color: colors.blue, fontWeight: 'bold' }}>Fireler</p>
            {plate.wastage && plate.wastage.length > 0 ? (
              plate.wastage.map((wastage, index) => (
                <div style={{ display: 'flex', marginBottom: '5px' }} key={index}>
                  <p>
                    <strong>En:</strong> {wastage.width || '—'} |{' '}
                    <strong>Boy:</strong> {wastage.height || '—'} |{' '}
                    <strong>Adet:</strong> {wastage.piece || '—'}
                  </p>
                </div>
              ))
            ) : (
              <p>Fire bulunmamaktadır</p>
            )}
                  </div>

                </div>
              ))
            ) : (
              <p>Yerleşim verisi bulunamadı.</p>
            )}
          </div>


    </div>
  );
};

export default SetupPlatesView;