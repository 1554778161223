import React,{useEffect,useState} from "react";
import { Table, Button,Space,Input, Modal, Select,Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import  { colors, parsCollections, placeholderMap}  from "../../../../environments/environment"
import { useLocation  } from 'react-router-dom';
import FirebaseService from "../../../../services/firebaseService";
import NotificationService from "../../../../services/antNotificationService";
import OneSignalService from "../../../../services/oneSignalService";
import { SearchOutlined } from "@ant-design/icons";


const ApprovedOfferOpenOrderList = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const [items, setItems] = useState([]);
  const [programmers, setProgrammers] = useState([]); 
  const [selectedProgrammer, setSelectedProgrammer] = useState({ id: null, nameSurname: '', jobCode: '' });
  const [loading, setLoading] = useState(false);
  const [filteredItems , setFilteredItems] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
   

    const fetchData = async () => {

      const data = await FirebaseService.getOpenOrderData();
      const filteredDataOffer = data.filter(item => !item.isDeleted && item.isWaiting &&  item.isOfferApproved && item.isOffer )
      .sort(
        (a, b) => new Date(b.firstTransactionDate) - new Date(a.firstTransactionDate)
      );;
      setItems(filteredDataOffer);
      setFilteredItems(filteredDataOffer)


      const dataCollection = await FirebaseService.getUserData();
      const filteredUsers = dataCollection.filter(user => !user.isDeleted && user.userUnit.includes("teknik ressam"));
      const filteredData = filteredUsers.map(user => {
       
      return {
          nameSurname: user.name  + " " + user.surname,
          id: user.key,
        };
      });
      setProgrammers(filteredData);
    };

    fetchData();
  }, []);

  const handleChangeProgrammer = async (record) => {
    console.log("record",record)
    setLoading(true)
    if(selectedProgrammer.id === null){
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "Lütfen programcı seçiniz",
        placement: 'topRight'
      });
      setLoading(false)
      return;
    }
    console.log("selectedProgrammer",selectedProgrammer, record);
    const userData = await FirebaseService.getUserByUid(selectedProgrammer.id);
    console.log("userData",userData.firstUserId)
    const oneSignalResult = await OneSignalService.sendNotification("", "Programcı Değiştirildi",[userData.oneSignalPlayerId])
    const currentDate =new Date()
   
    const sentUserIds = {
      [userData.firstUserId]: false
    };

    const data = {
      firstTransactionDate: currentDate,
      firstUserId: localStorage.getItem("uid"),
      isDeleted: false,
      sentUserIds:sentUserIds, 
      firstUserNameSurname: localStorage.getItem('name') + " " +localStorage.getItem('surname'),
      unitName:localStorage.getItem('userUnit'),
      title:"Programcı Değiştirildi",
      companyName:record.companyName,
      jobCode:record.jobCode, 
      projectCode:record.projectCode, 
      machine:null,
      type:null,
      notice:"Programcı değiştirildi, bekleyen siparişlerinizi kontrol ediniz.",
      description: null

    };

    const result = await FirebaseService.addRecording(parsCollections.notifications,data);

    const allOpenOrder = await FirebaseService.getOpenOrderData();
    const filteredOpenOrder = allOpenOrder.filter(item => !item.isDeleted && item.isWaiting && item.jobCode.includes(record.jobCode));

    console.log("filteredOpenOrder",filteredOpenOrder)

    const updatePromises = filteredOpenOrder.map(async (item) => {
    let updateOpenOrderItem = {
      isChangedProgrammer: true,
      programmerName: selectedProgrammer.nameSurname,
      programmerUserId: selectedProgrammer.id,
    };

    console.log("updateOpenOrderItem", updateOpenOrderItem, item.key);

    const resultx = await FirebaseService.updateOpenOrder(item.key, updateOpenOrderItem);
    console.log(resultx);

    if (resultx) {
     console.log("başarılı")
    } else {
      console.log("başarısız");
    }

    return resultx;
    });

    const results = await Promise.all(updatePromises);

    if (results.every(result => result)) {
      NotificationService.openSuccessNotification({
        title: 'Başarılı',
        description: "Başarıyla gönderilmiştir.",
        placement: 'topRight',
      });setLoading(false)
      
      setTimeout(() => {
        navigate("/salesmanpage");
      }, 2000);
    } else {
      NotificationService.openErrorNotification({
        title: 'Başarısız',
        description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
        placement: 'topRight',
      });setLoading(false)
    }
    

  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    const filteredData = items.filter((item) => {
      const value = selectedKeys[0]?.toLowerCase(); // Aranan değer
      const itemValue = item[dataIndex]?.toString().toLowerCase(); // Tablo verisi
      return (
        !item.isDeleted &&
        (value ? itemValue?.includes(value) : true) // Null kontrolü
      );
    });
    setFilteredItems(filteredData);
  };
  
  const handleReset = (clearFilters) => {
    clearFilters();
    setFilteredItems(items);
  };
  
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          id="search-input"
          placeholder={`${placeholderMap[dataIndex] || dataIndex} Ara`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, background: colors.blue }}
          >
            Ara
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Sıfırla
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      const recordValue = record[dataIndex]?.toString().toLowerCase(); // Null kontrolü
      return recordValue?.includes(value.toLowerCase()); // includes için güvenli kontrol
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => document.getElementById('search-input').select(), 100);
      }
    },
    sorter: (a, b) => {
      const aValue = a[dataIndex];
      const bValue = b[dataIndex];
  
      // String karşılaştırması
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return aValue.localeCompare(bValue);
      }
  
      // Sayısal (int ve float) karşılaştırma
      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return aValue - bValue;
      }
  
      return 0; // Varsayılan olarak eşit kabul et
    },
  });
  

  const columns = [
    {
      title: 'Firma',
      dataIndex: 'companyName',
      key: 'companyName',
     
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
      ...getColumnSearchProps('companyName')
  },
    {
      title: 'İş Kodu',
      dataIndex: 'jobCode',
      key: 'jobCode',
    
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text}
      </span>,
      ...getColumnSearchProps('jobCode')
    },
    {
        title: 'Proje Kodu',
        dataIndex: 'projectCode',
        key: 'projectCode',
    
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text ? text : "-"}
        </span>,
        ...getColumnSearchProps('projectCode')
      },
      {
        title: 'Programcı',
        dataIndex: 'programmerName',
        key: 'programmerName',
       
        render: (text, record) => (
          <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
           { text }
          </span>
          
        ),
        ...getColumnSearchProps('programmerName')
   },
   {
    title: 'Durum',
    dataIndex: '',
    key: '',
   
    render: (text, record) => (
      <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
       { record.isChangedProgrammer ? "Atandı" : "Otomatik Atandı" }
      </span>
      
    )
  } 
  ];

 
  const onChange = (value) => {
    const selected = programmers.find(item => item.id === value);
    if (selected) {
      setSelectedProgrammer({ id: selected.id, nameSurname: selected.nameSurname });
    }
  };


  return  <div style={{
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '94vh',
        padding:"3%",
       
      }}>
        <h3 style={{textAlign:"left", color:colors.blue}}>Onaylanan Teklifler</h3>
       
    

        <Table
            locale={{
              emptyText: 'Henüz onaylanan teklif bulunmamaktadır...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
            dataSource={filteredItems}
            columns={columns}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
            expandable={{
              expandedRowRender: record => (
                <div style={{ display: 'flex' }}>
                {/* Sol Parça */}
                <div style={{ flex: 1, marginLeft: "55px" }}>
                  <div style={{ marginBottom: "10px" }}>
                    <span style={{ fontWeight: "bold", color: "#00000080"  }}>Programcı Değiştir</span>
                    
                  </div>
                 
                </div>
              
                {/* Orta Parça */}
                <div style={{ flex: 1, marginLeft: "55px" }}>
                  <div style={{ marginBottom: "10px" }}>
                  <Select
                  showSearch
                      onChange={onChange}
                      placeholder="Programcı Seçiniz"
                      style={{ width:"300px"}}
                      className="input-style" 
                      suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
                    options={programmers.map(item => ({ label: item.nameSurname, value: item.id }))}
                    filterOption={(input, option) => 
                      option.label.toLowerCase().includes(input.toLowerCase()) // Arama için filtreleme
                    }
                    />
                  </div>
                </div>
              
                {/* Sağ Parça */}
               
                <div style={{ flex: 1, marginLeft: "55px" }}>
                  <div style={{ marginBottom: "10px" }}>
                    <Button 
                    onClick={() => handleChangeProgrammer(record)} 
                    style={{backgroundColor: colors.blue, color: "white", fontWeight: "500"}}>
                        Programcı Değiştir
                    </Button>
                  </div>
                 
                </div>
                {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}
      
      
      
              </div>
              
            ),
            
              expandIcon: ({ expanded, onExpand, record }) =>
                expanded ? (
                  <a type="link" onClick={e => onExpand(record, e)} style={{border:"none",backgroundColor:"transparent"}}><img style={{width:"20px"}} alt="edit" src="/images/uparrow.png" /></a>
                ) : (
                  <a type="link" onClick={e => onExpand(record, e)} style={{border:"none",backgroundColor:"transparent"}}><img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" /></a>
                ),
                expandIconColumnIndex: 5
            }}
        />
    </div>

  };
  export default ApprovedOfferOpenOrderList;
 