import React, { useEffect, useState } from "react";
import { Table, Button, Spin, Space, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../services/firebaseService";
import NotificationService from "../../services/antNotificationService";
import { SearchOutlined } from "@ant-design/icons";
const AllItemList = () => {
  const [items, setItems] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      const data = await FirebaseService.getAllItemsData();
      const filteredData = data.filter(item => !item.isDeleted);
      const sortedData = filteredData.sort((a, b) => new Date(b.firstTransactionDate) - new Date(a.firstTransactionDate));
      setItems(sortedData);
      setFilteredItems(sortedData);
    };
    fetchData();
  }, []);
  const goAddPage = () => {
    navigate('/allitemadd');
  };
  const handleDelete = async (record, isDeleted) => {
    setLoading(true);
    try {
      let result;
      if (isDeleted) {
        result = await FirebaseService.makeAllItemVisible(record.key);
      } else {
        result = await FirebaseService.deleteAllItemProduct(record.key);
      }
      if (result.success) {
        NotificationService.openSuccessNotification({
          title: 'İşlem Başarılı',
          description: result.message,
          placement: 'topRight'
        });
        setLoading(false);
        const data = await FirebaseService.getAllItemsData();
        const filteredData = data.filter(item => !item.isDeleted);
        setItems(filteredData);
        setFilteredItems(filteredData);
      } else {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
          placement: 'topRight'
        });
        setLoading(false);
      }
    } catch (error) {
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "Bilinmeyen bir hata ile karşılaşıldı.",
        placement: 'topRight'
      });
      setLoading(false);
    }
  };
  const mapProductType = (value) => {
    switch (value) {
      case 'raw':
        return 'Saç Levha';
      case 'other':
        return 'Diğer';
      default:
        return value;
    }
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    const filteredData = items.filter(item => {
      const value = selectedKeys[0]?.toLowerCase();
      let itemValue;
      if (dataIndex === 'productType') {
        // Dönüştürülmüş değeri kullan
        itemValue = mapProductType(item[dataIndex]).toLowerCase();
      } else {
        itemValue = item[dataIndex]?.toString().toLowerCase();
      }
      return (
        !item.isDeleted &&
        (value ? itemValue.includes(value) : true)
      );
    });
    setFilteredItems(filteredData);
  };
  const handleReset = clearFilters => {
    clearFilters();
    setFilteredItems(items);
  };
   const getColumnSearchProps = dataIndex => ({
     filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
       <div style={{ padding: 8 }}>
         <Input
           id="search-input"
           placeholder={`Ara`}
           value={selectedKeys[0]}
           onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
           onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
           style={{ width: 188, marginBottom: 8, display: 'block' }}
         />
         <Space>
           <Button
             type="primary"
             onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
             icon={<SearchOutlined />}
             size="small"
             style={{ width: 90 }}
           >
             Ara
           </Button>
           <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
             Sıfırla
           </Button>
         </Space>
       </div>
     ),
     filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890FF' : undefined }} />,
     onFilter: (value, record) => {
       const itemValue = dataIndex === 'productType'
         ? mapProductType(record[dataIndex]).toLowerCase()
         : record[dataIndex]?.toString().toLowerCase();
       return itemValue.includes(value.toLowerCase());
     },
     onFilterDropdownVisibleChange: visible => {
       if (visible) {
         setTimeout(() => document.getElementById('search-input').select(), 100);
       }
     },
     sorter: (a, b) => {
       const valueA = dataIndex === 'productType'
         ? mapProductType(a[dataIndex])
         : a[dataIndex];
       const valueB = dataIndex === 'productType'
         ? mapProductType(b[dataIndex])
         : b[dataIndex];
       return valueA.localeCompare(valueB);
     },
   });
  const columns = [
    {
      title: 'Türü',
      dataIndex: 'productType',
      key: 'productType',
      render: (text, record) => (
        <span style={{ fontWeight: "bold", color: record.isDeleted ? "#00000080" : "#000000" }}>
          {mapProductType(text)}
        </span>
      ),
      ...getColumnSearchProps('productType')
    },
    {
      title: 'Ürün',
      dataIndex: 'productString',
      key: 'productString',
      render: (text, record) => (
        <span style={{ fontWeight: "bold", color: record.isDeleted ? "#00000080" : "#000000" }}>
          {record.productType === "raw" ? formatProductDictionary(record.productDictionary) : text}
        </span>
      ),
      ...getColumnSearchProps('productString')
    },
    {
      title: 'Min Stok',
      dataIndex: 'minPiece',
      key: 'minPiece',
      render: (text, record) => (
        <span style={{ fontWeight: "bold", color: record.isDeleted ? "#00000080" : "#000000" }}>
          {text}
        </span>
      ),
      ...getColumnSearchProps('minPiece'),
      sorter: (a, b) => (a.minPiece || 0) - (b.minPiece || 0), // Sayısal sıralama
    },
    {
      title: ' ',
      dataIndex: 'delete',
      key: 'delete',
      render: (text, record) => (
        record.isDeleted ?
          <Button loading={loading} style={{ backgroundColor: "#1A446C", color: "white", borderRadius: 0 }} onClick={() => handleDelete(record, true)}>Göster</Button> :
          <Button loading={loading} style={{ backgroundColor: "#1A446C", color: "white", borderRadius: 0 }} onClick={() => handleDelete(record, false)}>Sil</Button>
      )
    }
  ];
  const formatProductDictionary = (productDictionary) => {
    const orderedKeys = ['quality', 'thickness', 'surface', 'width', 'height'];
    const formattedValues = orderedKeys.map(key => productDictionary[key]);
    return formattedValues.join(', ');
  };
  return (
    <div style={{
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '94vh',
      padding: "3%"
    }}>
      <Button onClick={goAddPage} icon={<img alt="plus" src="/images/plusbutton.png" style={{ width: "60%" }} />} className='admin-add-button'>
        Yeni Ekle
      </Button>
      <Table
        locale={{
          emptyText: 'Henüz ürün eklemediniz...',
          filterReset: 'Sıfırla',
          filterTitle: 'Filtre Menüsü',
          selectAll: 'Hepsini Seç',
          selectInvert: 'Tersini Seç',
          selectionAll: 'Tümünü Seç',
          sortTitle: 'Sıralama',
          triggerDesc: 'Azalan sıralama için tıklayın',
          triggerAsc: 'Artan sıralama için tıklayın',
          cancelSort: 'Sıralamayı iptal etmek için tıklayın',
        }}
        dataSource={filteredItems}
        columns={columns}
        showHeader={true}
        pagination={false}
        className="custom-news-table"
        rowClassName="custom-news-row"
      />
      {loading && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000,
        }}>
          <Spin />
        </div>
      )}
    </div>
  );
};
export default AllItemList;