import { initializeApp } from 'firebase/app';
import { environment, parsCollections } from "../environments/environment"
import { getFirestore, collection, query, orderBy, limit, getDocs, where,Timestamp,updateDoc,setDoc,doc,getDoc,addDoc,arrayUnion, arrayRemove ,serverTimestamp,deleteDoc,onSnapshot } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword,GoogleAuthProvider,signInWithPopup,signInWithRedirect ,getRedirectResult} from "firebase/auth";

import { parse, isAfter, isToday, format,compareDesc } from 'date-fns';

const app = initializeApp(environment.firebase);

const db = getFirestore(app);


const listenToCompaniesData = (callback) => {
  return onSnapshot(collection(db, parsCollections.companies), callback);
};

const listenToJobCodesData = (callback) => {
  return onSnapshot(collection(db, parsCollections.currentJobCode), callback);
};

const listenToStocksData = (callback) => {
  return onSnapshot(collection(db, parsCollections.stock), callback);
};

const listenToWastagesData = (callback) => {
  return onSnapshot(collection(db, parsCollections.wastages), callback);
};


const createUser = async (userData) => {
    try {
    const { name, surname, email, password, userUnit , isManager,title,birthday} = userData;
   
    const usersCollectionRef = collection(db, 'users');
    const userId = auth.currentUser.uid; 
    const docRef = doc(usersCollectionRef, userId);
   
    await setDoc(docRef, {
    name:name,
    surname:surname,
    email:email,
    password:password,
    userType: ["user"],
    isDeleted: false, 
    loginType: "normal-web",
    oneSignalPlayerId: "",
    firstTransactionDate: new Date(),
    firstUserId: userId,
    imageUrl: "",
    phone: 0,
    userUnit: userUnit,
    isManager: isManager,
    title:title,
    discontinuity: [],
    birthday:birthday
    });
   
    console.log('User successfully registered!');
    } catch (error) {
     console.error('Error occurred while registering user:', error);
    throw error;
    }
};
const updateUser = async (docId,data) => {
    try {
        const documentRef = doc(db, parsCollections.users, docId);
            await updateDoc(documentRef, data);
    
        return { success: true, message: 'Document successfully updated.' };
      } catch (error) {
        console.error('Error updating document:', error);
        return { success: false, message: 'Error updating document.' };
      }
};
const updateUserImageUrl = async (docId, newImageUrl) => {
    const documentRef = doc(db, parsCollections.users, docId);
    const updatedFields = {
    imageUrl: newImageUrl
    };
    
    try {
    await updateDoc(documentRef, updatedFields);
    console.log("burda")
    return { message: "Profil fotoğrafı başarıyla değiştirildi.", success: true };
    } catch (error) {
       console.log("errpr",error)
   
    return error;
    }
};


const getUserByUid = async (uid) => {
  const userDocRef = doc(db, parsCollections.users, uid);
  const userDocSnapshot = await getDoc(userDocRef);
  
  if (userDocSnapshot.exists()) {
  const userData = userDocSnapshot.data();
  return userData;
  } else {
  return null; 
  }
  };
  
   
//title
const getTitlesData = async () => {
    const data = await fetchData(parsCollections.titles);
    return data;
};
const deleteTitle = async (docId) => {
    const result = updateIsDeleted(parsCollections.titles,docId,true);
    return result;
};
const makeTitleVisible = async (docId) => {
    const result = await updateIsDeleted(parsCollections.titles,docId,false);
    return result;
};
const updateTitle = async (docId, updatedData) => {
    const result = await updateRecording(parsCollections.titles, docId, updatedData)
    return result;
};


//surface
const getSurfacesData = async () => {
  const data = await fetchData(parsCollections.surfaces);
  return data;
};
const deleteSurface = async (docId) => {
  const result = updateIsDeleted(parsCollections.surfaces,docId,true);
  return result;
};
const makeSurfaceVisible = async (docId) => {
  const result = await updateIsDeleted(parsCollections.surfaces,docId,false);
  return result;
};
const updateSurface = async (docId, updatedData) => {
  const result = await updateRecording(parsCollections.surfaces, docId, updatedData)
  return result;
};
   
//units
const getUnitsData = async () => {
    const data = await fetchData(parsCollections.units);
    return data;
};
const deleteUnit = async (docId) => {
    const result = updateIsDeleted(parsCollections.units,docId,true);
    return result;
   };
const makeUnitVisible = async (docId) => {
    const result = await updateIsDeleted(parsCollections.units,docId,false);
    return result;
};
const updateUnit = async (docId, updatedData) => {
    const result = await updateRecording(parsCollections.units, docId, updatedData)
    return result;
};

const getUnitDataById = async (id) => {
    const docRef = doc(db, parsCollections.units, id);
    const docSnapshot = await getDoc(docRef);
    if (docSnapshot.exists()) {
    const data = docSnapshot.data();
    return data;
    } else {
    return null;
    }
};



//company
const getCompaniesData = async () => {
    const data = await fetchData(parsCollections.companies);
    return data;
};
const deleteCompany = async (docId) => {
    const result = updateIsDeleted(parsCollections.companies,docId,true);
    return result;
   };
const makeCompanyVisible = async (docId) => {
    const result = await updateIsDeleted(parsCollections.companies,docId,false);
    return result;
};
const updateCompany = async (docId, updatedData) => {
    const result = await updateRecording(parsCollections.companies, docId, updatedData)
    return result;
};

const getCompanyDataById = async (id) => {
    const docRef = doc(db, parsCollections.companies, id);
    const docSnapshot = await getDoc(docRef);
    if (docSnapshot.exists()) {
    const data = docSnapshot.data();
    return data;
    } else {
    return null;
    }
};


//all items
const getAllItemsData = async () => {
    const data = await fetchData(parsCollections.allItems);
    return data;
};

const getAllItemtDataById = async (id) => {
    const docRef = doc(db, parsCollections.allItems, id);
    const docSnapshot = await getDoc(docRef);
    if (docSnapshot.exists()) {
    const data = docSnapshot.data();
    return data;
    } else {
    return null;
    }
};

const deleteAllItemProduct = async (docId) => {
    const result = updateIsDeleted(parsCollections.allItems,docId,true);
    return result;
   };
const makeAllItemVisible = async (docId) => {
    const result = await updateIsDeleted(parsCollections.allItems,docId,false);
    return result;
};
const updateAllItemProduct = async (docId, updatedData) => {
    const result = await updateRecording(parsCollections.allItems, docId, updatedData)
    return result;
};

//qr için
const addRecordingForDays = async (collectionName, documentName) => {
    try {
        const collectionRef = collection(db, collectionName);
      const docRef = doc(collectionRef, documentName);
      const existingDoc = await getDoc(docRef);

      if (existingDoc.exists()) {
        return { message: "Kayıt zaten var.", success: false, docId: documentName };
      }

      await setDoc(docRef, { });

      return { message: "Kayıt başarıyla oluşturuldu.", success: true, docId: documentName };
    } catch (error) {
      return error;
    }
};

const getDaysData = async () => {
    const data = await fetchData(parsCollections.days);
    return data;
};
  

//talep et için
const addPurchaseRequest = async (data) => {
   
    try {
        const {companyName,productType,unitName,deadline,requestedProductInfo,offerJobCode,deadlineForPayment, paymentMethod,mailList,sentExcelList,incomingExcelList,acceptExcelDocumentUrl,description,isSendSetup } = data;
       
        const usersCollectionRef = collection(db, parsCollections.purchaseRequests);
       
        const docRef = doc(usersCollectionRef); 
               
        await setDoc(docRef, {
            firstTransactionDate: new Date(),
            firstUserId: localStorage.getItem("uid"),
            isDeleted: false,
            firstUserNameSurname: localStorage.getItem('name') + " " + localStorage.getItem('surname'),
            companyName: companyName,
            productType: productType,
            unitName: unitName,
            deadline: deadline,
            requestedProductInfo: requestedProductInfo,
        
            offerJobCode: offerJobCode,
            deadlineForPayment:deadlineForPayment,
            paymentMethod:paymentMethod,
            mailList:mailList,
            sentExcelList:sentExcelList,
            incomingExcelList:incomingExcelList,
            acceptExcelDocumentUrl:acceptExcelDocumentUrl ,
            description: description, //sonra bak
            isSendSetup: isSendSetup,
            isPurchased: false,
            isPurchasedDate:null,
            noPurchasedDescription:null
        });
          return { message: "Kayıt başarıyla oluşturuldu.", success: true };
        } catch (error) {
          console.error('Hata', error);
          return error;
        }
};
const getPurchaseRequestData = async () => {
    const data = await fetchData(parsCollections.purchaseRequests);
    return data;
};

//delete
const deletePurchaseRequestData = async (documentId) => {
  try {
    const documentRef = doc(db, parsCollections.purchaseRequests, documentId);
    await deleteDoc(documentRef);
    console.log(`Document with key ${documentId} has been deleted successfully`);
    return true;
  } catch (error) {
    console.error("Error deleting document: ", error);
    return false;
  }
};

const updatePurchaseRequestData = async (documentId, newMailList,paymentMethod,deadlineForPayment,sentExcelDocumentUrl,checkedRequestedProductInfo) => {
    const documentRef = doc(db, parsCollections.purchaseRequests, documentId);

    try {
      const documentSnapshot = await getDoc(documentRef);
      if (documentSnapshot.exists()) {
        const existingMailList = documentSnapshot.data().mailList || [];
  
        const updatedMailList = Array.from(new Set([...existingMailList, ...newMailList]));
          await updateDoc(documentRef, {
          mailList: updatedMailList,
          paymentMethod:paymentMethod,
          deadlineForPayment: new Date(deadlineForPayment),
          sentExcelDocumentUrl:sentExcelDocumentUrl,
          checkedRequestedProductInfo:checkedRequestedProductInfo

        });
  
        console.log('Document successfully updated!');
        return true;
      } else {
        console.error('Document not found!');
        return false;
      }
    } catch (error) {
      console.error('Error updating document: ', error.message);
      return false;
    }
};
  

const updatePurchaseRequestIncomingExcellData = async (documentId, documentUrl, futureRaws, supplierName,customerDeadline) => {
  const documentRef = doc(db, parsCollections.purchaseRequests, documentId);

  try {
    const documentSnapshot = await getDoc(documentRef);
    if (documentSnapshot.exists()) {
      const existingIncomingExcelList = documentSnapshot.data().incomingExcelList || [];
      const updatedIncomingExcelList = [
        ...existingIncomingExcelList,
        { documentUrl: documentUrl, supplierName: supplierName, deadline:customerDeadline }
      ];

      const existingFutureRaws = documentSnapshot.data().checkedRequestedProductInfo || [];
      console.log("existingFutureRaws", existingFutureRaws);

      futureRaws = futureRaws.map(newItem => {
        const existingItem = existingFutureRaws.find(item => item.code === newItem.code) || {};
        const existingCompaniesAndPrice = existingItem.companiesAndPrice || [];
        const newEntry = { price: newItem.price, company: supplierName };

        return {
          ...newItem,
          companiesAndPrice: [...existingCompaniesAndPrice, newEntry]
        };
      });

      console.log("futureRaws after update", futureRaws);
      await updateDoc(documentRef, {
        incomingExcelList: updatedIncomingExcelList,
        checkedRequestedProductInfo: futureRaws
      });


      console.log('Document successfully updated!');
      return true;
    } else {
      console.error('Document not found!');
      return false;
    }
  } catch (error) {
    console.error('Error updating document: ', error.message);
    return false;
  }
};



const updatePurchaseRequestAcceptExcellData = async (documentId, documentUrl) => {
  const documentRef = doc(db, parsCollections.purchaseRequests, documentId);

  try {
    const documentSnapshot = await getDoc(documentRef);
    if (documentSnapshot.exists()) {
      await updateDoc(documentRef, {
        acceptExcelDocumentUrl: documentUrl,
      });

      console.log('Document successfully updated!');
      return true;
    } else {
      console.error('Document not found!');
      return false;
    }
  } catch (error) {
    console.error('Error updating document: ', error.message);
    return false;
  }
};

const updatePurchaseRequetIncomingExcellData = async (documentId, documentUrl, supplierName) => {
  const documentRef = doc(db, parsCollections.purchaseRequests, documentId);

  try {
    const documentSnapshot = await getDoc(documentRef);
    if (documentSnapshot.exists()) {
      await updateDoc(documentRef, {
        incomingExcelList: arrayUnion({ documentUrl, supplierName })
      });

      console.log('Document successfully updated!');
      return true;
    } else {
      console.error('Document not found!');
      return false;
    }
  } catch (error) {
    console.error('Error updating document: ', error.message);
    return false;
  }
};


const updatePurchaseData = async (docId, updatedItem) => {
  try {
    const documentRef = doc(db, parsCollections.purchaseRequests, docId);
    const currentDocument = await getDoc(documentRef);

    if (currentDocument.exists()) {
      const currentData = currentDocument.data();
      const updatedData = { ...currentData, ...updatedItem }; 

      await updateDoc(documentRef, updatedData);

      return { success: true, message: 'Başarıyla güncellendi' };
    } else {
      return { success: false, message: 'Belirtilen ID ile belge bulunamadı' };
    }
  } catch (error) {
    console.error('Güncellenirken bir hata oluştu:', error);
    return { success: false, message: 'Güncellenirken bir hata oluştu' };
  }
};


const updatePurchaseDataRequestedProductInfo = async (docId, updatedItem) => {
  try {
    const documentRef = doc(db, parsCollections.purchaseRequests, docId);
    const currentDocument = await getDoc(documentRef);

    if (currentDocument.exists()) {
      const currentData = currentDocument.data();

      const updatedProductInfo = currentData.requestedProductInfo.map(item => {
        if (item.id === updatedItem.id) {
          console.log("burdaaaaa");
          return { ...item, piece: Number(updatedItem.piece)}; 
        }
        return item;
      });

      const updatedData = { ...currentData, requestedProductInfo: updatedProductInfo };
      await updateDoc(documentRef, updatedData);

      return { success: true, message: 'Başarıyla güncellendi' };
    } else {
      return { success: false, message: 'Belirtilen ID ile belge bulunamadı' };
    }
  } catch (error) {
    console.error('Güncellenirken bir hata oluştu:', error);
    return { success: false, message: 'Güncellenirken bir hata oluştu' };
  }
};



//mail için
const getMailsData = async () => {
    const data = await fetchData(parsCollections.mails);
    return data;
};
const deleteMail = async (docId) => {
    const result = updateIsDeleted(parsCollections.mails,docId,true);
    return result;
   };
const makeMailVisible = async (docId) => {
    const result = await updateIsDeleted(parsCollections.mails,docId,false);
    return result;
};
const updateMail = async (docId, updatedData) => {
  const result = await updateRecording(parsCollections.mails, docId, updatedData);
  return result;
};


//fason üretim prosess
const getContractManufacturingProcessesData = async () => {
    const data = await fetchData(parsCollections.contractManufacturingProcesses);
    return data;
};
const deleteContractManufacturingProcess = async (docId) => {
    const result = updateIsDeleted(parsCollections.contractManufacturingProcesses,docId,true);
    return result;
   };
const makeContractManufacturingProcessVisible = async (docId) => {
    const result = await updateIsDeleted(parsCollections.contractManufacturingProcesses,docId,false);
    return result;
};

//fason üretim
const getContractManufacturingData = async () => {
    const data = await fetchData(parsCollections.contractManufacturing);
    return data;
};

// fason üretim silme delete
const deleteContractManufacturingData = async (documentId) => {
  try {
    const documentRef = doc(db, parsCollections.contractManufacturing, documentId);
    await deleteDoc(documentRef);
    console.log(`Document with key ${documentId} has been deleted successfully`);
    return true;
  } catch (error) {
    console.error("Error deleting document: ", error);
    return false;
  }
};

//tedarikçi
const getSuppliersData = async () => {
    const data = await fetchData(parsCollections.suppliers);
    return data;
};

const deleteSupplier = async (docId) => {
    const result = updateIsDeleted(parsCollections.suppliers,docId,true);
    return result;
};

const makeSupplierVisible = async (docId) => {
    const result = await updateIsDeleted(parsCollections.suppliers,docId,false);
    return result;
};


//ödeme vaadesi için
const getPaymentMethodsData = async () => {
  const data = await fetchData(parsCollections.paymentMethods);
  return data;
};
const deletePaymentMethods = async (docId) => {
  const result = updateIsDeleted(parsCollections.paymentMethods,docId,true);
  return result;
 };
const makePaymentMethodsVisible = async (docId) => {
  const result = await updateIsDeleted(parsCollections.paymentMethods,docId,false);
  return result;
};


//alarm
const getAlarmsData = async () => {
    const data = await fetchData(parsCollections.alarms);
    return data;
};


const updateAlarmRequestRemovalData = async (documentId, data) => {
    const documentRef = doc(db, parsCollections.alarms, documentId);
  
    try {
      await updateDoc(documentRef, data);
      console.log('Document successfully updated!');
      return true;
    } catch (error) {
      console.error('Error updating document: ', error.message);
      return false;
    }
  };
  
  

//stok
const getStocksData = async () => {
    const data = await fetchData(parsCollections.stock);
    return data;
};
const deleteStock = async (documentId) => {
  //const result = updateIsDeleted(parsCollections.stock,docId,true);
  //return result;
  try {
    const documentRef = doc(db, parsCollections.stock, documentId);
    await deleteDoc(documentRef);
    console.log(`Document with key ${documentId} has been deleted successfully`);
    return true;
  } catch (error) {
    console.error("Error deleting document: ", error);
    return false;
  }
 };

 //delete

 const updateStock = async (docId, updatedData) => {
  const result = await updateRecording(parsCollections.stock, docId, updatedData)
  return result;
};



const getStockItemById = async (id) => {
  const docRef = doc(db, parsCollections.stock, id);
  const docSnapshot = await getDoc(docRef);
  if (docSnapshot.exists()) {
  const data = docSnapshot.data();
  return data;
  } else {
  return null;
  }
};

//wastage
const getWastagesData = async () => {
    const data = await fetchData(parsCollections.wastages);
    return data;
};
const deleteWastage = async (docId) => {
  const result = updateIsDeleted(parsCollections.wastages,docId,true);
  return result;
};
const updateWastage = async (docId,updatedData) => {
  const result = await updateRecording(parsCollections.wastages, docId, updatedData)
  return result;
}

const getWastageItemById = async (id) => {
  const docRef = doc(db, parsCollections.wastages, id);
  const docSnapshot = await getDoc(docRef);
  if (docSnapshot.exists()) {
  const data = docSnapshot.data();
  return data;
  } else {
  return null;
  }
};

const deleteWastageData = async (documentId) => {
  try {
    const documentRef = doc(db, parsCollections.wastages, documentId);
    await deleteDoc(documentRef);
    console.log(`Document with key ${documentId} has been deleted successfully`);
    return true;
  } catch (error) {
    console.error("Error deleting document: ", error);
    return false;
  }
};

//open order
const getOpenOrderData = async () => {
    const data = await fetchData(parsCollections.openOrder);
   // console.log("data",data);
    return data;
};

//open order update
const updateOpenOrder = async (docId, updatedItem) => {
  try {
    const documentRef = doc(db, parsCollections.openOrder, docId);
    const currentDocument = await getDoc(documentRef);
console.log("currentDocument",currentDocument)
    if (currentDocument.exists()) {
      const currentData = currentDocument.data();
      const updatedData = { ...currentData, ...updatedItem }; 

      await updateDoc(documentRef, updatedData);

      return { success: true, message: 'Başarıyla güncellendi' };
    } else {
      return { success: false, message: 'Belirtilen ID ile belge bulunamadı' };
    }
  } catch (error) {
    console.error('Güncellenirken bir hata oluştu:', error);
    return { success: false, message: 'Güncellenirken bir hata oluştu' };
  }
};
const updateOpenOrderStockAndWastage = async (docId, updatedItem) => {
  try {
    const documentRef = doc(db, parsCollections.openOrder, docId);
    const currentDocument = await getDoc(documentRef);
    console.log("currentDocument", currentDocument);

    if (currentDocument.exists()) {
      const currentData = currentDocument.data();

      // Mevcut stock ve wastage değerlerini al
      const existingStock = currentData.stock || [];
      const existingWastage = currentData.wastage || [];

      // Güncellenmek istenen stock ve wastage öğelerini al
      const updatedStock = updatedItem.stock || [];
      const updatedWastage = updatedItem.wastage || [];

      // Mevcut verilerin üstüne yeni verileri ekle
      const combinedStock = [...existingStock, ...updatedStock];
      const combinedWastage = [...existingWastage, ...updatedWastage];

      // Güncellenmiş veriyi hazırlıyoruz
      const updatedData = { 
        ...currentData, 
        ...updatedItem, 
        stock: combinedStock, 
        wastage: combinedWastage 
      };

      // Belgeyi güncelle
      await updateDoc(documentRef, updatedData);

      return { success: true, message: 'Başarıyla güncellendi' };
    } else {
      return { success: false, message: 'Belirtilen ID ile belge bulunamadı' };
    }
  } catch (error) {
    console.error('Güncellenirken bir hata oluştu:', error);
    return { success: false, message: 'Güncellenirken bir hata oluştu' };
  }
};

const updateOpenOrderStockRemove = async (docId, recordx, inputValue) => {
  try {
    const documentRef = doc(db, parsCollections.openOrder, docId);
    const currentDocument = await getDoc(documentRef);

    if (currentDocument.exists()) {
      const currentData = currentDocument.data();
      const existingStock = currentData.stock || [];

      const stockIndex = existingStock.findIndex(stockItem => stockItem.id === recordx.id);

      if (stockIndex !== -1) {
        const stockItem = existingStock[stockIndex];
        const remainingPiece = Number(stockItem.piece) - Number(inputValue);

        if (remainingPiece <= 0) {
          existingStock.splice(stockIndex, 1);
          console.log(`Stock item with id ${recordx.id} deleted from openOrder.`);
        } else {
          existingStock[stockIndex] = { ...stockItem, piece: Number(remainingPiece) };
          console.log(`Stock item with id ${recordx.id} updated in openOrder. Remaining pieces: ${remainingPiece}`);
        }
        const updatedData = { ...currentData, stock: existingStock };
        await updateDoc(documentRef, updatedData);
        console.log(`Open order with id ${docId} successfully updated.`);
      } else {
        console.log(`Stock item with id ${recordx.id} not found in openOrder.`);
      }
    } else {
      console.log('Belirtilen ID ile openOrder belgesi bulunamadı.');
    }
  } catch (error) {
    console.error('OpenOrder güncellenirken bir hata oluştu:', error);
  }
};

const updateOpenOrderStockAdd = async (docId, recordx, inputValue) => {
  try {
    const documentRef = doc(db, parsCollections.openOrder, docId);
    const currentDocument = await getDoc(documentRef);

    if (currentDocument.exists()) {
      const currentData = currentDocument.data();
      const existingStock = currentData.stock || [];

      const stockIndex = existingStock.findIndex(stockItem => stockItem.id === recordx.id);

      if (stockIndex !== -1) {
        const stockItem = existingStock[stockIndex];
        const remainingPiece = Number(stockItem.piece) + Number(inputValue);
console.log("remainingPiece",remainingPiece)
        existingStock[stockIndex] = { ...stockItem, piece: remainingPiece };
        console.log(`Stock item with id ${recordx.id} updated in openOrder. Remaining pieces: ${remainingPiece}`);

        const updatedData = { ...currentData, stock: existingStock };
        await updateDoc(documentRef, updatedData);
        console.log(`Open order with id ${docId} successfully updated.`);
      } else {
        console.log(`Stock item with id ${recordx.id} not found in openOrder.`);
      }
    } else {
      console.log('Belirtilen ID ile openOrder belgesi bulunamadı.');
    }
  } catch (error) {
    console.error('OpenOrder güncellenirken bir hata oluştu:', error);
  }
};

const updateOpenOrderWastageAdd = async (docId, recordx, inputValue) => {
  try {
    const documentRef = doc(db, parsCollections.openOrder, docId);
    const currentDocument = await getDoc(documentRef);

    if (currentDocument.exists()) {
      const currentData = currentDocument.data();
      const existingWastage = currentData.wastage || [];

      const wastageIndex = existingWastage.findIndex(wastageItem => wastageItem.id === recordx.id);

      if (wastageIndex !== -1) {
        const wastageItem = existingWastage[wastageIndex];
        const remainingPiece = Number(wastageItem.piece) + Number(inputValue);
        existingWastage[wastageIndex] = { ...wastageItem, piece: remainingPiece };
        console.log(`wastage item with id ${recordx.id} updated in openOrder. Remaining pieces: ${remainingPiece}`);

        const updatedData = { ...currentData, wastage: existingWastage };
        await updateDoc(documentRef, updatedData);
        console.log(`Open order with id ${docId} successfully updated.`);
      } else {
        console.log(`Wastage item with id ${recordx.id} not found in openOrder.`);
      }
    } else {
      console.log('Belirtilen ID ile openOrder belgesi bulunamadı.');
    }
  } catch (error) {
    console.error('OpenOrder güncellenirken bir hata oluştu:', error);
  }
};

const updateOpenOrderWastageRemove = async (docId, recordx, inputValue) => {
  try {
    const documentRef = doc(db, parsCollections.openOrder, docId);
    const currentDocument = await getDoc(documentRef);

    if (currentDocument.exists()) {
      const currentData = currentDocument.data();
      const existingWastage = currentData.wastage || [];

      const wastageIndex = existingWastage.findIndex(wastageItem => wastageItem.id === recordx.id);

      if (wastageIndex !== -1) {
        const wastageItem = existingWastage[wastageIndex];
        const remainingPiece = Number(wastageItem.piece) - Number(inputValue);

        if (remainingPiece <= 0) {
          existingWastage.splice(wastageIndex, 1);
          console.log(`wastageItem item with id ${recordx.id} deleted from openOrder.`);
        } else {
          existingWastage[wastageIndex] = { ...wastageItem, piece: Number(remainingPiece) };
          console.log(`wastageItem item with id ${recordx.id} updated in openOrder. Remaining pieces: ${remainingPiece}`);
        }

        const updatedData = { ...currentData, wastage: existingWastage };
        await updateDoc(documentRef, updatedData);
        console.log(`Open order with id ${docId} successfully updated.`);
      } else {
        console.log(`Stock item with id ${recordx.id} not found in openOrder.`);
      }
    } else {
      console.log('Belirtilen ID ile openOrder belgesi bulunamadı.');
    }
  } catch (error) {
    console.error('OpenOrder güncellenirken bir hata oluştu:', error);
  }
};


//delete
const deleteOpenOrderData = async (documentId) => {
  try {
    const documentRef = doc(db, parsCollections.openOrder, documentId);
    await deleteDoc(documentRef);
    console.log(`Document with key ${documentId} has been deleted successfully`);
    return true;
  } catch (error) {
    console.error("Error deleting document: ", error);
    return false;
  }
};

const deleteOpenOrderDataIsDeleted = async (docId) => {
  const result = updateIsDeleted(parsCollections.openOrder,docId,true);
  return result;
};

//open order update
const updatePurchaseRequestForIs = async (docId, updatedItem) => {
  try {
    const documentRef = doc(db, parsCollections.returnRaw, docId);
    const currentDocument = await getDoc(documentRef);

    if (currentDocument.exists()) {
      const currentData = currentDocument.data();
      const updatedData = { ...currentData, ...updatedItem }; 

      await updateDoc(documentRef, updatedData);

      return { success: true, message: 'Başarıyla güncellendi' };
    } else {
      return { success: false, message: 'Belirtilen ID ile belge bulunamadı' };
    }
  } catch (error) {
    console.error('Güncellenirken bir hata oluştu:', error);
    return { success: false, message: 'Güncellenirken bir hata oluştu' };
  }
};


//stock adet alanını günccellemek için 
const updateStockPiece = async ( docId, newPieceValue) => {
    try {
      const documentRef = doc(db, parsCollections.stock, docId);
      const currentDocument = await getDoc(documentRef);
  
      if (currentDocument.exists()) {
        const currentData = currentDocument.data();
        const updatedData = { piece: Number(newPieceValue) };
  
        await updateDoc(documentRef, updatedData);
  
        return { success: true, message: 'Piece alanı başarıyla güncellendi' };
      } else {
        return { success: false, message: 'Belirtilen ID ile belge bulunamadı' };
      }
    } catch (error) {
      console.error('Piece alanı güncellenirken bir hata oluştu:', error);
      return { success: false, message: 'Piece alanı güncellenirken bir hata oluştu' };
    }
};


//stock reserved piece eklemek için
const updateStockReservedPiece = async (docId, reservedPiece) => {
  try {
    const documentRef = doc(db, parsCollections.stock, docId);
    const currentDocument = await getDoc(documentRef);

    if (currentDocument.exists()) {
      const currentData = currentDocument.data();
      let currentReservedPiece = currentData.reservedPiece || 0;
      let currentPiece = currentData.piece || 0;


      if (currentPiece <= 0) {
        console.log(`Stock item with id ${docId} has insufficient pieces: ${currentPiece}`);
        return { success: false, message: 'Yetersiz stok' };
      }

      const updatedReservedPiece = Number(currentReservedPiece) + Number(reservedPiece);
      const updatedCurrentPiece = Number(currentPiece) - Number(reservedPiece);

      await updateDoc(documentRef, { reservedPiece: Number(updatedReservedPiece) , piece: Number(updatedCurrentPiece)
        
      });

      console.log(`Yeni rezerve edilen parça sayısı: ${updatedReservedPiece}, ${docId}`);
      return { success: true, message: 'Başarılı' };

    } else {
      await setDoc(documentRef, { reservedPiece });
      console.log("Yeni belge oluşturuldu ve rezerve edilen parça kaydedildi.,");
      return { success: true, message: 'Başarılı' };

    }
  } catch (error) {
    return { success: false, message: 'Hata oluştu' };
  }
};


//stock reserved piece çıkarmak için
const updateStockReservedPieceRemove = async (docId, reservedPiece) => {
  try {
    const documentRef = doc(db, parsCollections.stock, docId);
    const currentDocument = await getDoc(documentRef);

    if (currentDocument.exists()) {
      const currentData = currentDocument.data();
      let currentReservedPiece = currentData.reservedPiece || 0;
      let currentPiece = currentData.piece || 0;

      const updatedReservedPiece = Number(currentReservedPiece) - Number(reservedPiece);
      const updatedCurrentPiece = Number(currentPiece) + Number(reservedPiece);

      await updateDoc(documentRef, { reservedPiece: Number(updatedReservedPiece) , piece: Number(updatedCurrentPiece)
      });

      console.log(`Yeni rezerve edilen parça sayısı: ${updatedReservedPiece}`);
    } else {
      await setDoc(documentRef, { reservedPiece });
      console.log("Yeni belge oluşturuldu ve rezerve edilen parça kaydedildi.");
    }
  } catch (error) {
    return { success: false, message: 'Hata oluştu' };
  }
};

//stock waiting piece eklemek için
const updateStockWaitingPiece = async (docId, waitingPiece) => {
  try {
    const documentRef = doc(db, parsCollections.stock, docId);
    const currentDocument = await getDoc(documentRef);

    if (currentDocument.exists()) {
      const currentData = currentDocument.data();
      let currentWaitingPiece = currentData.waitingPiece || 0;

      const updatedWaitingPiece = Number(currentWaitingPiece) + Number(waitingPiece);

      await updateDoc(documentRef, { waitingPiece: Number(updatedWaitingPiece)});

      console.log(`Yeni waiting edilen parça sayısı: ${updatedWaitingPiece}`);
    } else {
      await setDoc(documentRef, { waitingPiece });
      console.log("Yeni belge oluşturuldu ve waiting edilen parça kaydedildi.");
    }
  } catch (error) {
    return { success: false, message: 'Hata oluştu' };
  }
};


//stock waiting piece eklemek için
const updateStockWaitingPieceRemove = async (docId, waitingPiece) => {
  try {
    const documentRef = doc(db, parsCollections.stock, docId);
    const currentDocument = await getDoc(documentRef);

    if (currentDocument.exists()) {
      const currentData = currentDocument.data();
      let currentWaitingPiece = currentData.waitingPiece || 0;

      const updatedWaitingPiece = Number(currentWaitingPiece) - Number(waitingPiece);

      await updateDoc(documentRef, { waitingPiece: Number(updatedWaitingPiece)});

      console.log(`Yeni waiting edilen parça sayısı: ${updatedWaitingPiece}`);
    } else {
      await setDoc(documentRef, { waitingPiece });
      console.log("Yeni belge oluşturuldu ve waiting edilen parça kaydedildi.");
    }
  } catch (error) {
    return { success: false, message: 'Hata oluştu' };
  }
};



//wastagge reserved piece eklemek için
const updateWastageReservedPiece = async (docId, reservedPiece) => {
  try {
    const documentRef = doc(db, parsCollections.wastages, docId);
    const currentDocument = await getDoc(documentRef);

    if (currentDocument.exists()) {
      const currentData = currentDocument.data();
      const currentReservedPiece = isNaN(Number(currentData.reservedPiece)) ? 0 : Number(currentData.reservedPiece);
      const currentPiece = isNaN(Number(currentData.piece)) ? 0 : Number(currentData.piece);
      const currentReservedComingPiece = isNaN(Number(currentData.reservedComingPiece)) ? 0 : Number(currentData.reservedComingPiece);
      const currentComingPiece = isNaN(Number(currentData.comingPiece)) ? 0 : Number(currentData.comingPiece);
  
      const totalAvailable = Number(currentPiece) + Number(currentComingPiece); 
  
      console.log("Initial values:", { currentReservedPiece, currentPiece, currentReservedComingPiece, currentComingPiece });
  
      if (totalAvailable < reservedPiece) {
          console.log(`wastage item with id ${docId} has insufficient total pieces: ${totalAvailable}`);
          return { success: false, message: 'Yetersiz comingPiece ve currentPiece' };
      }
  
      if (currentPiece >= reservedPiece) {
          const updatedReservedPiece = Number(currentReservedPiece) + Number(reservedPiece);
          const updatedCurrentPiece = Number(currentPiece) - Number(reservedPiece);
  
          await updateDoc(documentRef, {
              reservedPiece: updatedReservedPiece,
              piece: updatedCurrentPiece,
          });
  
          console.log(`Yeni rezerve edilen parça sayısı: ${reservedPiece}`);
          return { success: true, message: 'Piece rezerve edildi' };
      } else {
          const pieceToReserve = currentPiece; 
          const comingPieceToReserve = reservedPiece - pieceToReserve; 
  
          const updatedReservedPiece = Number(currentReservedPiece) + pieceToReserve;
          const updatedReservedComingPiece = Number(currentReservedComingPiece) + comingPieceToReserve;
          const updatedCurrentPiece = 0;
          const updatedCurrentComingPiece = Number(currentComingPiece) - comingPieceToReserve;
  
          await updateDoc(documentRef, {
              reservedPiece: updatedReservedPiece,
              reservedComingPiece: updatedReservedComingPiece,
              piece: updatedCurrentPiece,
              comingPiece: updatedCurrentComingPiece,
          });
  
          console.log(`Yeni rezerve edilen parçalar: piece - ${pieceToReserve}, comingPiece - ${comingPieceToReserve}`);
          return { success: true, message: 'Piece ve ComingPiece rezerve edildi' };
      }
  }
   else {
      await setDoc(documentRef, { reservedPiece });
      console.log("Yeni belge oluşturuldu ve rezerve edilen parça kaydedildi.");
      return { success: true };
    }
  } catch (error) {
    console.error("Hata oluştu:", error);
    return { success: false, message: 'Hata oluştu' };
  }
};

const updateWastageReservedPieceRemove = async (docId, reservedPiece) => {
  try {
    const documentRef = doc(db, parsCollections.wastages, docId);
    const currentDocument = await getDoc(documentRef);

    if (currentDocument.exists()) {
      const currentData = currentDocument.data();

      // Retrieve current values or set defaults
      let currentReservedPiece = currentData.reservedPiece || 0;
      let currentReservedComingPiece = currentData.reservedComingPiece || 0;
      let currentPiece = currentData.piece || 0;
      let currentComingPiece = currentData.comingPiece || 0;

      console.log("Initial Data:", {
        currentReservedPiece,
        currentReservedComingPiece,
        currentPiece,
        currentComingPiece,
      });

      if (currentReservedComingPiece > 0) {
        // If `reservedComingPiece` exists, prioritize updating it
        const reservedFromComingPiece = Math.min(currentReservedComingPiece, reservedPiece);
        const updatedReservedComingPiece = currentReservedComingPiece - reservedFromComingPiece;
        const updatedComingPiece = currentComingPiece + reservedFromComingPiece;

        await updateDoc(documentRef, {
          reservedComingPiece: updatedReservedComingPiece,
          comingPiece: updatedComingPiece,
        });

        console.log(`ReservedComingPiece updated: -${reservedFromComingPiece}`);
        reservedPiece -= reservedFromComingPiece;

        if (reservedPiece <= 0) {
          return { success: true, message: `Updated reservedComingPiece. Remaining reservedPiece: ${reservedPiece}` };
        }
      }

      if (currentReservedPiece > 0) {
        // Update `reservedPiece` and `piece` for any remaining `reservedPiece`
        const reservedFromPiece = Math.min(currentReservedPiece, reservedPiece);
        const updatedReservedPiece = currentReservedPiece - reservedFromPiece;
        const updatedCurrentPiece = currentPiece + reservedFromPiece;

        await updateDoc(documentRef, {
          reservedPiece: updatedReservedPiece,
          piece: updatedCurrentPiece,
        });

        console.log(`ReservedPiece updated: -${reservedFromPiece}`);
        return { success: true, message: `ReservedPiece updated: -${reservedFromPiece}` };
      }

      console.log("No reservedPiece or reservedComingPiece available to update.");
      return { success: false, message: "No reservedPiece or reservedComingPiece available to update." };
    } else {
      console.log("Document does not exist.");
      return { success: false, message: "Document does not exist." };
    }
  } catch (error) {
    console.error("An error occurred:", error);
    return { success: false, message: "An error occurred." };
  }
};



  //fire için silme



//jobcode için
const getJobCodesData = async () => {
    const data = await fetchData(parsCollections.jobCodes);
    return data;
};
const deleteJobCodeData = async (documentId) => {
  try {
    const documentRef = doc(db, parsCollections.jobCodes, documentId);
    await deleteDoc(documentRef);
    console.log(`Document with key ${documentId} has been deleted successfully`);
    return true;
  } catch (error) {
    console.error("Error deleting document: ", error);
    return false;
  }
};

//current job code 
const getCurrentJobCodeData = async () => {
  const data = await fetchData(parsCollections.currentJobCode);
  return data;
};

//delete
const deleteCurrentJobCodeData = async (documentId) => {
  try {
    const documentRef = doc(db, parsCollections.currentJobCode, documentId);
    await deleteDoc(documentRef);
    console.log(`Document with key ${documentId} has been deleted successfully`);
    return true;
  } catch (error) {
    console.error("Error deleting document: ", error);
    return false;
  }
};


//güncel fiyat kontrol için
const getCheckCurrentPriceData = async () => {
    const data = await fetchData(parsCollections.checkCurrentPrice);
    return data;
};
const updateCheckCurrentPrice = async ( key, updatedData) => {
    try {
      const documentRef = doc(db, parsCollections.checkCurrentPrice, key);
      await updateDoc(documentRef, updatedData);
  
      return { success: true, message: 'Record updated successfully.' };
    } catch (error) {
      console.error('Error updating record:', error);
      return { success: false, message: 'Error updating record.' };
    }
  };
  

//gelecek hammade için 
const getFutureRawData = async () => {
    const data = await fetchData(parsCollections.futureRaw);
    return data;
};

//admin gelen ekleme istekleri için
const getAddRequestsData = async () => {
    const data = await fetchData(parsCollections.addRequestsAdmin);
    return data;
};

const deleteAddRequest = async (docId) => {
    const result = updateIsDeleted(parsCollections.addRequestsAdmin,docId,true);
    return result;
   };



//özkütle için
const getDensitiesData = async () => {
  const data = await fetchData(parsCollections.densities);
  return data;
};

const deleteDensity = async (docId) => {
  const result = updateIsDeleted(parsCollections.densities,docId,true);
  return result;
};

const makeDensityVisible = async (docId) => {
  const result = await updateIsDeleted(parsCollections.densities,docId,false);
  return result;
};

const updateDensity = async (docId, updatedData) => {
  const result = await updateRecording(parsCollections.densities, docId, updatedData)
  return result;
};




//return raw
const getReturnRawData = async () => {
  const data = await fetchData(parsCollections.returnRaw);
  return data;
};



//exit raw
const getExitRawData = async () => {
  const data = await fetchData(parsCollections.exitRaw);
  return data;
};


//fault
const getFaultData = async () => {
  const data = await fetchData(parsCollections.fault);
  return data;
};

//get production table
const getProductionData = async () => {
  const data = await fetchData(parsCollections.production);
  return data;
};


//get setup table
const getSetupData = async () => {
  const data = await fetchData(parsCollections.setup);
  return data;
};

const updateSetupTable = async (updatedData) => {
  try {
    const querySnapshot = await getDocs(collection(db, parsCollections.setup));

    const promises = updatedData.map(async (item) => {
      const docRef = doc(collection(db, parsCollections.setup), item.id);
      await updateDoc(docRef, {
        priorityMachine: item.priorityMachine,
        // Diğer güncellenecek alanları da buraya ekleyebilirsiniz
      });
    });

    await Promise.all(promises);

    return { success: true, message: 'Tüm kayıtlar başarıyla güncellendi' };
  } catch (error) {
    console.error('Kayıtları güncelleme hatası:', error);
    return { success: false, message: 'Kayıtları güncelleme sırasında bir hata oluştu' };
  }
};


const updateSetupTablePriorityAbkant = async (updatedData) => {
  try {
    const querySnapshot = await getDocs(collection(db, parsCollections.setup));

    const promises = updatedData.map(async (item) => {
      const docRef = doc(collection(db, parsCollections.setup), item.id);
      await updateDoc(docRef, {
        priorityAbkant: item.priorityAbkant,
        // Diğer güncellenecek alanları da buraya ekleyebilirsiniz
      });
    });

    await Promise.all(promises);

    return { success: true, message: 'Tüm kayıtlar başarıyla güncellendi' };
  } catch (error) {
    console.error('Kayıtları güncelleme hatası:', error);
    return { success: false, message: 'Kayıtları güncelleme sırasında bir hata oluştu' };
  }
};


const deleteSetupData = async (documentId) => {
  /*
  try {
    const documentRef = doc(db, parsCollections.setup, documentId);
    await deleteDoc(documentRef);
    console.log(`Document with key ${documentId} has been deleted successfully`);
    return true;
  } catch (error) {
    console.error("Error deleting document: ", error);
    return false;
  }
  */
  const result = updateIsDeleted(parsCollections.setup,documentId,true);
  return result;
};


const deletePartData = async (documentId) => {
  try {
    const documentRef = doc(db, parsCollections.parts, documentId);
    await deleteDoc(documentRef);
    console.log(`Document with key ${documentId} has been deleted successfully`);
    return true;
  } catch (error) {
    console.error("Error deleting document: ", error);
    return false;
  }
};
const updateOpenOrderPriorityTable = async (updatedData) => {
  try {
    const querySnapshot = await getDocs(collection(db, parsCollections.openOrder));

    const promises = updatedData.map(async (item) => {
      const docRef = doc(collection(db, parsCollections.openOrder), item.id);
      await updateDoc(docRef, {
        priority: item.priority,
      });
    });

    await Promise.all(promises);

    return { success: true, message: 'Tüm kayıtlar başarıyla güncellendi open order' };
  } catch (error) {
    console.error('Kayıtları güncelleme hatası: open order', error);
    return { success: false, message: 'Kayıtları güncelleme sırasında bir hata oluştu open order' };
  }
};



//open order offer
const getOpenOrderOfferData = async () => {
  const data = await fetchData(parsCollections.invoiceOpenOrder);
  return data;
};

const deleteOpenOrderOfferData = async (documentId) => {
  try {
    const documentRef = doc(db, parsCollections.invoiceOpenOrder, documentId);
    await deleteDoc(documentRef);
    console.log(`Document with key ${documentId} has been deleted successfully`);
    return true;
  } catch (error) {
    console.error("Error deleting document: ", error);
    return false;
  }
};

const invoiceOpenOrderIsVisible = async (docId) => {
  const result = await updateIsDeleted(parsCollections.invoiceOpenOrder,docId,true);
  return result;
};

//machine için
const getMachinesData = async () => {
  const data = await fetchData(parsCollections.machines);
  return data;
};
const deleteMachine = async (docId) => {
  const result = updateIsDeleted(parsCollections.machines,docId,true);
  return result;
 };
const makeMachineVisible = async (docId) => {
  const result = await updateIsDeleted(parsCollections.machines,docId,false);
  return result;
};



//parts
const getPartsData = async () => {
  const data = await fetchData(parsCollections.parts);
  return data;
};


//update arıza listesi arıza giderildi
const updateFixedFault = async ( docId, fixedDate,fixedUserId) => {
  try {
    const documentRef = doc(db, parsCollections.fault, docId);
    const currentDocument = await getDoc(documentRef);

    if (currentDocument.exists()) {
      const updatedData = { 
        isFixed: true ,
        fixedDate:fixedDate,
        fixedUserId:fixedUserId
      };
      await updateDoc(documentRef, updatedData);

      return { success: true, message: 'Başarıyla güncellendi' };
    } else {
      return { success: false, message: 'Belirtilen ID ile belge bulunamadı' };
    }
  } catch (error) {
    console.error('Güncellenirken bir hata oluştu:', error);
    return { success: false, message: 'Güncellenirken bir hata oluştu' };
  }
};

//user teknik ressam perfomans güncelleme 
const updateUserPerformance = async (docId, firstTransactionDate, setupTime) => {
  const documentRef = doc(db, parsCollections.users, docId);
  
  const newPerformanceEntry = {
    firstTransactionDate: firstTransactionDate,
    setupTime: setupTime
  };

  const updatedFields = {
    setupPerformance: arrayUnion(newPerformanceEntry) 
  };

  try {
    await updateDoc(documentRef, updatedFields);
    return { message: "Başarılı", success: true };
  } catch (error) {
    return error;
  }
};



//hurda için
const getScrapsData = async () => {
  const data = await fetchData(parsCollections.scraps);
  return data;
};



//satın alınan ürünler için fiyat
const getInvoicePurchaseData = async () => {
  const data = await fetchData(parsCollections.invoicePurchase);
  return data;
};



const updateInvoicePrice = async (docId, invoicePrice) => {
  try {
    const documentRef = doc(db, parsCollections.invoicePurchase, docId);
    const currentDocument = await getDoc(documentRef);

    if (currentDocument.exists()) {
      const updatedData = { invoicePrice: Number(invoicePrice) }; // Burada invoicePrice olarak güncelliyoruz
      await updateDoc(documentRef, updatedData);

      return { success: true, message: 'Başarıyla güncellendi' };
    } else {
      return { success: false, message: 'Belirtilen ID ile belge bulunamadı' };
    }
  } catch (error) {
    console.error('Güncellenirken bir hata oluştu:', error);
    return { success: false, message: 'Güncellenirken bir hata oluştu' };
  }
};

const updateOperations = async (updatedData) => {
  const tables = [
    parsCollections.setup,
    parsCollections.parts,
    parsCollections.contractManufacturing
  ];
  try {
    for (const table of tables) {
      console.log(`\n=== Processing table: ${table} ===\n`);
      // Verileri al
      const records = await fetchData(table);
      if (!records || records.length === 0) {
        console.log(`No records found for table: ${table}`);
        continue;
      }
      if (table === parsCollections.setup) {
        // Setup tablosu güncellemesi
        for (const item of updatedData) {
          const record = records.find(r => r.jobCode === item.jobCode);
          if (!record) continue;
          const docRef = doc(collection(db, table), record.key);
          // Parçaların operasyonlarını güncelle
          const updatedParts = record.parts.map(part => {
            const updatedPart = item.parts.find(p => p.partNo === part.partNo);
            if (updatedPart) {
              return {
                ...part,
                operations: updatedPart.operations
              };
            }
            return part;
          });
          // ABKANT operasyonu kontrolü
          const hasAbkant = item.parts.some(part =>
            part.operations && part.operations.includes('ABKANT')
          );
          const updatedRecord = {
            parts: updatedParts,
            ...(hasAbkant && { priorityAbkant: 999999999 })
          };
          await updateDoc(docRef, updatedRecord);
        }
      }
      else if (table === parsCollections.parts) {
        // Parts tablosu güncellemesi
        for (const item of updatedData) {
          // Her bir part için ayrı güncelleme yap
          for (const updatedPart of item.parts) {
            const partRecord = records.find(r =>
              r.jobCode === item.jobCode && r.partNo === updatedPart.partNo
            );
            if (!partRecord) continue;
            const docRef = doc(collection(db, table), partRecord.key);
            const operationsArray = updatedPart.operations.map(op => ({
              firstUserId: null,
              firstUserNameSurname: null,
              isCompleted: false,
              isContractManufacturing: false,
              isQrScan: false,
              processName: op
            }));
            await updateDoc(docRef, { operations: operationsArray });
          }
        }
      }
      else if (table === parsCollections.contractManufacturing) {
        // ContractManufacturing tablosu güncellemesi
        for (const item of updatedData) {
          const contractRecords = records.filter(r => r.jobCode === item.jobCode);
          const existingProcessNames = new Set(contractRecords.map(r => r.processName));
          // Yeni process gruplarını oluştur
          const processGroups = {};
          item.parts.forEach(part => {
            part.operations.forEach(processName => {
              // ABKANT operasyonunu contractManufacturing'e ekleme
              if (processName !== 'ABKANT') {
                if (!processGroups[processName]) {
                  processGroups[processName] = [];
                }
                processGroups[processName].push({
                  partCode: part.partNo,
                  piece: part.piece || 1,
                  profit: null,
                  unitPrice: null
                });
              }
            });
          });
          // Mevcut kayıtları güncelle veya sil
          for (const record of contractRecords) {
            const docRef = doc(collection(db, table), record.key);
            if (processGroups[record.processName]) {
              // Process hala kullanılıyorsa güncelle
              await updateDoc(docRef, {
                parts: processGroups[record.processName]
              });
              delete processGroups[record.processName];
            } else {
              // Process artık kullanılmıyorsa sil
              await deleteDoc(docRef);
            }
          }
          // Yeni process grupları için kayıt oluştur
          for (const [processName, parts] of Object.entries(processGroups)) {
            if (!existingProcessNames.has(processName)) {
              const newDocRef = doc(collection(db, table));
              const baseRecord = contractRecords[0] || {};
              await setDoc(newDocRef, {
                ...baseRecord,
                key: newDocRef.id,
                processName: processName,
                parts: parts,
                jobCode: item.jobCode
              });
            }
          }
        }
      }
    }
    console.log('\nAll tables have been updated successfully.\n');
    return { success: true, message: 'Operations successfully updated in all tables' };
  } catch (error) {
    console.error('Error updating operations in all tables:', error);
    return { success: false, message: 'An error occurred while updating operations' };
  }
};

//invoiceopenorder
const getInvoiceOpenOrderData = async () => {
  const data = await fetchData(parsCollections.invoiceOpenOrder);
  return data;
}

//fatura kategorileri
const getInvoiceCategoryData = async () => {
  const data = await fetchData(parsCollections.invoiceCategory);
  return data;
};

const getInvoiceCategoryContentsData = async () => {
  const data = await fetchData(parsCollections.invoiceCategoryContents);
  return data;
};


//giderler
const getExpensesData = async () => {
  const data = await fetchData(parsCollections.expenses);
  return data;
};


//haberler
const getNewData = async () => {
  const data = await fetchData(parsCollections.news);
  return data;
 };
const deleteNew = async (docId) => {
  const result = await updateIsDeleted(parsCollections.news,docId,true);
  return result;
 };
 
 const makeNewVisible = async (docId) => {
  const result = await updateIsDeleted(parsCollections.news,docId,false);
  return result;
 };
 
 const updateNew = async (docId, updatedData) => {
  const result = await updateRecording(parsCollections.news, docId, updatedData)
  return result;
 };




 //duyurular
const getAnnouncementData = async () => {
  const data = await fetchData(parsCollections.announcements);
  return data;
 };
const deleteAnnouncement = async (docId) => {
  const result = await updateIsDeleted(parsCollections.announcements,docId,true);
  return result;
 };
 
 const makeAnnouncementVisible = async (docId) => {
  const result = await updateIsDeleted(parsCollections.announcements,docId,false);
  return result;
 };
 
 const updateAnnouncement = async (docId, updatedData) => {
  const result = await updateRecording(parsCollections.announcements, docId, updatedData)
  return result;
 };



 //companyName değişirse
 const updateCompanyNameInAllTables = async (oldCompanyName, newCompanyName) => {
 
  const tables = [
    parsCollections.purchaseRequests,
    parsCollections.openOrder,
    parsCollections.exitRaw,
    parsCollections.parts,
    parsCollections.setup,
    parsCollections.futureRaw,
    parsCollections.invoicePurchase,
    parsCollections.invoiceOpenOrder,
    parsCollections.returnRaw,
    parsCollections.stock,
    parsCollections.wastages,
    parsCollections.alarms,
    parsCollections.contractManufacturing
  ];

  const tablesDepotName = [
    parsCollections.openOrder,
    parsCollections.stock,
    parsCollections.wastages,
  ];
  try {
    for (const table of tables) {
      const records = await fetchData(table);
      const recordsToUpdate = records.filter(record => record.companyName === oldCompanyName);
      const recordsToUpdateDepotName = records.filter(record => record.depotName === oldCompanyName);

      console.log("recordsToUpdate",recordsToUpdate)
     
      for (const record of recordsToUpdate) {
        const updatedRecord = { ...record, companyName: newCompanyName };
        await updateRecording(table, record.key, updatedRecord);
      }

      for (const recordDepotName of recordsToUpdateDepotName) {
        console.log("depot nameee",recordDepotName)
        const updatedRecordDepotName = { ...recordDepotName, depotName: newCompanyName };
        await updateRecording(table, recordDepotName.key, updatedRecordDepotName);
      }

      console.log(`Updated ${recordsToUpdate.length} records in ${table}`);
    }

    for (const table of tablesDepotName) {
      console.log("buraya geldi")
      const records = await fetchData(table);
      const recordsToUpdateDepotName = records.filter(record => record.depotName === oldCompanyName);

      for (const recordDepotName of recordsToUpdateDepotName) {
        console.log("depot nameee",recordDepotName)
        const updatedRecordDepotName = { ...recordDepotName, depotName: newCompanyName };
        await updateRecording(table, recordDepotName.key, updatedRecordDepotName);
      }

      if (table === parsCollections.openOrder) {
        for (const record of records) {
            let isRecordUpdated = false;
    
            // Process stock array directly
            record.stock?.forEach(stockItem => {
              console.log("stockItem",stockItem)
                if (stockItem.depotName === oldCompanyName) {
                    console.log("stok depo aynı", stockItem.depotName, oldCompanyName);
                    stockItem.depotName = newCompanyName;
                    isRecordUpdated = true;
                }
            });
    
            // Process wastage array directly
            record.wastage?.forEach(wastageItem => {
              console.log("wastageItem",wastageItem)
                if (wastageItem.depotName === oldCompanyName) {
                    console.log("wastage depo aynı", wastageItem.depotName, oldCompanyName);
                    wastageItem.depotName = newCompanyName;
                    isRecordUpdated = true;
                }
            });
    
            // Update the record if any changes were made
            if (isRecordUpdated) {
                const updateResult = await updateRecording(table, record.key, record);
                if (updateResult) {
                    console.log(`Successfully updated record with key: ${record.key} in openOrder`);
                } else {
                    console.error(`Failed to update record with key: ${record.key} in openOrder`);
                }
            }
        }
    
        console.log(`Completed updating openOrder items in ${table}`);
    }
    

      console.log(`Updated ${recordsToUpdateDepotName.length} records in ${table}`);
    }
    return true;  
  } catch (error) {
    console.error('Error updating company name:', error);
    return false;  
  }
};

//Open orderda itemdeki stockta da surface var orda da değiştirmem lazım
const updateSurfaceNameInAllTables = async (oldSurfaceName, newSurfaceName) => {
  const tables = [
    parsCollections.purchaseRequests,
    parsCollections.openOrder,
    parsCollections.exitRaw,
    parsCollections.parts,
    parsCollections.setup,
    parsCollections.futureRaw,
    parsCollections.invoicePurchase,
    parsCollections.invoiceOpenOrder,
    parsCollections.returnRaw,
    parsCollections.stock,
    parsCollections.wastages,
    parsCollections.checkCurrentPrice,
    parsCollections.allItems,
    parsCollections.addRequestsAdmin
  ];

  try {
    for (const table of tables) {
      try {
        console.log(`Processing table: ${table}`);
        
        // Fetching records
        const records = await fetchData(table);
        if (!records || records.length === 0) {
          console.log(`No records found for table: ${table}`);
          continue; // Bu tabloyu atla, çünkü kayıt yok
        }
        console.log(`Fetched ${records.length} records from table: ${table}`);

        if (table === parsCollections.openOrder) {
          // openOrder tablosu için özel işlem
          for (const record of records) {
            let isRecordUpdated = false;

            // Her item içindeki stock ve wastage arraylerini kontrol et
            record.items?.forEach(item => { //record.items diyo acaba items değil mi bak BURAYA GİRMİYO
              // stock arrayini gez ve oradaki surface'i güncelle
              item.stock?.forEach(stockItem => {
                if (stockItem.surface === oldSurfaceName) {
                  console.log(`Updating stock surface from ${oldSurfaceName} to ${newSurfaceName} for record key: ${record.key}`);
                  stockItem.surface = newSurfaceName;
                  isRecordUpdated = true;
                }
              });

              // wastage arrayini gez ve oradaki surface'i güncelle
              item.wastage?.forEach(wastageItem => {
                if (wastageItem.surface === oldSurfaceName) {
                  console.log(`Updating wastage surface from ${oldSurfaceName} to ${newSurfaceName} for record key: ${record.key}`);
                  wastageItem.surface = newSurfaceName;
                  isRecordUpdated = true;
                }
              });
            });

            if (isRecordUpdated) {
              // Güncellenen kaydı veritabanına kaydet
              const updateResult = await updateRecording(table, record.key, record);
              if (updateResult) {
                console.log(`Successfully updated record with key: ${record.key} in openOrder`);
              } else {
                console.error(`Failed to update record with key: ${record.key} in openOrder`);
              }
            }
          }

          console.log(`Completed updating openOrder items in ${table}`);
        } else {
          // Diğer tablolar için genel işlem
          const recordsToUpdate = records.filter(record => record.surface === oldSurfaceName);
          if (recordsToUpdate.length === 0) {
            console.log(`No records to update in ${table} for surface: ${oldSurfaceName}`);
            continue;
          }

          for (const record of recordsToUpdate) {
            const updatedRecord = { ...record, surface: newSurfaceName };
            const updateResult = await updateRecording(table, record.key, updatedRecord);
            if (updateResult) {
              console.log(`Successfully updated record with key: ${record.key} in ${table}`);
            } else {
              console.error(`Failed to update record with key: ${record.key} in ${table}`);
            }
          }

          console.log(`Updated ${recordsToUpdate.length} records in ${table}`);
        }
      } catch (innerError) {
        console.error(`Error processing table ${table}:`, innerError);
      }
    }

    console.log('Surface name update completed in all tables.');
    return true;
  } catch (error) {
    console.error('General error updating surface name:', error);
    return false;
  }
};



const updateMailInAllTables = async (oldEmail, newEmail) => {
  //users , purchaserequests,companies, addrequestsadmin , 
  const tables = [
    parsCollections.purchaseRequests,
    parsCollections.users,
    parsCollections.companies,
    parsCollections.addRequestsAdmin
  ];

  try {
    for (const table of tables) {
      const records = await fetchData(table);
      const recordsToUpdate = records.filter(record => record.email === oldEmail);
      console.log("recordsToUpdate", recordsToUpdate);

      for (const record of recordsToUpdate) {
        const updatedRecord = { ...record, email: newEmail };
        await updateRecording(table, record.key, updatedRecord);
      }

      console.log(`Updated ${recordsToUpdate.length} records in ${table}`);
    }
    console.log("Email update completed in all tables.");

    return true;  
  } catch (error) {
    console.error("Error updating email:", error);
    return false;  
  }
};

const updateDensityInAllTables = async (oldData, newData) => {
  console.log("oldData",oldData)
  console.log("newData",newData)
  const tables = [
    parsCollections.stock,
    parsCollections.wastages,
    parsCollections.futureRaw,
    parsCollections.checkCurrentPrice,
    parsCollections.returnRaw,
    parsCollections.invoicePurchase,
  ];

  try {
    for (const table of tables) {
      const records = await fetchData(table);
      
      const recordsToUpdate = records.filter(record => record.quality === oldData.quality);

      for (const record of recordsToUpdate) {
        let updatedRecord = { ...record };
      
        if (oldData.density !== newData.density) {
          console.log("density değişti")
          const { width, height, thickness } = record;
      
          const newUnitKilo = (width * height * thickness * newData.density) / 1000000;
          updatedRecord.unitKilo = newUnitKilo; 
        }
      
        if (oldData.quality !== newData.quality) {
          console.log("quality değişti")

          updatedRecord.quality = newData.quality; 
        }
      
        if (oldData.density !== newData.density || oldData.quality !== newData.quality) {
          await updateRecording(table, record.key, updatedRecord);
        }
      }
      

      console.log(`Updated ${recordsToUpdate.length} records in ${table}`);
      
    }
    if (oldData.quality !== newData.quality) {
      console.log("quality name değişmiş")
      const result = await updateQualityInOtherTables(oldData, newData);
      console.log("result update quality",result)
      if (result) {
        console.log('Updated quality in other tables successfully.');
      } else {
        console.error('Failed to update quality in other tables.');
        return false; // Return false if updating quality failed
      }
    }

    console.log('Density update completed in all tables.');
    return true;
  } catch (error) {
    console.error('Error updating records:', error);
    return false;
  }
};


const updateQualityInOtherTables = async (oldData, newData) => {
  const otherTables = [
    parsCollections.openOrder, // stock and wastage inside
    parsCollections.parts,
    parsCollections.allItems, // productDictionary inside
    parsCollections.exitRaw,
    parsCollections.invoiceOpenOrder, // items inside
    parsCollections.purchaseRequests, // requestedProductInfo and checkedRequestedProductInfo if they exist
    parsCollections.setup, // also update in stock, wastage, and parts inside
  ];

  let overallSuccess = true; // To track if all updates were successful

  try {
    for (const table of otherTables) {
      const records = await fetchData(table);
      let tableUpdated = false; // Track if any record was updated in this table

      // Check for each table type and update relevant fields
      for (const record of records) {
        let updatedRecord = { ...record };
        let isUpdated = false;

        // For openOrder table, check stock and wastage inside
        if (table === parsCollections.openOrder) {
          if (Array.isArray(record.stock)) {
            updatedRecord.stock = record.stock.map(item => {
              if (item.quality === oldData.quality) {
                isUpdated = true;
                return { ...item, quality: newData.quality };
              }
              return item;
            });
          }

          if (Array.isArray(record.wastage)) {
            updatedRecord.wastage = record.wastage.map(item => {
              if (item.quality === oldData.quality) {
                isUpdated = true;
                return { ...item, quality: newData.quality };
              }
              return item;
            });
          }
        }

        // For parts and allItems (productDictionary inside)
        if (table === parsCollections.allItems) {
          if (record.productDictionary?.quality === oldData.quality) {
            updatedRecord.productDictionary.quality = newData.quality;
            isUpdated = true;
          }
        } 
        
        // For parts and exitRaw, update quality directly
        if (table === parsCollections.parts || table === parsCollections.exitRaw) {
          if (record.quality === oldData.quality) {
            updatedRecord.quality = newData.quality;
            isUpdated = true;
          }
        }

        // For invoiceOpenOrder, check items inside
        if (table === parsCollections.invoiceOpenOrder) {
          if (Array.isArray(record.items)) {
            updatedRecord.items = record.items.map(item => {
              if (item.quality === oldData.quality) {
                isUpdated = true;
                return { ...item, quality: newData.quality };
              }
              return item;
            });
          }
        }

        // For purchaseRequests, update requestedProductInfo and checkedRequestedProductInfo if present
        if (table === parsCollections.purchaseRequests) {
          if (Array.isArray(record.requestedProductInfo)) {
            updatedRecord.requestedProductInfo = record.requestedProductInfo.map(item => {
              if (item.quality === oldData.quality) {
                isUpdated = true;
                return { ...item, quality: newData.quality };
              }
              return item;
            });
          }

          if (Array.isArray(record.checkedRequestedProductInfo)) {
            updatedRecord.checkedRequestedProductInfo = record.checkedRequestedProductInfo.map(item => {
              if (item.quality === oldData.quality) {
                isUpdated = true;
                return { ...item, quality: newData.quality };
              }
              return item;
            });
          }
        }

        // For setup, update both outside and inside stock, wastage, and parts
        if (table === parsCollections.setup) {
          // Update the root quality
          if (record.quality === oldData.quality) {
            updatedRecord.quality = newData.quality;
            isUpdated = true;
          }

          // Update quality in the stock array
          if (Array.isArray(record.stock)) {
            updatedRecord.stock = record.stock.map(item => {
              if (item.quality === oldData.quality) {
                isUpdated = true;
                return { ...item, quality: newData.quality };
              }
              return item;
            });
          }

          // Update quality in the wastage array
          if (Array.isArray(record.wastage)) {
            updatedRecord.wastage = record.wastage.map(item => {
              if (item.quality === oldData.quality) {
                isUpdated = true;
                return { ...item, quality: newData.quality };
              }
              return item;
            });
          }

          // Update quality in the parts array
          if (Array.isArray(record.parts)) {
            updatedRecord.parts = record.parts.map(item => {
              if (item.quality === oldData.quality) {
                isUpdated = true;
                return { ...item, quality: newData.quality };
              }
              return item;
            });
          }
        }

        // Update record if any changes were made
        if (isUpdated) {
          await updateRecording(table, record.key, updatedRecord);
          console.log(`Updated record in ${table} with key ${record.key}`);
          tableUpdated = true; // Mark table as updated
        }
      }

      console.log("tableUpdated",tableUpdated)
      if (tableUpdated) {
        console.log(`Records updated in ${table}.`);
      } else {
        console.log(`No records updated in ${table}.`);
        tableUpdated = true; // Mark table as updated

      }
      
      // If no records were updated, consider the overall success false
      if (!tableUpdated) {
        overallSuccess = false;
      }
    }

    console.log('Quality update completed in other specific tables.');
    return overallSuccess; // Return the overall success status
  } catch (error) {
    console.error('Error updating quality in other tables:', error);
    return false; // Return false in case of an error
  }
};



const fetchData = async (collectionName) => {
 const querySnapshot = await getDocs(collection(db, collectionName));
 const data = [];

 querySnapshot.forEach(doc => {
 const docData = doc.data();
 docData.key = doc.id;

 if (docData.firstTransactionDate && docData.firstTransactionDate.toDate) {
 const firstTransactionDate = docData.firstTransactionDate.toDate();
 //const formattedReleaseDate = `${firstTransactionDate.getDate().toString().padStart(2, '0')}.${firstTransactionDate.getMonth() + 1}.${firstTransactionDate.getFullYear()}`;
 docData.firstTransactionDate = firstTransactionDate;
 docData.firstTransactionDateEdit = firstTransactionDate;
 }

 if (docData.releaseDate && docData.releaseDate.toDate) {
 const releaseDate = docData.releaseDate.toDate();

 const day = releaseDate.getDate().toString().padStart(2, '0');
 const month = (releaseDate.getMonth() + 1).toString().padStart(2, '0');
 const year = releaseDate.getFullYear();
 
 const formattedReleaseDate = `${day}.${month}.${year}`;
 
 docData.releaseDate = formattedReleaseDate;
 docData.releaseDateEdit = releaseDate;

 }

 if (docData.createdDate && docData.createdDate.toDate) {
 const createdDate = docData.createdDate.toDate();

 const formattedCreatedDate = `${createdDate.getDate().toString().padStart(2, '0')}.${createdDate.getMonth() + 1}.${createdDate.getFullYear()}`;

 docData.createdDate = formattedCreatedDate;
 docData.createdDateEdit = createdDate;
 }

 if (docData.startDate && docData.startDate.toDate) {
 const startDate = docData.startDate.toDate();
 
 const formattedendDate = `${startDate.getDate().toString().padStart(2, '0').toString().padStart(2, '0')}/${(startDate.getMonth() + 1).toString().padStart(2, '0')}/${startDate.getFullYear()}`;
 docData.startDate = formattedendDate;
 docData.startDateEdit = startDate;
 }

 if (docData.endDate && docData.endDate.toDate) {
 const endDate = docData.endDate.toDate();
 const formattedendDate = `${endDate.getDate().toString().padStart(2, '0')}/${(endDate.getMonth() + 1).toString().padStart(2, '0')}/${endDate.getFullYear()}`;
 docData.endDate = formattedendDate;
 docData.endDateEdit = endDate;
 }

 if (docData.meetingDate && docData.meetingDate.toDate) {
 const meetingDate = docData.meetingDate.toDate();
 const formattedMeetingDateDate = `${meetingDate.getDate().toString().padStart(2, '0')}/${(meetingDate.getMonth() + 1).toString().padStart(2, '0')}/${meetingDate.getFullYear()}`;
 docData.meetingDate = formattedMeetingDateDate;
 docData.meetingDateEdit = meetingDate;
 }

 if (docData.auditDate && docData.auditDate.toDate) {
 const auditDate = docData.auditDate.toDate();
 const formattedauditDateDate = `${auditDate.getDate().toString().padStart(2, '0')}/${(auditDate.getMonth() + 1).toString().padStart(2, '0')}/${auditDate.getFullYear()}`;
 docData.auditDate = formattedauditDateDate;
 docData.auditDateEdit = auditDate;
 }

 if (docData.projectProcess) {
 docData.projectProcess.map(dte=>{
 if(dte.date.toDate())
 {
 const date = dte.date.toDate();
 const formattedendDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
 dte.date = formattedendDate;
 dte.dateEdit = date;
 } 
 }) 
 }

 if (docData.b2bAndf2f) {
 docData.b2bAndf2f.map(dte=>{
 if(dte.date.toDate())
 {
 const date = dte.date.toDate();
 const formattedendDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
 dte.date = formattedendDate;
 dte.dateEdit = date;
 } 
 }) 
 }

 if (docData.companyDates) {
 docData.companyDates.map(dte=>{
 if(dte.date.toDate())
 {
 const date = dte.date.toDate();
 const formattedendDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
 dte.date = formattedendDate;
 dte.dateEdit = date;
 } 
 }) 
 }

 if (docData.deliveryDate && docData.deliveryDate.toDate) {
 const deliverydate = docData.deliveryDate.toDate();
 const formattedendDate = `${deliverydate.getDate().toString().padStart(2, '0')}/${deliverydate.getMonth() + 1}/${deliverydate.getFullYear()}`;
 docData.deliveryDate = formattedendDate;
 docData.deliveryDateEdit = deliverydate;
 }
 data.push(docData);
 });

 return data;
};



//generic delete
const updateIsDeleted = async (collectionName, documentId,visibility) => {
 const documentRef = doc(db, collectionName, documentId);
 const updatedFields = {
 isDeleted: visibility
 };

 try {
 await updateDoc(documentRef, updatedFields);
 return {message:"Kayıdın gizlilik durumu başarıyla güncellendi.",success:true};
 } catch (error) {
 return error;
 }
};

//generic add
const addRecording = async (collectionName,recordingObject) => {
 try {
 const collectionRef = collection(db, collectionName);
 const docRef = await addDoc(collectionRef, recordingObject);
 return { message: "Kayıt başarıyla oluşturuldu.", success: true, docId: docRef.id };
 } catch (error) {
 return error;
 }
};


//generci update 

const updateRecording= async (collectionName, docId, updatedData) => {
 try {
 const documentRef = doc(db, collectionName, docId)
 await updateDoc(documentRef,updatedData); 
 return { success: true, message: 'Kayıt başarıyla güncellendi' };
 } catch (error) {
 return { success: false, message: 'Kayıt güncellenirken bir hata oluştu' };
 }
};




//user
const getUserData = async () => {
 const data = await fetchData(parsCollections.users);
 return data;
};

const deleteUser = async (docId) => {
 const result = await updateIsDeleted(parsCollections.users,docId,true);
 return result;
 };
 
 const makeUserVisible = async (docId) => {
 const result = await updateIsDeleted(parsCollections.users,docId,false);
 return result;
 };

 const updateAuthorizationPages = async (docId,authorizationPage,userType) => {
 const documentRef = doc(db, parsCollections.users, docId);
 const updatedFields = {
 authorizationPages : authorizationPage,
 userType: userType
 };
 
 try {
 await updateDoc(documentRef, updatedFields);
 return {message:"Kullanıcının erişebilirlik sayfaları güncellendi.",success:true};
 } catch (error) {
 return error;
 }
 };

 const updateUserType = async (docId,userType) => {
 const documentRef = doc(db, parsCollections.users, docId);
 const updatedFields = {
 userType: userType
 };

 try {
 await updateDoc(documentRef, updatedFields);
 return {message:"Kullanıcının yetkisi güncellendi.",success:true};
 } catch (error) {
 return error;
 }
 };





 

 
 
 






//bildirim
const getNotificationData = async () => {
const data = await fetchData(parsCollections.notifications);
return data;
};

const deleteNotification = async (docId) => {
 const result = updateIsDeleted(parsCollections.notifications,docId,true)
 return result;
};

const makeNotificationVisible = async (docId) => {
 const result = await updateIsDeleted(parsCollections.notifications,docId,false);
 return result;
};


const getNotificationDataByUserId = async () => {
  const firstUserId = localStorage.getItem("uid");
  const notifications = await fetchData(parsCollections.notifications);
  const matchingNotifications = [];

  notifications.forEach(notification => {
      if (notification.sentUserIds && notification.sentUserIds[firstUserId] !== undefined) {
          matchingNotifications.push(notification);
      }
  });

  return matchingNotifications;
};

//
const getCurrencyData = async () => {
  const data = await fetchData(parsCollections.currency);
  return data;
  };
  

const getFilteredUserData = async () => {
 const firstUserId = localStorage.getItem("uid");
 const data = await fetchData(parsCollections.users);
 const filteredUser = data.find(user => user.userId === firstUserId);
 // console.log("Filtered User Data:", filteredUser);

 return filteredUser;
};


const auth = getAuth(); 

const registerUser = async (email, additionalInfo) => {
 try {
 const userCredential = await createUserWithEmailAndPassword(auth, email);
 const user = userCredential.user;
 await FirebaseService.createUser(user, additionalInfo);
 console.log("Kullanıcı başarıyla kaydedildi:", user);
 
 return user;
 } catch (error) {
 console.error("Kullanıcı kaydı sırasında hata oluştu:", error);
 throw error;
 }
};


const registerUserWithGoogle = async () => {
 const googleProvider = new GoogleAuthProvider();
 try {
 // await signInWithRedirect(auth, googleProvider);
 // const userCredential = await getRedirectResult(auth);
 const userCredential = await signInWithPopup(auth, googleProvider);
 const user = userCredential.user;
 const existingUserData = await FirebaseService.getUserByUid(user.uid);

 if (!existingUserData) {
 await FirebaseService.createUserGoogle(user, user.displayName);
 console.log("Google ile Kullanıcı başarıyla kaydedildi:");
 return user;
 }
 return user;

 } catch (error) {
 console.error('Google registration failed:', error);
 throw error;
 }
};


const createUserGoogle = async (userData, name) => {
 try {
 const usersCollectionRef = collection(db, 'users');
 const userId = auth.currentUser.uid; 
 const docRef = doc(usersCollectionRef, userId);

 const nameSurname = name ;

 await setDoc(docRef, {
 nameSurname,
 companyName: "",
 email: userData.email || "",
 city: "",
 userType: ["user"],
 isDeleted: false, 
 loginType: "google-web",
 oneSignalPlayerId: "",
 createdDate: new Date(),
 userId : userId,
 imageUrl: ""
 });
 
 console.log('User successfully registered!');
 } catch (error) {
 console.error('Error occurred while registering user:', error);
 throw error;
 }
};


 const FirebaseService = {
  
  listenToCompaniesData,
  listenToJobCodesData,
  listenToStocksData,
  listenToWastagesData,
  
  
  updateRecording,

  
    createUser,
    updateUser,

getTitlesData,
makeTitleVisible,
deleteTitle,
updateTitle,

    
getSurfacesData,
makeSurfaceVisible,
deleteSurface,
updateSurface,


getUnitsData,
makeUnitVisible,
deleteUnit,
updateUnit,
getUnitDataById,

getCompaniesData,
makeCompanyVisible,
deleteCompany,
updateCompany,
getCompanyDataById,


getAllItemsData,
getAllItemtDataById,
deleteAllItemProduct,
makeAllItemVisible,
updateAllItemProduct,

addRecordingForDays,
getDaysData,

getPurchaseRequestData,
addPurchaseRequest,
deletePurchaseRequestData,
updatePurchaseRequestData,
updatePurchaseRequestIncomingExcellData,
updatePurchaseRequestAcceptExcellData,
updatePurchaseRequetIncomingExcellData,
updatePurchaseData,
updatePurchaseDataRequestedProductInfo,


getMailsData,
makeMailVisible,
deleteMail,
updateMail,

getContractManufacturingProcessesData,
deleteContractManufacturingProcess,
makeContractManufacturingProcessVisible,


getContractManufacturingData,
deleteContractManufacturingData,

getSuppliersData,
deleteSupplier,
makeSupplierVisible,

getPaymentMethodsData,
deletePaymentMethods,
makePaymentMethodsVisible,

getAlarmsData,
updateAlarmRequestRemovalData,

getStocksData,
getStockItemById,

deleteStock,
updateStock,


getWastagesData,
getWastageItemById,
deleteWastageData,

getOpenOrderData,
updateOpenOrder,
deleteOpenOrderData,
deleteOpenOrderDataIsDeleted,


updateOpenOrderStockAndWastage,
updateOpenOrderStockRemove,
updateOpenOrderStockAdd,

updateOpenOrderWastageAdd,
updateOpenOrderWastageRemove,


updatePurchaseRequestForIs,

updateStockPiece,
updateStockReservedPiece,
updateStockReservedPieceRemove,
updateStockWaitingPiece,
updateStockWaitingPieceRemove,
updateWastageReservedPiece,
updateWastageReservedPieceRemove,
deleteWastage,
updateWastage,

getJobCodesData,
deleteJobCodeData,
getCurrentJobCodeData,
deleteCurrentJobCodeData,


getCheckCurrentPriceData,
updateCheckCurrentPrice,


getFutureRawData,
getAddRequestsData,
deleteAddRequest,

getDensitiesData,
deleteDensity,
makeDensityVisible,
updateDensity,

getReturnRawData,

getExitRawData,
getFaultData,
getProductionData,
getSetupData,
deleteSetupData,
deletePartData,
updateSetupTable,
updateSetupTablePriorityAbkant,
updateOpenOrderPriorityTable,

getOpenOrderOfferData,
deleteOpenOrderOfferData,
invoiceOpenOrderIsVisible,


getMachinesData,
deleteMachine,
makeMachineVisible,

getPartsData,

updateFixedFault,
updateUserPerformance,

getScrapsData,

getInvoicePurchaseData,
updateInvoicePrice,
getInvoiceCategoryData,
getInvoiceCategoryContentsData,
getInvoiceOpenOrderData,
getExpensesData,

updateOperations,
getNewData,
deleteNew,
makeNewVisible,
updateNew,

getAnnouncementData,
deleteAnnouncement,
makeAnnouncementVisible,
updateAnnouncement,
getUserData,
getNotificationData,
 deleteNotification,
 deleteUser,
 makeNotificationVisible,
 getNotificationDataByUserId,
 makeUserVisible,

 getCurrencyData,

 updateCompanyNameInAllTables,
 updateSurfaceNameInAllTables,
 updateMailInAllTables,
 updateDensityInAllTables,
 
 addRecording,
 updateAuthorizationPages,
 updateUserType,
 updateUserImageUrl,
 getUserByUid,
 getFilteredUserData,
 createUserGoogle,
 registerUser,
 registerUserWithGoogle,

};

export default FirebaseService;