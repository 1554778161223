import React,{useEffect,useState} from "react";
import { Button, Form, Input,Select,Spin} from 'antd';
import NotificationService from "../../services/antNotificationService";
import FirebaseService from "../../services/firebaseService";
import  { parsCollections, qualityGroup}  from "../../environments/environment"
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

 
const CompanyAddAdmin = ({ record,onClose }) => {
    const navigate = useNavigate();
    const [paymentMethods, setPaymentMethods] = useState([]); 
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
          const paymentMethods = await FirebaseService.getPaymentMethodsData();
          const filteredpaymentMethods = paymentMethods.filter(item => !item.isDeleted);
          setPaymentMethods(filteredpaymentMethods);
        };
        fetchData();
      }, []);
   

    const onFinish = async (values) => {
      setLoading(true)
        const allCompanies = await FirebaseService.getCompaniesData();
        const newItems = allCompanies.filter((entry) => entry.companyName.toLowerCase() === record.companyName.toLowerCase());

         if(newItems.length > 0){
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "Böyle bir firma bulunmaktadır.",
            placement: 'topRight'
          });
          setLoading(false)
          return;
          
         }

         if (values.vkn.length !== 10) {
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "Vergi numarası 10 hane olmalıdır.",
            placement: 'topRight'
          });
          setLoading(false)
          return;
        }
     
        const { tableName, description, key, firstTransactionDateEdit, firstUserName, firstUserSurname,firstTransactionDate, ...newItem } = record;
        const newItemWithAdditionalProperties = {
            ...newItem,
            firstTransactionDate:new Date(),
            firstUserId: localStorage.getItem("uid"),
            isDeleted: false,
            companyName: record.companyName,
            vkn : values.vkn,
            taxAdministration: values.taxAdministration,
            paymentMethod : paymentMethod,
            address: values.address,
            email :values.email

        };

    try {
      const result = await FirebaseService.addRecording(record.tableName,newItemWithAdditionalProperties);
      if (result.success) {

        const deleteItem = await FirebaseService.deleteAddRequest(record.key);
        if (deleteItem.success) {
          NotificationService.openSuccessNotification({
            title: 'İşlem Başarılı',
            description: result.message,
            placement: 'topRight'
          });
        //  const data = await FirebaseService.getAddRequestsData();
        onClose();
        setLoading(false)
          setTimeout(() => {
            window.location.reload();
           // navigate("/addrequestslist");
        }, 2000);
       
            
            
        } else {
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
            placement: 'topRight'
          });
          setLoading(false)
        }
      } 
      else {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
          placement: 'topRight'
        });
        setLoading(false)
      }
      
      
    } catch (error) {
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "Bilinmeyen bir hata ile karşılaşıldı.",
        placement: 'topRight'
      });
      setLoading(false)
    }
    

        
     
    }; 


    const onFinishFailed = (errorInfo) => {
        NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen zorunlu alanları doldurunuz',placement:'topRight'})
    };

    const onChange = (value) => {
        setPaymentMethod(value)
  
      };
    
   return ( <div>
    
         <Form
            name="basic"
            labelCol={{
            span: 8,
            }}
            wrapperCol={{
            span: 12,
            }}
            style={{
            maxWidth: "100%",
            padding:"5% 0 0 5%"
            }}
            initialValues={{
            remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        > 
           
           <Form.Item
             label="Vkn"
            name="vkn"
            rules={[
                {
                required: true,
                message: 'Lütfen vkn giriniz!',
                },
            ]}
         
            >
          <Input className="input-style" style={{width:"100%"}}  placeholder="Vergi Numarası"/>
            </Form.Item>


            <Form.Item
             label="Vergi Dairesi"
            name="taxAdministration"
            rules={[
                {
                required: true,
                message: 'Lütfen vergi dairesi giriniz!',
                },
            ]}
           
            >
          <Input className="input-style" style={{width:"100%"}}  placeholder="Vergi Dairesi"/>
            </Form.Item>


            <Form.Item
             label="Mail"
            name="email"
            rules={[
                {
                required: true,
                message: 'Lütfen mail giriniz!',
                },
            ]}
           
            >
          <Input className="input-style" style={{width:"100%"}}  placeholder="Mail adresi"/>
            </Form.Item>

            
         
            <Form.Item
             label="Adres"
            name="address"
            rules={[
                {
                required: true,
                message: 'Lütfen adres giriniz!',
                },
            ]}
          
            >
          <Input className="input-style" style={{width:"100%"}}  placeholder="Adres"/>
            </Form.Item>

            
         
            <Form.Item
             label="Ödeme Vaadesi"
            name="paymentMethod"
            rules={[
                {
                required: true,
                message: 'Lütfen ödeme vaadesi giriniz!',
                },
            ]}
           
            >
            <Select
            showSearch
                onChange={onChange}
                placeholder="Ödeme Vaadesi Seçiniz"
                className="input-style" 
              
                suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
             /*   options={[
                  ...paymentMethods.map(item => ({ label: item.paymentMethod, value: item.paymentMethod })),
                  { label: 'Yeni Ödeme vaadesi Ekle', value: 'newPaymentMethod' },
                ]}*/
                options={paymentMethods.map(item => ({ label: item.paymentMethod, value: item.paymentMethod }))}
                filterOption={(input, option) => 
                  option.label.toLowerCase().includes(input.toLowerCase()) // Arama için filtreleme
                }
              />            
              </Form.Item>   

       <Form.Item
            wrapperCol={{
                offset: 15,
                span: 18,
            }}
            >
            <Button htmlType="submit" loading={loading} style={{backgroundColor:"#1A446C",color:"white",fontWeight:"500"}}>Gönder</Button>
            </Form.Item>

        </Form>
        {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}

    </div>)

};

CompanyAddAdmin.propTypes = {
    onClose: PropTypes.func.isRequired, 
  };
  
export default CompanyAddAdmin;