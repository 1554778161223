import React,{useEffect,useState} from "react";
import { Table, Button,Space,Input, Modal, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import  { colors, parsCollections}  from "../../../../environments/environment"
import { useLocation  } from 'react-router-dom';
import FirebaseService from "../../../../services/firebaseService";
import NotificationService from "../../../../services/antNotificationService";
import NoOfferApprovedDescriptionDetail from "./noOfferApprovedDescriptionDetail";
import OneSignalService from "../../../../services/oneSignalService";

const WaitingOfferOpenOrderList = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const record = location.state && location.state.record;
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.removeItem("qualityWeights");
    localStorage.removeItem("paymentMethod");
    localStorage.removeItem("machineTimes");

    fetchData();
  }, []);

  function isEqual(obj1, obj2) {
    // Tüm özelliklerin eşit olup olmadığını karşılaştır
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }
  function addTimes(time1, time2) {
    const [h1, m1, s1] = time1.split(':').map(Number);
    const [h2, m2, s2] = time2.split(':').map(Number);
  
    let seconds = s1 + s2;
    let minutes = m1 + m2;
    let hours = h1 + h2;
  
    if (seconds >= 60) {
      seconds -= 60;
      minutes++;
    }
    if (minutes >= 60) {
      minutes -= 60;
      hours++;
    }
  
    return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
  }
  
  function pad(num) {
    return num.toString().padStart(2, '0');
  }
  
  const fetchData = async () => {
    const data = await FirebaseService.getOpenOrderData();
    const filteredData = data.filter(item => !item.isDeleted && item.isWaiting && item.orderType === "offer" && !item.isOfferApproved && item.setupName != null && !item.isOffer && item.isOfferable);
    const setupData = await FirebaseService.getSetupData();

    const updatedData = [];

    filteredData.forEach(item => {
      const setupItems = setupData.filter(setup => setup.jobCode === item.jobCode);
    
      if (setupItems.length > 0) {
        const addedJobCodes = new Set();
    
        setupItems.forEach(setupItem => {
          // Aynı jobCode ve firstTransactionDate zaten eklenmişse ekleme
          const isDuplicate = updatedData.some(updatedItem => (
            updatedItem.jobCode === setupItem.jobCode &&
            isEqual(updatedItem.firstTransactionDate, setupItem.firstTransactionDate) // isEqual ile karşılaştırma
          ));
    
          if (!isDuplicate) {
            const updatedItem = { ...item, ...setupItem };
            updatedData.push(updatedItem);
            addedJobCodes.add(setupItem.jobCode);
          }
        });
      } 
      else if(setupItems.length === 0){
        console.log("")
      }
       else {
        updatedData.push(item);
      }
      
      
    });
    

    
    
      const groupedByJobCodePrefix = updatedData.reduce((acc, item) => {
      const prefix = item.jobCode.split('-')[0];
      if (!acc[prefix]) {
        acc[prefix] = []; 
      }
      acc[prefix].push(item);
      return acc;
    }, {});
  
    const uniqueLimitedData = Object.entries(groupedByJobCodePrefix).map(([key, value]) => {
    const sampleItem = value[0]; 

 const mergedItem = value.reduce((acc, item) => {
  return {
    ...acc,
    programmerNames: [...acc.programmerNames, item.programmerName],
    totalKgs: acc.totalKgs + item.totalKg,
    setupTimes: {
      ...acc.setupTimes,
      [`${item.machine}-${item.fuel}`]: acc.setupTimes[`${item.machine}-${item.fuel}`]
        ? addTimes(acc.setupTimes[`${item.machine}-${item.fuel}`], item.setupTime)
        : item.setupTime,
    },
    totalKg: {
      ...acc.totalKg,
      [item.quality]: acc.totalKg[item.quality] ? acc.totalKg[item.quality] + item.totalKg : item.totalKg,
    },
    deadlines: [...acc.deadlines, item.deadline],
    parts: [...acc.parts, ...item.parts],
  };
}, { programmerNames: [], totalKgs: 0, setupTimes: {}, totalKg: {}, deadlines: [], parts: [] });


      return {
        firstTransactionDate: sampleItem.firstTransactionDate,
        firstUserId: sampleItem.firstUserId,
        programmerUserId:sampleItem.programmerUserId,
        noOfferApprovedDescription:sampleItem.noOfferApprovedDescription,
        jobCode: key,    
        isDeleted: sampleItem.isDeleted,
        companyName: sampleItem.companyName,
        projectCode: sampleItem.projectCode,
        isOffer: sampleItem.isOffer,
        isOfferApproved: sampleItem.isOfferApproved,
        isOfferSetupUpdate: sampleItem.isOfferSetupUpdate,
        isOfferChange: sampleItem.isOfferChange,
        isOfferDenied: sampleItem.isOfferDenied,
        orderType: sampleItem.orderType,
        isWaiting: sampleItem.isWaiting,
        isSetup: sampleItem.isSetup,
        isChangedProgrammer: sampleItem.isChangedProgrammer,
        programmerName:mergedItem.programmerNames,
        totalKgs: mergedItem.totalKgs,
        setupTime: mergedItem.setupTimes,
        totalKg: mergedItem.totalKg,
        deadline: mergedItem.deadlines, 
        parts: mergedItem.parts, 

        items: value.map(item => ({
          ...item,
        })),
      };
    });


  if (uniqueLimitedData) {
    let updatedItems = uniqueLimitedData.map(item => {
      let newStatus = "Unknown";
      if (item.isWaiting && !item.isSetup && item.orderType === "order") {
        newStatus = "Setup Bekleniyor";
      } else if (item.isWaiting && !item.isSetup && item.orderType === "offer") {
        newStatus = "Setup Bekleniyor";
      } else if (item.isWaiting && item.isSetup && item.orderType === "offer" && !item.isOffer) {
        newStatus = "Teklif Bekleniyor";
      } else if (item.isWaiting && item.isSetup && item.orderType === "offer" && item.isOffer && !item.isOfferApproved && !item.isOfferChange && !item.isOfferSetupUpdate && !item.isOfferDenied) {
        newStatus = "Teklif Verildi";
      } else if (item.isWaiting && item.isSetup && item.orderType === "offer" && item.isOffer && item.isOfferApproved) {
        newStatus = "Onaylandı";
      } else if (item.isWaiting && item.isSetup && item.orderType === "offer" && item.isOffer && !item.isOfferApproved && item.isOfferChange && !item.isOfferSetupUpdate && !item.isOfferDenied) {
        newStatus = "Onaylanmadı,Teklif Değişikliği";
      } else if (item.isWaiting && item.isSetup && item.orderType === "offer" && item.isOffer && !item.isOfferApproved && !item.isOfferChange && item.isOfferSetupUpdate && !item.isOfferDenied) {
        newStatus = "Onaylanmadı,Setup Düzenleme";
      } else if (item.isWaiting && item.isSetup && item.orderType === "offer" && item.isOffer && !item.isOfferApproved && !item.isOfferChange && !item.isOfferSetupUpdate && item.isOfferDenied) {
        newStatus = "Onaylanmadı,Reddedildi";
      }
      
      return { ...item, status: newStatus };
    });
    updatedItems.sort((a, b) => {
      // "Onaylanmadı, Reddedildi" durumu en altta göstermek için özel sıralama
      if (a.status === "Onaylanmadı,Reddedildi" && b.status !== "Onaylanmadı,Reddedildi") {
        return 1; // a'yı sonra göster
      }
      if (a.status !== "Onaylanmadı,Reddedildi" && b.status === "Onaylanmadı,Reddedildi") {
        return -1; // a'yı önce göster
      }
  
      // Durumlar aynıysa tarihe göre ters sırala
      const dateA = new Date(a.firstTransactionDate);
      const dateB = new Date(b.firstTransactionDate);
      return dateB - dateA; // En son tarih en üstte olacak şekilde sırala
    });
    
    setItems(updatedItems);
  }
  
  };

  const goOfferPage = (record) => {
    navigate('/offerdetail', { state: { record } })
  };

  const goOfferApproved = async (record) => {
    const allOpenOrder = await FirebaseService.getOpenOrderData();
    const filteredOpenOrder = allOpenOrder.filter(item => !item.isDeleted && item.isWaiting && item.isOffer && !item.isOfferApproved && item.orderType === "offer" && item.jobCode.includes(record.jobCode));

   for (const item of filteredOpenOrder) {
    try {
      const result = await FirebaseService.deleteOpenOrderData(item.key);
      if (result) {
        console.log(`Item with key ${item.key} deleted successfully.`);
      } else {
        console.log(`Failed to delete item with key ${item.key}.`);
      }
    } catch (error) {
      console.error(`Error deleting item with key ${item.key}:`, error);
    }
  }


  const setupData = await FirebaseService.getSetupData();
  const filteredSetup = setupData.filter(item => !item.isDeleted && item.jobCode.includes(record.jobCode));
  
  for (const item of filteredSetup) {
    try {
      const result = await FirebaseService.deleteSetupData(item.key);
      if (result) {
        console.log(`Item with key ${item.key} deleted successfully.`);
      } else {
        console.log(`Failed to delete item with key ${item.key}.`);
      }
    } catch (error) {
      console.error(`Error deleting item with key ${item.key}:`, error);
    }
  }
  

  const jobCodes = await FirebaseService.getJobCodesData();
  const filteredJobCodes = jobCodes.filter(item => !item.isDeleted  && item.item.includes(record.jobCode));
  
  for (const item of filteredJobCodes) {
    try {
      const result = await FirebaseService.deleteJobCodeData(item.key);
      if (result) {
        console.log(`Item with key ${item.key} deleted successfully.`);
      } else {
        console.log(`Failed to delete item with key ${item.key}.`);
      }
    } catch (error) {
      console.error(`Error deleting item with key ${item.key}:`, error);
    }
  }

  const data = {
          firstTransactionDate: record.firstTransactionDate,
          firstUserId: record.firstUserId,
          programmerUserId:record.firstUserId,
          isDeleted: false,
          programmerName : Array.isArray(record.programmerName) && record.programmerName.length > 0 ? record.programmerName[0]: null ,
          jobCode : record.jobCode, 
          companyName: record.companyName,
          projectCode: record.projectCode ? record.projectCode : null,
          orderType : "order",
          stock: null,
          wastage: null,
          deadline:null, //setup sonunda seçiliyor
          isSetup:false,
          isOffer:true,
          isWaiting: true,
          isOfferApproved:true,
          noOfferApprovedDescription:record.noOfferApprovedDescription ? record.noOfferApprovedDescription : null, 
          noQrScanDescription:null,
          isOfferSetupUpdate:record.isOfferSetupUpdate,
          isOfferChange:record.isOfferChange,
          isOfferDenied:record.isOfferDenied,
          // priority:999999,
          isChangedProgrammer:false ,
          isShipment: false,
          shipmentPiece: 1,
          invoiceNumber:null,
          isOfferable:false

    };

    try {
        const result = await FirebaseService.addRecording(parsCollections.openOrder,data);
        if (result.success) {
          /*
          const item = {
            isDeleted:false,
            item: record.jobCode
          }
         const result = await FirebaseService.addRecording(parsCollections.jobCodes,item);
        */
          NotificationService.openSuccessNotification({
            title: 'İşlem Başarılı',
            description: "Başarıyla kaydedilmitşir.",
            placement: 'topRight'
          });

          const userData = await FirebaseService.getUserByUid(record.firstUserId);
          const oneSignalResult = await OneSignalService.sendNotification("", "Teklif Onaylandı",[userData.oneSignalPlayerId])
          const currentDate =new Date()
          
          const sentUserIds = {
            [userData.firstUserId]: false
          };
        
          const data = {
            firstTransactionDate: currentDate,
            firstUserId: localStorage.getItem("uid"),
            isDeleted: false,
            sentUserIds:sentUserIds, 
            firstUserNameSurname: localStorage.getItem('name') + " " +localStorage.getItem('surname'),
            unitName:localStorage.getItem('userUnit'),
            title:"Teklif Onaylandı",
            companyName:record.companyName,
            jobCode:record.jobCode, 
            projectCode:record.projectCode, 
            machine:null,
            type:null,
            notice:"Bekleyen siparişlerinizi kontrol ediniz.",
            description: null

          };
          const result = await FirebaseService.addRecording(parsCollections.notifications,data);
          setTimeout(() => {
            navigate("/salesmanpage");
          }, 2000);
       
        } else {
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
            placement: 'topRight'
          });}
        
        
    } 
    catch (error) {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Bilinmeyen bir hata ile karşılaşıldı.",
          placement: 'topRight'
        });
    }
  };

  const goNoOfferApproved = (record) => {
    handleRequestRemoval(record)

  };
  const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const goDelete = async (record) => {
    
    let success = true; 
    setLoading(true);

    // Tüm teklifleri getirin
    const allOpenOffers = await FirebaseService.getOpenOrderData();

    // Sadece bekleyen teklifleri filtreleyin (orderType: "offer" ve isWaiting: true)
    const filteredOpenOffers = allOpenOffers.filter(item => 
        !item.isDeleted && 
        item.isWaiting && 
        item.orderType === "offer" && 
        item.jobCode.includes(record.jobCode)
    );
    
    const offersWithAllItemsQrScanFalse = filteredOpenOffers.filter(offer => {
        const stockItems = offer.stock || [];
        const wastageItems = offer.wastage || [];
    
        const allStockItemsQrScanFalse = stockItems.every(item => item.isQrScan === false);
        const allWastageItemsQrScanFalse = wastageItems.every(item => item.isQrScan === false);
    
        return allStockItemsQrScanFalse && allWastageItemsQrScanFalse;
    });
    
    if (offersWithAllItemsQrScanFalse.length === 0) {
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "Bu teklif silinemez.",
        placement: 'topRight'
      });
      setLoading(false);
      return;
    } else {
      let combinedStockItems = [];

      offersWithAllItemsQrScanFalse.forEach(offer => {
        if (offer.stock) {
            offer.stock.forEach(stockItem => {
                const existingItemIndex = combinedStockItems.findIndex(item => item.id === stockItem.id);
    
                if (existingItemIndex !== -1) {
                    combinedStockItems[existingItemIndex].piece += stockItem.piece;
                } else {
                    combinedStockItems.push({ ...stockItem });
                }
            });
        }
      });
    
      
      // Stokları güncelleme işlemi
      async function updateCombinedStockItems(stockItems) {
        for (const item of stockItems) {
            const { id, piece, isSendPurchase } = item;
            if (isSendPurchase) {
              const resultPurchase = await FirebaseService.updateStockWaitingPieceRemove(id, piece);
              console.log("Result for item", id, ":", resultPurchase);
            } else {
              console.log("Rezerve değişiyor");
              const result = await FirebaseService.updateStockReservedPieceRemove(id, piece);
              console.log("Result for item", id, ":", result);
            }
        }
      }
      updateCombinedStockItems(combinedStockItems);

      // Tekliflere ait wastage (atık) verileriyle ilgili işlem
      let combinedWastageItems = [];
      offersWithAllItemsQrScanFalse.forEach(offer => {
        if (offer.wastage) {
          offer.wastage.forEach(wastageItem => {
              const existingItemIndex = combinedWastageItems.findIndex(item => item.id === wastageItem.id);
  
              if (existingItemIndex !== -1) {
                combinedWastageItems[existingItemIndex].piece += wastageItem.piece;
              } else {
                combinedWastageItems.push({ ...wastageItem });
              }
          });
        }
      });

      async function updateCombinedWastageItems(wastageItems) {
        for (const item of wastageItems) {
            const { id, piece } = item;
            const result = await FirebaseService.updateWastageReservedPieceRemove(id, piece);
            console.log("Result for item", id, ":", result);
        }
      }
      updateCombinedWastageItems(combinedWastageItems);

      // Teklif silme işlemi
      for (const offer of filteredOpenOffers) {
        try {
          const result = await FirebaseService.deleteOpenOrderDataIsDeleted(offer.key);
          if (result) {
            console.log(`Offer with key ${offer.key} deleted successfully.`);
          } else {
            success = false;
            console.log(`Failed to delete offer with key ${offer.key}.`);
          }
        } catch (error) {
          success = false;
          console.error(`Error deleting offer with key ${offer.key}:`, error);
        }
      }
  
      if (success) {
        NotificationService.openSuccessNotification({
            title: 'İşlem Başarılı',
            description: 'Bekleyen teklif başarıyla silindi.',
            placement: 'topRight'
        });
        setLoading(false);
        setTimeout(() => {
          navigate("/salesmanpage");
        }, 2000);
      } else {
        NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: 'Bekleyen teklif silinemedi. Lütfen daha sonra tekrar deneyiniz',
            placement: 'topRight'
        }); 
        setLoading(false);
      }
    }
}

const handleViewOfferDetails = (record) => {
navigate('/offerdetailview', { state: { selectedItem: record } })
};


  
  const calculateTotalTime = (timeStrings) => {
    const totalSeconds = timeStrings.reduce((acc, time) => {
      const [hours, minutes, seconds] = time.split(':').map(Number);
      return acc + (hours * 3600) + (minutes * 60) + seconds;
    }, 0);
    return formatTime(totalSeconds);
  };

  const columns = [
    {
      title: 'Müşteri Firma',
      dataIndex: 'companyName',
      key: 'companyName',
     
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
  },
    {
      title: 'İş Kodu',
      dataIndex: 'jobCode',
      key: 'jobCode',
    
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text}
      </span>,
    },
    {
        title: 'Proje Kodu',
        dataIndex: 'projectCode',
        key: 'projectCode',
    
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text ? text : "-"}
        </span>,
      },
      /*
      {
        title: 'Programcı',
        dataIndex: 'programmerName',
        key: 'programmerName',
       
        render: (text, record) => (
          <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
            {text.map(item => (
              <>
              <div key={item}>-{item}</div>
             
              </>
            ))}
          </span>
          
        )
              },
              */
             
    {
      title: 'Programcı',
      dataIndex: 'programmerName',
       key: 'programmerName',
        render: (text, record) => (
         <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
          <div key={text[0]}>-{text[0]}</div>
          </span>
       )
     },
              
    {
        title: 'Durum',
        dataIndex: 'status',
        key: 'status',
        render: (text, record) => {
          let statusColor = "#000000";  
          return <span style={{ color: record.isDeleted ? "#00000080" : statusColor }}>{text}</span>;
        },
      
      },
      {
        title: 'Toplam Kilo',
        dataIndex: 'totalKg',
        key: 'totalKg',
        render: (text, record) => (
          <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
            {record && record.totalKgs.toFixed(2)} Kg
          </span>
        )
        
      },
     
      {
        title: 'Toplam Süre',
        dataIndex: 'setupTime',
        key: 'setupTime',
        render: (text, record) => {
          const totalSetupTime = Object.values(record.setupTime).reduce((total, timeString) => addTimes(total, timeString), '00:00:00');
      
          return (
            <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
            {totalSetupTime}
            </span>
          );
        }
      }
,      
      
   /*
      {
        title: 'Termin Tarihi',
        dataIndex: 'deadline',
        key: 'deadline',
        render: (text, record) => {
          // record.deadline var mı ve bir dizi mi kontrol et
          const formattedDeadlines = Array.isArray(record.deadline) ? 
            record.deadline
              .filter(d => d !== null) // null değerleri filtrele
              .map(d => new Date(d.seconds * 1000).toLocaleDateString('tr-TR')) : 
            [];
      
          return (
            <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
              {formattedDeadlines.length > 0 ? (
                formattedDeadlines.map((date, index) => (
                  <div key={index}>-{date}</div>
                ))
              ) : (
                <div>-</div>
              )}
           
            </span>
          );
        }
      }*/
      
      
      {
        title: 'Termin Tarihi',
        dataIndex: 'deadline',
        key: 'deadline',
        render: (text, record) => {
          // record.deadline var mı ve bir dizi mi kontrol et
          const formattedDeadlines = Array.isArray(record.deadline) ? 
            record.deadline
              .filter(d => d !== null) // null değerleri filtrele
              .map(d => new Date(d.seconds * 1000).toLocaleDateString('tr-TR')) : 
            [];
        
          return (
            <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
              {formattedDeadlines.length > 0 ? (
                <div>{formattedDeadlines[0]}</div>
              ) : (
                <div>-</div>
              )}
            </span>
          );
        }
      }
,      
           
{
  title: '   ',
  dataIndex: 'edit',
  key: 'edit',
  render: (text, record) => (
      record.isOffer ? (
          <>
           {(!record.isOfferApproved && !record.isOfferSetupUpdate && !record.isOfferChange && !record.isOfferDenied) ? 
           <>
            <Button 
              onClick={() => goOfferApproved(record)} 
              style={{backgroundColor: colors.blue, color: "white", fontWeight: "500", }}
              >
              Onaylandı
              </Button> 
              <Button 
              onClick={() => goNoOfferApproved(record)} 
              style={{backgroundColor: colors.blue, color: "white", fontWeight: "500"}}
              >
              Onaylanmadı
              </Button> 
              
              <Button 
              onClick={() => handleViewOfferDetails(record)} 
              style={{backgroundColor: colors.blue, color: "white", fontWeight: "500"}}
              >
              Teklifi Gör
              </Button>
              </> 
              
          : 
          <>
           {record.isOfferChange && (
        <Button 
        onClick={() => goOfferPage(record)} 
        style={{backgroundColor: colors.blue, color: "white", fontWeight: "500"}}
        >
          Teklif Ver
        </Button>
      )}
          </>
          }
             
          </>

      ) : (
          <Button 
              onClick={() => goOfferPage(record)} 
              style={{backgroundColor: colors.blue, color: "white", fontWeight: "500"}}
          >
              Teklif Ver
          </Button>
      )
  ),
},

{
  title: ' ',
  dataIndex: 'delete',
  key: 'delete',
  render: (text, record) => (
    <img
    src="/images/delete.svg"
      alt="Sil"
      onClick={() => goDelete(record)}
      style={{ cursor: 'pointer', width: '20px', height: '20px' }} 
    />
  ),
}

  ];

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [recordForModal, setRecordForModal] = useState(null);

  const handleRequestRemoval = (record) => {
    setRecordForModal(record);
    setIsModalVisible(true);
};

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handleRequestRemovalFinish = () => {
    setIsModalVisible(false); 
  };

  return  <div style={{
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '94vh',
        padding:"3%",
       
      }}>
        <h3 style={{textAlign:"left", color:colors.blue}}>Bekleyen Teklifler</h3>
        <Input
        style={{
          borderRadius: 0,
          border: 'none',
          borderBottom: '1px solid #1A446C80',
          width: '100%',
          maxWidth: '400px',
          textAlign: 'left',
          outline: 'none',
        }}
        placeholder="İş Kodu Ara"
        suffix={
          <Space>
              <img width={15} alt="edit" src="/images/searchicon.png" />
          </Space>
        }
        onChange={(e) => {
          const searchValue = e.target.value.toLowerCase();
          const filteredData = items.filter(item => {
            const jobCode = item.jobCode.toLowerCase() ;
            return jobCode.includes(searchValue);
          });
          setItems(filteredData);
        }}
      />
<Table
  rowKey={(record) => record.jobCode }
  locale={{
    emptyText: 'Henüz bekleyen teklif bulunmamaktadır...',
    filterReset: 'Sıfırla',
    filterTitle: 'Filtre Menüsü',
    selectAll: 'Hepsini Seç',
    selectInvert: 'Tersini Seç',
    selectionAll: 'Tümünü Seç',
    sortTitle: 'Sıralama',
    triggerDesc: 'Azalan sıralama için tıklayın',
    triggerAsc: 'Artan sıralama için tıklayın',
    cancelSort: 'Sıralamayı iptal etmek için tıklayın',
  }}
  dataSource={items}
  columns={columns}
  showHeader={true}
  pagination={false}
  className="custom-news-table"
  rowClassName="custom-news-row"
  expandable={{
    expandedRowRender: x => (
      <div style={{ display: 'flex' }}>
        {/* Sol Parça: Birim Kalınlıklar */}
        <div style={{  }}>
          <div style={{ marginBottom: "10px" }}>
            <span style={{ fontWeight: "bold", color: record.isDeleted ? "#00000080" : "#000000" }}>Birim Kalınlıklar:</span>
            <span style={{ color: record.isDeleted ? "#00000080" : "#000000", marginLeft: "5px", display: "block" }}>
              {x.items.map((item, index) => (
                <div key={index} style={{ marginRight: "10px" }}>
                  <span>{item.thickness} mm</span>
                </div>
              ))}
            </span>
          </div>
        </div>
  
    
        {/* Sol Parça */}
        <div style={{marginLeft: "55px"   }}>
          <div style={{ marginBottom: "10px" }}>
            <span style={{ fontWeight: "bold", color: record.isDeleted ? "#00000080" : "#000000" }}>Birim Kilolar:</span>
            <span style={{ color: record.isDeleted ? "#00000080" : "#000000", marginLeft: "5px", display: "block" }}>
              {x.items.map((item, index) => (
                <div key={index} style={{ marginRight: "10px" }}>
                  <span>{parseFloat(item.totalKg).toFixed(2)} Kg</span>
                </div>
              ))}
            </span>
          </div>
        </div>

        {/* Orta Parça */}
        <div style={{  marginLeft: "55px" }}>
          <div style={{ marginBottom: "10px" }}>
            <span style={{ fontWeight: "bold", color: record.isDeleted ? "#00000080" : "#000000" }}>Birim Süreler:</span>
            <span style={{ color: record.isDeleted ? "#00000080" : "#000000", marginLeft: "5px", display: "block" }}>
              {x.items.map((item, index) => (
                <div key={index} style={{ marginRight: "10px" }}>
                  <span>{item.setupTime} </span>
                </div>
              ))}
            </span>
          </div>
        </div>
      </div>
    ),
    expandIcon: ({ expanded, onExpand, record }) => {
      return expanded ? (
        <a type="link" onClick={e => onExpand(record, e)} style={{ border: "none", backgroundColor: "transparent" }}>
          <img style={{ width: "20px" }} alt="edit" src="/images/uparrow.png" />
        </a>
      ) : (
        <a type="link" onClick={e => onExpand(record, e)} style={{ border: "none", backgroundColor: "transparent" }}>
          <img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />
        </a>
      );
    },
    expandIconColumnIndex: 8
  }}
  
/>


          <Modal
            title="Onaylanmayan Teklif"
            visible={isModalVisible}
            onCancel={handleModalCancel}
            footer={null}
            className="no-offer-approved-detail-popup"
          >
            <NoOfferApprovedDescriptionDetail  record={recordForModal}  onClose={handleRequestRemovalFinish} />
          </Modal>

          {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}
    </div>

  };
  export default WaitingOfferOpenOrderList;
 