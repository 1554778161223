import React,{useEffect,useState} from "react";
import { Table, Button, Space, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../services/firebaseService";
import NotificationService from "../../services/antNotificationService";
import { SearchOutlined } from "@ant-design/icons";
const NewList = () => {
  const [news, setNews] = useState([]);
  const navigate = useNavigate();
  const [filteredNews , setFilteredNews] = useState([])
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      const data = await FirebaseService.getNewData();
      data.sort((a, b) => new Date(b.firstTransactionDate) - new Date(a.firstTransactionDate));
      setNews(data)
      setFilteredNews(data)
    };
    fetchData();
  }, []);
  const goAddPage = () => {
    navigate('/newadd')
  };
  const goEditPage = (record) => {
    navigate('/newedit', { state: { record } });
  };
const handleDelete = async (record, isDeleted) => {
  try {
    let result;
    if (isDeleted) {
      result = await FirebaseService.makeNewVisible(record.key);
    } else {
      result = await FirebaseService.deleteNew(record.key);
    }
    if (result.success) {
      NotificationService.openSuccessNotification({
        title: 'İşlem Başarılı',
        description: result.message,
        placement: 'topRight'
      });
      const data = await FirebaseService.getNewData();
      setNews(data);
    } else {
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
        placement: 'topRight'
      });
    }
  } catch (error) {
    NotificationService.openErrorNotification({
      title: 'İşlem Başarısız',
      description: "Bilinmeyen bir hata ile karşılaşıldı.",
      placement: 'topRight'
    });
  }
};
const handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  const value = selectedKeys[0]?.toLowerCase();
  const filteredData = news.filter((item) => {
    const itemValue = item[dataIndex]?.toString().toLowerCase();
    return !item.isDeleted && (value ? itemValue.includes(value) : true);
  });
  setFilteredNews(filteredData);
};
const handleReset = (clearFilters) => {
  clearFilters();
  setFilteredNews(news);
};
const getColumnSearchProps = (dataIndex) => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        id="search-input"
        placeholder={`Ara`}
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: "block" }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Ara
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Sıfırla
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890FF" : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: (visible) => {
    if (visible) {
      setTimeout(() => document.getElementById("search-input").select(), 100);
    }
  },
  sorter: (a, b) => {
    const valueA = a[dataIndex];
    const valueB = b[dataIndex];
    if (valueA == null && valueB == null) return 0;
    if (valueA == null) return 1;
    if (valueB == null) return -1;
    const numA = parseFloat(valueA);
    const numB = parseFloat(valueB);
    if (!isNaN(numA) && !isNaN(numB)) {
      return numA - numB;
    }
    if (!isNaN(numA)) return -1;
    if (!isNaN(numB)) return 1;
    return String(valueA).localeCompare(String(valueB));
  },
});
const columns = [
  {
    title: 'Resim',
    dataIndex: 'imageUrl',
    key: 'imageUrl',
    render: (img,record) => <img src={img} width={50} style={{opacity: record.isDeleted ? "60%" : null}}/>,
    width: 150,
  },
  {
    title: 'Başlık',
    dataIndex: 'title',
    key: 'title',
    render: (text,record) => <span style={{fontWeight:"bold",color: record.isDeleted ? "#00000080" : "#000000"}}>{text}</span>,
    width: 400,
    ...getColumnSearchProps('title')
  },
  {
    title: ' ',
    dataIndex: 'delete',
    key: 'delete',
    render: (text, record) => (
        record.isDeleted ?
         <Button style={{backgroundColor:"#1A446C",color:"white",borderRadius:0}} onClick={() => handleDelete(record,true)}>Göster</Button>
        :
        <Button style={{backgroundColor:"#1A446C",color:"white",borderRadius:0}} onClick={() => handleDelete(record,false)}>Sil</Button>
    ),
  },
  {
    title: 'Güncelle',
    dataIndex: 'edit',
    key: 'edit',
    render: (text, record) => (
      <a type="link" onClick={()=>goEditPage(record)}
      style={{
        border: "none",
        backgroundColor: "transparent",
        pointerEvents: record.isDeleted ? "none" : "auto",
        opacity: record.isDeleted ? 0.5 : 1,
      }}><img style={{width:"20px"}} alt="edit" src="/images/newEditButton.png" /></a>
    ),
  }
];
   return <div style={{
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '94vh',
        padding:"3%"
      }}>
        <Button onClick={()=>goAddPage()} icon={<img alt="plus" src="/images/plusbutton.png" style={{width:"60%"}}/>} className='admin-add-button'>
          Yeni Ekle
        </Button>
          <Table
            locale={{ emptyText: 'Henüz haber eklemediniz...' }}
            dataSource={filteredNews}
            columns={columns}
            showHeader={true}
            pagination={false}
            className="custom-news-table"
            rowClassName="custom-news-row"
        />
    </div>
    };
  export default NewList;