import React,{useEffect,useState} from "react";
import { Button, Form, Input,Table,Modal, Space} from 'antd';
import NotificationService from "../../services/antNotificationService";

import FirebaseService from "../../services/firebaseService";
import  { parsCollections,colors}  from "../../environments/environment"
import { useNavigate } from 'react-router-dom';
import DensityAddAdmin from "../Densities/densityAddAdmin";
import AllItemsAddAdmin from "./allItemsAddAdmin";
import CompanyAddAdmin from "./companyAddAdmin";
import { SearchOutlined } from "@ant-design/icons";


const AddRequestsList = () => {
    const [items, setItems] = useState([]);
    const navigate = useNavigate();
    const [filteredItems , setFilteredItems] = useState([]);
   
    useEffect(() => {
        window.scrollTo(0, 0);
        const fetchData = async () => {
          const data = await FirebaseService.getAddRequestsData();
          const sortedData = data.sort(sortByIsDeleted);
          console.log("sıralı data", sortedData);
          setItems(sortedData);
          setFilteredItems(sortedData)
        };
        

    fetchData();
  }, []);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    const searchValue = selectedKeys[0]?.toLowerCase();
  
    const filteredData = items.filter((item) => {
      // Render edilen değeri elde et
      let renderedValue = "";
  
      if (dataIndex === "tableName") {
        renderedValue =
          item[dataIndex] === "suppliers"
            ? "Tedarikçi"
            : item[dataIndex] === "companies"
            ? "Firma"
            : item[dataIndex] === "units"
            ? "Birim"
            : item[dataIndex] === "mails"
            ? "Mail"
            : item[dataIndex] === "allItems" && item.productType === "raw"
            ? "Ürün- Sac Levha"
            : item[dataIndex] === "allItems" && item.productType === "other"
            ? "Ürün- Diğer"
            : item[dataIndex] === "densities"
            ? "Kalite"
            : item[dataIndex] === "contractManufacturingProcesses"
            ? "Fason Üretim Süreç"
            : "Kategori Bulunamadı";
      } else {
        renderedValue = item[dataIndex]?.toString() || "";
      }
  
      // Filtreleme işlemi (hem silinmemiş hem de arama değerine uygun olanlar)
      return (
        !item.isDeleted &&
        (searchValue ? renderedValue.toLowerCase().includes(searchValue) : true)
      );
    });
  
    setFilteredItems(filteredData);
  };
  
  
  

  const handleReset = (clearFilters) => {
    clearFilters();
    setFilteredItems(items);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          id="search-input"
          placeholder={`Ara`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Ara
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Sıfırla
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value, record) => {
      let renderedValue = "";
  
      if (dataIndex === "tableName") {
        renderedValue =
          record[dataIndex] === "suppliers"
            ? "Tedarikçi"
            : record[dataIndex] === "companies"
            ? "Firma"
            : record[dataIndex] === "units"
            ? "Birim"
            : record[dataIndex] === "mails"
            ? "Mail"
            : record[dataIndex] === "allItems" && record.productType === "raw"
            ? "Ürün- Sac Levha"
            : record[dataIndex] === "allItems" && record.productType === "other"
            ? "Ürün- Diğer"
            : record[dataIndex] === "densities"
            ? "Kalite"
            : record[dataIndex] === "contractManufacturingProcesses"
            ? "Fason Üretim Süreç"
            : "Kategori Bulunamadı";
      } else {
        renderedValue = record[dataIndex]?.toString() || "";
      }
  
      return renderedValue.toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => document.getElementById("search-input").select(), 100);
      }
    },
    sorter: (a, b) => {
      const valueA = a[dataIndex];
      const valueB = b[dataIndex];
  
      if (valueA == null && valueB == null) return 0;
      if (valueA == null) return 1;
      if (valueB == null) return -1;
  
      const numA = parseFloat(valueA);
      const numB = parseFloat(valueB);
  
      if (!isNaN(numA) && !isNaN(numB)) {
        return numA - numB;
      }
  
      if (!isNaN(numA)) return -1;
      if (!isNaN(numB)) return 1;
  
      return String(valueA).localeCompare(String(valueB));
    },
  });

  const sortByIsDeleted = (a, b) => {
    if (a.isDeleted === false && b.isDeleted === true) {
      return -1; 
    }
    if (a.isDeleted === true && b.isDeleted === false) {
      return 1; 
    }
    const dateA = new Date(a.firstTransactionDate);
    const dateB = new Date(b.firstTransactionDate);
    return dateB - dateA;
  };
  
  const handleAdd = async (record) => {
   
    const { tableName, description, key, firstTransactionDateEdit, firstUserName, firstUserSurname,firstTransactionDate, ...newItem } = record;
    const newItemWithAdditionalProperties = {
        ...newItem,
        firstTransactionDate:new Date()
    };

    console.log("Yeni öğe:", newItemWithAdditionalProperties);
    try {
      const result = await FirebaseService.addRecording(record.tableName,newItemWithAdditionalProperties);
      if (result.success) {

        const deleteItem = await FirebaseService.deleteAddRequest(record.key);
        if (deleteItem.success) {
          NotificationService.openSuccessNotification({
            title: 'İşlem Başarılı',
            description: result.message,
            placement: 'topRight'
          });
          const data = await FirebaseService.getAddRequestsData();
         
            const sortedData = data.sort(sortByIsDeleted);
            console.log("sıralı data", sortedData);
            setItems(sortedData);
            setTimeout(() => {
              window.location.reload();
            }, 500);
            
        } else {
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
            placement: 'topRight'
          });
        }
      } 
      else {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
          placement: 'topRight'
        });
      }
      
      
    } catch (error) {
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "Bilinmeyen bir hata ile karşılaşıldı.",
        placement: 'topRight'
      });
    }

    
  };

  const columns = [
    {
      title: 'Kategori',
      dataIndex: 'tableName',
      key: 'tableName',
      width: 300,
      render: (text, record) => (
        <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
        {text === "suppliers" ? "Tedarikçi" : 
        text === "companies" ? "Firma" : 
        text === "units" ? "Birim" : 
        text === "mails" ? "Mail" : 
        text === "allItems"  && record.productType==="raw" ? "Ürün- Sac Levha" : 
        text === "allItems"  && record.productType==="other" ? "Ürün- Diğer" : 
        text === "densities" ? "Kalite" : 
        text === "contractManufacturingProcesses" ? "Fason Üretim Süreç" : "Kategori Bulunamadı"}
        </span>
      ),
      ...getColumnSearchProps('tableName')
        },
  {
    title: 'İsteyen Kişi',
    dataIndex: 'firstUserName',
    key: 'firstUserName',
    width: 200,
    render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {record.firstUserName} {record.firstUserSurname} 
    </span>,
    ...getColumnSearchProps('firstUserName')
  },
  {
    title: 'Durum',
    dataIndex: '',
    key: '',
    render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000"}}>{record.isDeleted ? "Eklendi" : "Eklenmedi"}</span>,
    width: 400,
  },
  
      
      
  ];

  const [isModalVisibleDensities, setIsModalVisibleDensities] = useState(false);
  const [recordForModal, setRecordForModal] = useState(null);

  const handleModalCancelDensities = () => {
      setIsModalVisibleDensities(false);
  };

  const handleAddFinishDensities = () => {
      setIsModalVisibleDensities(false);
  };

  const handleAddDensity = async (record) => {
    setRecordForModal(record);
    setIsModalVisibleDensities(true)
    
  };


  const [isModalVisibleAllItems, setIsModalVisibleAllItems] = useState(false);
  const [recordForModalAllItems, setRecordForModalAllItems] = useState(null);

  const handleModalCancelAllItems = () => {
      setIsModalVisibleAllItems(false);
  };

  const handleAddFinishAllItems = () => {
      setIsModalVisibleAllItems(false);
  };

  const handleAddAllItems = async (record) => {
    setRecordForModalAllItems(record);
    setIsModalVisibleAllItems(true)
    
  };
 




  const [isModalVisibleCompany, setIsModalVisibleCompany] = useState(false);
  const [recordForModalCompany, setRecordForModalCompany] = useState(null);

  const handleModalCancelCompany = () => {
      setIsModalVisibleCompany(false);
  };

  const handleAddFinishCompany = () => {
      setIsModalVisibleCompany(false);
  };

  const handleAddACompany = async (record) => {
    setRecordForModalCompany(record);
    setIsModalVisibleCompany(true)
    
  };

  return  <div style={{
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '94vh',
        padding:"3%",
       
      }}>
          <Table
            locale={{
              emptyText: 'Henüz ekleme talebi bulunmamaktadır.',
               filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
            dataSource={filteredItems}
            columns={columns}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
            expandable={{
              expandedRowRender: record => (
                <div style={{ display: 'block' }}>
                    <div style={{ marginLeft: "55px" }}>
                        <span style={{ fontWeight: "bold" }}>
                        <span style={{ fontWeight: "bold" }}>
                          {record.tableName === "suppliers" ? "Tedarikçi:" : 
                          record.tableName === "companies" ? "Şirket:" : 
                          record.tableName === "units" ? "Birim:" : 
                          record.tableName === "mails" ? "" : 
                          record.tableName === "densities" ? "" : 
                          record.tableName === "allItems"  && record.productType === "raw" ? "" : 
                          record.tableName === "allItems"  && record.productType === "other" ? "" : 
                          record.tableName === "contractManufacturingProcesses" ? "Fason Üretim Süreci:" : "Diğer:"}
                      </span>
                       </span>
                      <span style={{ color: record.isDeleted ? "#00000080" : "#000000", marginLeft: "5px" }}>
                          {record.tableName === "suppliers" ? record.supplierName : 
                          record.tableName === "companies" ? record.companyName : 
                          
                          record.tableName === "allItems" && record.productType === "raw" ? 
                          <div style={{ display: "flex" }}>
                          <span style={{ fontWeight: "bold", marginRight: "10px" }}>Kalınlık:</span>
                          <span>{record.productDictionary.thickness}</span>
                          <span style={{ fontWeight: "bold", margin: "0 10px" }}>Kalite:</span>
                          <span>{record.productDictionary.quality}</span>
                          <span style={{ fontWeight: "bold", margin: "0 10px" }}>Yüzey:</span>
                          <span>{record.productDictionary.surface}</span>
                          <span style={{ fontWeight: "bold", margin: "0 10px" }}>En:</span>
                          <span>{record.productDictionary.width}</span>
                          <span style={{ fontWeight: "bold", margin: "0 10px" }}>Boy:</span>
                          <span>{record.productDictionary.height}</span>
                        </div>
                        
                          : 

                          record.tableName === "allItems" && record.productType === "other" ? 
                          <div style={{ display: "flex" }}>
                            <span style={{ fontWeight: "bold", marginRight: "10px" }}>Ürün:</span>
                            <span>{record.productString}</span>
                          </div>
                        
                          : 

                          record.tableName === "units" ? record.unitName : 

                          record.tableName === "mails" ?
                          <div style={{ display: "flex" }}>
                            <span style={{ fontWeight: "bold", marginRight: "10px" }}>Email:</span>
                            <span>{record.email}</span>
                            <span style={{ fontWeight: "bold", margin: "0 10px" }}>Firma:</span>
                            <span>{record.companyName}</span>
                          </div>
                          
                           : 

                           record.tableName === "densities" ?
                           <div style={{ display: "flex" }}>
                             <span style={{ fontWeight: "bold", marginRight: "10px" }}>Kalite:</span>
                             <span>{record.quality}</span>
                             <span style={{ fontWeight: "bold", margin: "0 10px" }}>Özkütle:</span>
                             <span>?</span>
                           </div>
                           
                           :
                          

                          record.tableName === "contractManufacturingProcesses" ? record.processName  : "Diğer"}
                      </span>

                    </div>
                    <div style={{ marginLeft: "55px" }}>
                        <span style={{ fontWeight: "bold", color: record.isDeleted ? "#00000080" : "#000000" }}>Açıklama:</span>
                        <span style={{ color: record.isDeleted ? "#00000080" : "#000000", marginLeft: "5px" }}>
                            {record.description ? record.description : "-" }
                        </span>
                    </div>
                    <Button 
                      onClick={() => {
                        if (record.tableName === "allItems") {
                          handleAddAllItems(record);
                        } else if (record.tableName === "densities") {
                          handleAddDensity(record);
                        } 
                        else if (record.tableName === "companies") {
                          handleAddACompany(record);
                        } 
                        else {
                          handleAdd(record);
                        }
                      }}
                     disabled = {record.isDeleted ? true : false}
                    style={{float:"right",color:"white" ,fontWeight:"500",  backgroundColor: record.isDeleted ? "#00000080" : colors.blue }}
                    >
                      {record.isDeleted ? "Eklendi" : "Ekle"}
                  </Button>


                   
            <Modal
                title="Kalite Ekleme Talebi"
                visible={isModalVisibleDensities}
                onCancel={handleModalCancelDensities}
                footer={null}
              >
              <DensityAddAdmin   record={recordForModal}  onClose={handleAddFinishDensities} />
              </Modal>

              <Modal
                title="Ürün/Sac Talebi"
                visible={isModalVisibleAllItems}
                onCancel={handleModalCancelAllItems}
                footer={null}
              >
              <AllItemsAddAdmin   record={recordForModalAllItems}  onClose={handleAddFinishAllItems} />
              </Modal>

              <Modal
                title="Firma Ekleme Talebi"
                visible={isModalVisibleCompany}
                onCancel={handleModalCancelCompany}
                footer={null}
              >
              <CompanyAddAdmin record={recordForModalCompany}  onClose={handleAddFinishCompany} />
              </Modal>
            
            
                </div>
            ),
            
              expandIcon: ({ expanded, onExpand, record }) =>
                expanded ? (
                  <a type="link" onClick={e => onExpand(record, e)} style={{border:"none",backgroundColor:"transparent"}}><img style={{width:"20px"}} alt="edit" src="/images/uparrow.png" /></a>
                ) : (
                  <a type="link" onClick={e => onExpand(record, e)} style={{border:"none",backgroundColor:"transparent"}}><img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" /></a>
                ),
                expandIconColumnIndex: 5
            }}
        />
    </div>

  };
  export default AddRequestsList;
