import React,{useEffect,useState} from "react";
import { Table, Button,Space,Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../../services/firebaseService";
import NotificationService from "../../../../services/antNotificationService";
import  { colors}  from "../../../../environments/environment"
import moment from 'moment';
import { useLocation  } from 'react-router-dom';
import { SearchOutlined } from "@ant-design/icons";
const ProductionOpenOrderGroupList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const record = location.state && location.state.record;
  const [items, setItems] = useState(record);
  const [filteredItems , setFilteredItems] = useState(record)
  console.log( "items",items);
  console.log("record - grouplist" , record)
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
    };
    fetchData();
  }, [record]);
  console.log("Kontroooooooool REcord",record)
  const goDetail = (record) => {
    console.log("record",record)
    navigate('/productionopenorderlist', { state: { record } })
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    const filteredData = items.filter(item => {
      const value = selectedKeys[0]?.toLowerCase();
      const itemValue = item[dataIndex]?.toString().toLowerCase();
      return (
        !item.isDeleted &&
        (value ? itemValue.includes(value) : true)
      );
    });
    setFilteredItems(filteredData);
  };
  const handleReset = clearFilters => {
    clearFilters();
    setFilteredItems(items);
  };
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          id="search-input"
          placeholder={`Ara`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Ara
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Sıfırla
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890FF' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => document.getElementById('search-input').select(), 100);
      }
    },
    sorter: (a, b) => {
      // `status` alanını kontrol et ve her iki değeri de güvenli hale getir
      const statusA = a[dataIndex] ? String(a[dataIndex]) : ''; // `undefined` için boş string
      const statusB = b[dataIndex] ? String(b[dataIndex]) : ''; // `undefined` için boş string
      return statusA.localeCompare(statusB); // String karşılaştırması
    },
  });
  const columns = [
    {
      title: 'Müşteri Firma',
      dataIndex: 'companyName',
      key: 'companyName',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
      ...getColumnSearchProps('companyName')
  },
    {
      title: 'İş Kodu',
      dataIndex: 'jobCode',
      key: 'jobCode',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{text.substring(0, 5)}
      </span>,
      ...getColumnSearchProps('jobCode')
    },
    {
      title: 'Proje Kodu',
      dataIndex: 'projectCode',
      key: 'projectCode',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text}
      </span>,
      ...getColumnSearchProps('projectCode')
    },
    {
      title: 'Durum',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => {
        let statusColor = "#000000";
        return <span style={{ color: record.isDeleted ? "#00000080" : statusColor }}>Üretimde</span>;
      },
      sorter: (a, b) => {
        const statusA = a.status ? String(a.status) : ''; // `undefined` ve `null` için boş string ata
        const statusB = b.status ? String(b.status) : ''; // Aynı şekilde
        return statusA.localeCompare(statusB);
      },
      ...getColumnSearchProps('status')
    },
   /*
    {
        title: 'Termin Tarihi',
        dataIndex: 'deadline',
        key: 'deadline',
        render: (text, record) => {
          const formattedDeadline = record.deadline ? new Date(record.deadline.seconds * 1000).toLocaleDateString('tr-TR') : '';
          return <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>{formattedDeadline}</span>;
        },
        sorter: (a, b) => {
          const deadlineA = a.deadline ? a.deadline.seconds : 0;
          const deadlineB = b.deadline ? b.deadline.seconds : 0;
          return deadlineA - deadlineB;
      },
      },*/
      /*
      {
        title: ' ',
        dataIndex: 'important',
        key: 'important',
        render: (text, record) => {
          console.log("record aaa", record.stock);
          const showImage = record.stock && record.stock.some(stockItem => stockItem.isSendPurchase === true);
          return showImage ? (
            <img
              width={30}
              height={20}
              alt="logo"
              src="/images/important.png"
              style={{ paddingBottom: "5%" }}
            />
          ) : null;
        },
      },*/
      {
        title: ' ',
        dataIndex: 'important',
        key: 'important',
        render: (text, record) => {
          const showImage = record.stock && record.stock.some(stockItem => stockItem.isSendPurchase === true);
          return showImage ? (
           <div style={{color:colors.lightRed}}> Satın Alma Talebi var</div>
          ) : null;
        },
      },
      {
        title: ' ',
        dataIndex: 'edit',
        key: 'edit',
        render: (text, record) => (
            <Button  onClick={()=>goDetail(record)} style={{ backgroundColor: "white", color: colors.blue, fontWeight: "500", border: "none" }}>
            Detaya Git
          </Button>
        ),
      }
  ];
  return  <div style={{
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '94vh',
        padding:"3%",
      }}>
        <h3 style={{textAlign:"left", color:colors.blue}}>Açık Siparişler - Üretim</h3>
        {/* <Input
        style={{
          borderRadius: 0,
          border: 'none',
          borderBottom: '1px solid #1A446C80',
          width: '100%',
          maxWidth: '400px',
          textAlign: 'left',
          outline: 'none',
        }}
        placeholder="İş Kodu Ara"
        suffix={
          <Space>
              <img width={15} alt="edit" src="/images/searchicon.png" />
          </Space>
        }
        onChange={(e) => {
          const searchValue = e.target.value.toLowerCase();
          if (searchValue.length === 0){
            setItems(record)
          }else{
          const filteredData = items.filter(item => {
            const jobCode = item.jobCode.toLowerCase() ;
            return jobCode.includes(searchValue);
          });
          setItems(filteredData);
        }
        }}
      /> */}
        <Table
            locale={{
              emptyText: 'Henüz üretimde açık sipariş bulunmamaktadır....',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
            dataSource={filteredItems}
            columns={columns}
            showHeader={true}
            pagination={false}
            className="custom-news-table"
            rowClassName="custom-news-row"
        />
    </div>
  };
  export default ProductionOpenOrderGroupList;