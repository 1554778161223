import React, { useEffect, useState } from "react";
import { Table, Button, Space, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import { colors ,placeholderMap} from "../../../../environments/environment";
import { useLocation } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';

const WaitingOpenOrderGroupList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const record = location.state && location.state.record;
  const [items, setItems] = useState([]);
  const [allItems, setAllItems] = useState([]); 
  const [filteredItems, setFilteredItems] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (record) {
      const sortedRecords = [...record].sort((a, b) => {
        return b.jobCode.localeCompare(a.jobCode); 
      });
    
      setItems(sortedRecords);
      setAllItems(sortedRecords); 
      setFilteredItems(sortedRecords);

    }
  }, [record]);

  const goEditPage = (record) => {
    navigate('/waitingopenorderdetail', { state: { record } })

  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    const filteredData = items.filter(item => {
      const value = selectedKeys[0]?.toLowerCase(); 
      const itemValue = item[dataIndex]?.toString().toLowerCase(); 
      return (
        !item.isDeleted &&
       
        (value ? itemValue.includes(value) : true) 
      );
    });
    setFilteredItems(filteredData);
  };
  
  const handleReset = clearFilters => {
    clearFilters();
    setFilteredItems(items);
  };



  const getColumnSearchProps = dataIndex => ({
    
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
     <Input
    id="search-input"  
    placeholder={`${placeholderMap[dataIndex] || dataIndex} Ara`}
    value={selectedKeys[0]}
    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
    style={{ width: 188, marginBottom: 8, display: 'block' }}
    />

        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90,background:colors.blue }}
          >
            Ara
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Sıfırla
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => document.getElementById('search-input').select(), 100);
      }
    },
  });

  const columns = [
    {
      title: 'Müşteri Firma',
      dataIndex: 'companyName',
      key: 'companyName',
      ...getColumnSearchProps('companyName'),
      render: (text, record) => <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
      sorter: (a, b) => {
        const companyNameA = a && a.companyName ? a.companyName : '';
        const companyNameB = b && b.companyName ? b.companyName : '';
        return companyNameA.localeCompare(companyNameB);
      }
    },
    
    {
      title: 'Proje Kodu',
      dataIndex: 'projectCode',
      key: 'projectCode',
      ...getColumnSearchProps('projectCode'),
      sorter: (a, b) => {
        const itemA = a && a.projectCode ? a.projectCode : '';
        const itemB = b && b.projectCode ? b.projectCode : '';
        return itemA.localeCompare(itemB);
      },
      render: (text, record) => <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
    },
    {
      title: 'İş Kodu',
      dataIndex: 'jobCode',
      key: 'jobCode',
      width: 130,
      ...getColumnSearchProps('jobCode'),
      sorter: (a, b) => {
        const itemA = a && a.jobCode ? a.jobCode : '';
        const itemB = b && b.jobCode ? b.jobCode : '';
        return itemA.localeCompare(itemB);
      },
      render: (text, record) => <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
    },
    {
      title: 'Durum',
      dataIndex: '',
      key: '',
      width: 250,
      render: (text, record) => {
        const getMainStatus = (data) => {
          if (!data || data.length === 0) {
            return 'Veri bulunamadı';
          }
              if (data.some(item => !item.stock && !item.wastage && item.orderType === "order")) {
            return "Sipariş, Güncel Stok Seçimi Bekleniyor";
          }
    
          if (data.some(item => !item.isSetup && item.orderType === "order" && item.isWaiting)) {
            return "Sipariş, Setup Bekleniyor";
          }

          if (data.some(item => item.isSetup && item.isWaiting && item.orderType === "order")) {
            return "Açık Siparişe Geçirilmesi Bekleniyor";
          }
    
          if (data.some(item => item.isSetup && !item.isWaiting && item.orderType === "order")) {
            return "Açık siparişe geçirildi";
          }
    
          if (data.some(item => !item.isSetup && item.isWaiting && item.orderType === "offer")) {
            return "Teklif, Setup Bekleniyor";
          }
    
          if (data.some(item => item.isWaiting && !item.isSetup && item.orderType === "offer" && item.isOfferApproved && item.isOffer)) {
            return "Onaylandı, Setup Bekleniyor";
          }
    
          if (data.some(item => item.isSetup && item.isWaiting && item.orderType === "offer" && !item.isOfferSetupUpdate)) {
            return "Teklif, Setup Yüklendi";
          }
     
          if (
            data.some(
              item =>
                item.isSetup &&
                item.isWaiting &&
                item.orderType === "offer" &&
                !item.isOffer &&
                item.isOfferSetupUpdate
            )
          ) {
            return "Onaylanmadı, Setup Düzenleme";
          }
    
          return "Durum belirsiz";
        };
    
        const mainStatus = getMainStatus(record.data);
    
        return (
          <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
            {mainStatus}
          </span>
        );
      },
    }
,    
    {
      title: 'Programcı',
      dataIndex: 'programmerName',
      key: 'programmerName',
      render: (text, record) => (
        <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
          {text}
        </span>
      )
    },
    {
      title: 'Termin Tarihi',
      dataIndex: 'deadline',
      key: 'deadline',
    
      render: (text, record) => {
        const formattedDeadline = record.deadline ? new Date(record.deadline.seconds * 1000).toLocaleDateString('tr-TR') : '';
        return <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>{formattedDeadline ? formattedDeadline : "-"}</span>;
      },
      sorter: (a, b) => {
        const deadlineA = a.deadline ? a.deadline.seconds : 0;
        const deadlineB = b.deadline ? b.deadline.seconds : 0;
        return deadlineA - deadlineB;
      },
    },
    {
      title: ' ',
      dataIndex: 'edit',
      key: 'edit',
      render: (text, record) => (
        <Button onClick={() => goEditPage(record)} style={{ backgroundColor: colors.blue, color: "white", fontWeight: "500" }}>
          Detaya Git
        </Button>
      ),
    }
  ];

  return (
    <div style={{
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '94vh',
      padding: "3%",
    }}>
      <h3 style={{ textAlign: "left", color: colors.blue }}>Bekleyen Siparişler</h3>
   

      <Table
        locale={{
          emptyText: 'Henüz bekleyen sipariş bulunmamaktadır...',
          filterReset: 'Sıfırla',
          filterTitle: 'Filtre Menüsü',
          selectAll: 'Hepsini Seç',
          selectInvert: 'Tersini Seç',
          selectionAll: 'Tümünü Seç',
          sortTitle: 'Sıralama',
          triggerDesc: 'Azalan sıralama için tıklayın',
          triggerAsc: 'Artan sıralama için tıklayın',
          cancelSort: 'Sıralamayı iptal etmek için tıklayın',
        }}
        dataSource={filteredItems}
        columns={columns}
        showHeader={true}
        pagination={false}
        className="custom-news-table"
        rowClassName="custom-news-row"
        expandable={{
          expandedRowRender: record => (
            <div style={{ display: 'flex' }}>
            <div style={{ marginLeft: "355px" }}>
              <div style={{ marginBottom: "10px" }}>
                {record.data.map((item, index) => (
                  <div key={index} style={{ display: "flex", marginBottom: "5px",}}>
                    <div style={{minWidth:"120px" }}> {item.jobCode}</div>
                    <div style={{ marginLeft: "10px" }}>
                      {(!item.stock && !item.wastage && item.orderType ==="order") ? (
                        <span>Sipariş, Güncel Stok Seçimi Bekleniyor</span>
                      ) : (!item.isSetup && item.orderType ==="order" && item.isWaiting ? (
                        <span>Sipariş,Setup Bekleniyor</span>
                      ) : (item.isSetup && !item.isWaiting && item.orderType ==="order" ? (
                        <span>Açık Siparişe Geçirildi</span>
                        
                      ) : (item.isSetup && item.isWaiting && item.orderType ==="order" ? (
                        <span>Açık Siparişe Geçirilmesi Bekleniyor</span>
                        
                      ) : (!item.isSetup && item.isWaiting && item.orderType ==="offer" ? (
                        <span>Teklif, Setup Bekleniyor</span>
                      
                      ) : (item.isSetup && item.isWaiting && item.orderType ==="offer" ? (
                        <span>Onaylandı, Setup Bekleniyor</span>
                      
                      ) : (item.isSetup && item.isWaiting && item.orderType ==="offer" && item.isOffer && item.isOfferSetupUpdate ? (
                        <span>Onaylanmadı,Setup Düzenleme</span>
                      
                      
                      ) : (
                        <span>Durum belirsiz</span>
                      )))))))}
                    </div>

                  </div>
                ))}
              </div>
            </div>
          </div>
          ),
           
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <a type="link" onClick={e => onExpand(record, e)} style={{border:"none",backgroundColor:"transparent"}}><img style={{width:"20px"}} alt="edit" src="/images/uparrow.png" /></a>
            ) : (
              <a type="link" onClick={e => onExpand(record, e)} style={{border:"none",backgroundColor:"transparent"}}><img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" /></a>
            ),
          expandIconColumnIndex: 7
        }}
      />
    </div>
  );
};

export default WaitingOpenOrderGroupList;


/*
{
  title: 'Durum',
  dataIndex: 'status',
  key: 'status',
  render: (text, record) => {
    let statusColor = "#000000"; // Default color

    // Assign colors based on the status
    switch (text) {
      case "Sipariş,Setup Bekleniyor":
        statusColor =colors.orange
        break;
      case "Sipariş, Güncel Stok Seçimi Bekleniyor":
        statusColor =colors.orange
        break;
      case "Teklif, Setup Bekleniyor":
        statusColor = colors.orange
        break;
      case "Onaylandı, Setup Bekleniyor":
        statusColor = colors.green
        break;
      case "Onaylanmadı,Setup Düzenleme":
        statusColor = colors.red
        break;
      default:
        statusColor = "#000000"; // Default black color
    }

    return <span style={{ color: record.isDeleted ? "#00000080" : statusColor }}>{text}</span>;
  },
},

   <Input
        style={{
          borderRadius: 0,
          border: 'none',
          borderBottom: '1px solid #1A446C80',
          width: '100%',
          maxWidth: '400px',
          textAlign: 'left',
          outline: 'none',
        }}
        placeholder="İş Kodu Ara"
        suffix={
          <Space>
            <img width={15} alt="edit" src="/images/searchicon.png" />
          </Space>
        }
        onChange={(e) => {
          const searchValue = e.target.value.toLowerCase();
          if (searchValue === "") {
            setItems(allItems); // Arama kutusu boşsa tüm verileri geri getir
          } else {
            const filteredData = allItems.filter(item => {
              const jobCode = item.jobCode.toLowerCase();
              return jobCode.includes(searchValue);
            });
            setItems(filteredData);
          }
        }}
      />
*/
