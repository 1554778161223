import React,{useEffect,useState} from "react";
import { Table, Button,Modal, Input, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import NotificationService from "../../../services/antNotificationService";
import  { colors, parsCollections, placeholderMap}  from "../../../environments/environment"
import moment from 'moment';
import { useLocation  } from 'react-router-dom';
import ExpenseAdd from "./expenseAdd";
import { SearchOutlined } from "@ant-design/icons";

const ExpenseList = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const [items, setItems] = useState([]);
  const [filteredItems , setFilteredItems] = useState([])

  useEffect(() => {
   
    window.scrollTo(0, 0);

    const fetchData = async () => {
        const allOpenOrder = await FirebaseService.getExpensesData();
        const filteredData = allOpenOrder.filter(item => !item.isDeleted);
        setItems(filteredData);
        setFilteredItems(filteredData);
    };

    fetchData();
  }, []);


  const goEditPage = (record) => {
    navigate('/expenseedit', { state: { record } })
  };

  const goDisplayPage = (record) => {
    navigate('/expensedisplay', { state: { record } })
  };
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleExpenseAdd = () => {
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handleExpenseAddFinish = () => {
    setIsModalVisible(false); 
  };

  const monthNames = [
    'ocak', 'şubat', 'mart', 'nisan', 'mayıs', 'haziran',
    'temmuz', 'ağustos', 'eylül', 'ekim', 'kasım', 'aralık'
  ];


  const getMonthNumber = (searchText) => {
    // searchText undefined veya null ise null dön
    if (!searchText) return null;

    // Aramada kullanılan metni küçük harfe çevir ve türkçe karakterleri normalize et
    const normalizedSearch = searchText.toLowerCase()
      .replace('ı', 'i')
      .replace('ğ', 'g')
      .replace('ü', 'u')
      .replace('ş', 's')
      .replace('ö', 'o')
      .replace('ç', 'c');

    // Ay isimlerini de normalize et ve karşılaştır
    const monthIndex = monthNames.findIndex(month => {
      const normalizedMonth = month
        .replace('ı', 'i')
        .replace('ğ', 'g')
        .replace('ü', 'u')
        .replace('ş', 's')
        .replace('ö', 'o')
        .replace('ç', 'c');
      return normalizedMonth.includes(normalizedSearch);
    });

    return monthIndex !== -1 ? monthIndex + 1 : null;
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    const value = selectedKeys[0];
    let filteredData = [...items];

    if (!value) {
      // Eğer value undefined veya null ise tüm verileri göster
      setFilteredItems(items);
      return;
    }

    if (dataIndex === 'month') {
      // Sayısal değer kontrolü
      if (!isNaN(value)) {
        // Eğer sayısal bir değer girilmişse direkt olarak ay numarası ile karşılaştır
        filteredData = filteredData.filter(item => 
          !item.isDeleted && item[dataIndex] === parseInt(value)
        );
      } else {
        // Metin girildiyse ay ismine göre ara
        const monthNumber = getMonthNumber(value);
        if (monthNumber !== null) {
          filteredData = filteredData.filter(item => 
            !item.isDeleted && item[dataIndex] === monthNumber
          );
        }
      }
    } else {
      // Diğer kolonlar için normal arama
      filteredData = filteredData.filter(item => 
        !item.isDeleted && 
        item[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase())
      );
    }

    setFilteredItems(filteredData);
  };

  const handleReset = (clearFilters) => {
    if (clearFilters) {
      clearFilters();
    }
    setFilteredItems(items);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          id="search-input"
          placeholder={dataIndex === 'month' ? 'Ay adı veya sayı giriniz' : `${placeholderMap[dataIndex] || dataIndex} Ara`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, background: colors.blue }}
          >
            Ara
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Sıfırla
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      if (!value) return true;
      
      if (dataIndex === 'month') {
        if (!isNaN(value)) {
          return record[dataIndex] === parseInt(value);
        }
        const monthNumber = getMonthNumber(value);
        return monthNumber !== null && record[dataIndex] === monthNumber;
      }
      const recordValue = record[dataIndex]?.toString().toLowerCase();
      return recordValue?.includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => document.getElementById('search-input').select(), 100);
      }
    },
    sorter: (a, b) => {
      const aValue = a[dataIndex];
      const bValue = b[dataIndex];
      
      return aValue - bValue;
    },
  });
  
  


  const columns = [
    {
      title: 'Ay',
      dataIndex: 'month',
      key: 'month',
      render: (text, record) => {
          const monthNames = [
              'Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 
              'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'
          ];
          return (
              <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
                  {monthNames[text - 1]}
              </span>
          );
      },
      ...getColumnSearchProps('month')
  },
  
  
    {
      title: 'Yıl',
      dataIndex: 'year',
      key: 'year',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text}
      </span>,
      ...getColumnSearchProps('year')
    },
    {
      title: ' ',
      dataIndex: 'edit',
      key: 'edit',
      render: (text, record) => (
        <Button onClick={()=>goEditPage(record)} style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}}>
        Düzenle
     </Button>
      ),
    }
  ,
   
   
  ];


  return  <div style={{
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '94vh',
        padding:"3%",
       
      }}>
        <h3  style={{backgroundColor:"white" ,color:colors.blue,fontWeight:"700", border:"none",float:"left"}}>Giderler</h3>
        <Button 
       // onClick={()=>goAddPage()} 
        onClick={handleExpenseAdd}
        icon={<img alt="plus" src="/images/plusbutton.png" style={{width:"60%"}}/>} style={{background:"transparent",borderColor:"#1A446C80",color:"#1A446C",fontWeight:"bold",borderRadius:"0px",float:"right"}}>
          Yeni Ay Ekle
        </Button>
          <Table
            locale={{
              emptyText: 'Henüz bulunmamaktadır...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
            dataSource={filteredItems}
            columns={columns}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
          
        />

          <Modal
            title="Yeni Ay Ekle"
            visible={isModalVisible}
            onCancel={handleModalCancel}
            footer={null}>
            <ExpenseAdd onClose={handleExpenseAddFinish} />
        </Modal>

       
    </div>

  };
  export default ExpenseList;

  