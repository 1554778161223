import React,{useEffect,useState} from "react";
import { Table, Button,Modal, Space, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import NotificationService from "../../../services/antNotificationService";
import  { colors}  from "../../../environments/environment"
import moment from 'moment';
import { useLocation  } from 'react-router-dom';
import WantedOfferAdd from "./wantedOfferAdd";
import { SearchOutlined } from "@ant-design/icons";
const WantedOfferList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const record = location.state && location.state.record;
  const [items, setItems] = useState(false);
  const [filteredItems , setFilteredItems] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [recordForModal, setRecordForModal] = useState(null);
  useEffect(() => {
    console.log("items",items);
    window.scrollTo(0, 0);
    const fetchData = async () => {
      const data = await FirebaseService.getPurchaseRequestData();
      const filteredData = data.filter(item => item.mailList && item.mailList.length >= 1);
    setItems(filteredData)
    setFilteredItems(filteredData)
    };
    fetchData();
  }, []);
  const mapProductType = (value) => {
    switch (value) {
      case 'raw':
        return 'Saç Levha';
      case 'other':
        return 'Diğer';
      default:
        return value;
    }
  };
  const handleAddOffer = (record) => {
    setRecordForModal(record);
    setIsModalVisible(true);
  };
  const handleModalCancel = () => {
    console.log("bastı",)
    window.location.reload();
    setIsModalVisible(false);
  };
  const handleAddOfferFinish = () => {
    window.location.reload();
    setIsModalVisible(false);
  };
  const goDisplay = (record) => {
    console.log("record",record)
    /*
    NotificationService.openErrorNotification(
      {title:'',description:'Beta sürümünde bulunmamaktadır.',placement:'topRight'})
    return;
    */
    if (record.incomingExcelList?.length > 0 ) {
      navigate('/wantedofferview', { state: { record } })
    }
    else{
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "Teklif yüklendikten sonra görebilirsiniz.",
        placement: 'topRight'
      });
      return;
    }
   // navigate('/wantedofferview', { state: { record } })
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    const filteredData = items.filter(item => {
      const value = selectedKeys[0]?.toLowerCase();
      let itemValue;
      if (dataIndex === 'productType') {
        // Dönüştürülmüş değeri kullan
        itemValue = mapProductType(item[dataIndex]).toLowerCase();
      } else {
        itemValue = item[dataIndex]?.toString().toLowerCase();
      }
      return (
        !item.isDeleted &&
        (value ? itemValue.includes(value) : true)
      );
    });
    setFilteredItems(filteredData);
  };
  const handleReset = clearFilters => {
    clearFilters();
    setFilteredItems(items);
  };
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          id="search-input"
          placeholder={`Ara`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Ara
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Sıfırla
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890FF' : undefined }} />,
    onFilter: (value, record) => {
      const itemValue = dataIndex === 'productType'
        ? mapProductType(record[dataIndex]).toLowerCase()
        : record[dataIndex]?.toString().toLowerCase();
      return itemValue.includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => document.getElementById('search-input').select(), 100);
      }
    },
    sorter: (a, b) => {
      const valueA = dataIndex === 'productType'
        ? mapProductType(a[dataIndex])
        : a[dataIndex];
      const valueB = dataIndex === 'productType'
        ? mapProductType(b[dataIndex])
        : b[dataIndex];
      return valueA.localeCompare(valueB);
    },
  });
  const columns = [
    {
      title:"Sipariş Kod",
      dataIndex: 'offerJobCode',
      key: 'offerJobCode',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
      ...getColumnSearchProps('offerJobCode'),
      sorter: (a, b) => {
        const offerJobCodeA = a && a.offerJobCode ? a.offerJobCode: '';
        const offerJobCodeB = b && b.offerJobCode ? b.offerJobCode : '';
        return offerJobCodeA.localeCompare(offerJobCodeB);
      }
  },
  {
    title: 'Ürün',
    dataIndex: 'productType',
    key: 'productType',
    render: (text, record) => (
      <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
        {mapProductType(text)}
      </span>
    ),
    ...getColumnSearchProps('productType'),
    sorter: (a, b) => {
      const productA = a && a.productType ? a.productType: '';
      const productB = b && b.productType ? b.productType : '';
      return productA.localeCompare(productB);
    }
  },
  /*
  {
    title: 'Durum',
    dataIndex: '',
    key: '',
    render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000"}}>{record.acceptExcelDocumentUrl ?  "Teklif Yüklendi" : "Teklif Yüklenmedi" }</span>,
  },*/
  {
    title:"Durum",
    dataIndex: '',
    key: '',
    render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{record.incomingExcelList ?  record.incomingExcelList.length : 0 } / {record.mailList.length} teklif yüklendi , {record.acceptExcelDocumentUrl ? "Seçildi" : "Seçilmedi"}</span>,
},
{
  title: 'Termin Tarihi',
  dataIndex: 'deadline',
  key: 'deadline',
  render: (text, record) => {
    const formattedDeadline = record.deadline ? new Date(record.deadline.seconds * 1000).toLocaleDateString('tr-TR') : '';
    return <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>{formattedDeadline}</span>;
  },
  sorter: (a, b) => {
    const deadlineA = a.deadline ? a.deadline.seconds : 0;
    const deadlineB = b.deadline ? b.deadline.seconds : 0;
    return deadlineA - deadlineB;
},
},
{
  title: '   ',
  dataIndex: 'edit',
  key: 'edit',
  render: (text, record) => (
    <>
    <Button
    disabled = { (record.incomingExcelList?.length || 0) === record.mailList.length ? true : false }
    onClick={()=>handleAddOffer(record)}
    style={{
      backgroundColor: ( (record.incomingExcelList?.length || 0) === record.mailList.length ? true : false ) ? "gray" : colors.blue,
      color:"white",fontWeight:"500", }}>
    Teklif Yükle
   </Button>
      <Modal
      title="Teklif Yükle"
      visible={isModalVisible}
      onCancel={handleModalCancel}
      footer={null}
      >
      <WantedOfferAdd   record={recordForModal} onClose={handleAddOfferFinish} />
      </Modal>
      </>
  ),
},
{
    title: ' ',
    dataIndex: 'edit',
    key: 'edit',
    render: (text, record) => (
      <Button
       onClick={()=>goDisplay(record)}
      style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}}>
      Teklifleri Gör
   </Button>
    ),
  }
  ];
  return  <div style={{
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '94vh',
        padding:"3%",
      }}>
        <h3  style={{backgroundColor:"white" ,color:colors.blue,fontWeight:"700", border:"none",float:"left"}}>İstenen Teklif Listesi</h3>
          <Table
            locale={{
              emptyText: 'Henüz istenen teklif bulunmamaktadır...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
            dataSource={filteredItems}
            columns={columns}
            showHeader={true}
            pagination={false}
            className="custom-news-table"
            rowClassName="custom-news-row"
        />
    </div>
  };
  export default WantedOfferList;