import React,{useEffect,useState} from "react";
import { Button, Form, Input,Select,DatePicker, Upload,Modal, List} from 'antd';
import NotificationService from "../../../services/antNotificationService";
import FirebaseService from "../../../services/firebaseService";
import  { parsCollections, colors,openOrderType}  from "../../../environments/environment"
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import FirebaseStorage from "../../../services/storegeService";
import SetupAdd from "./setupAdd";


const OpenOrderSecond = ({ onClose }) => {
    const navigate = useNavigate();
    const [jobCodes, setJobCodes] = useState([]); 
    const [matchingItems, setMatchingItems] = useState([]);

    useEffect(() => {
      const fetchData = async () => {

        const jobCodes = await FirebaseService.getOpenOrderData();
        const uniqueJobCodes = new Map();
        const existingPrefixes = new Set(); 

        jobCodes.forEach((item) => {
          
          if (item.jobCode) { 
            const prefix = item.jobCode.substring(0, 5); 
            if (!item.isDeleted && !existingPrefixes.has(prefix) && item.orderType === "order") {
              uniqueJobCodes.set(item.jobCode, item);
              existingPrefixes.add(prefix); 
            }
          }
        });

        const filteredJobCodes = Array.from(uniqueJobCodes.values());
        console.log("mevcut orderlar", filteredJobCodes);
        setJobCodes(filteredJobCodes);

        
        
      };
      fetchData();
    }, []);
    
    const onFinishFailed = (errorInfo) => {
      NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen zorunlu alanları doldurunuz.',placement:'topRight'})
    };    
    
  const onChange = async (value) => {
      console.log(`Selected ${value}`);

    const firstPart = value.substring(0, 5);
    console.log("First part", firstPart);
    const data = await FirebaseService.getOpenOrderData();
    const matchingItems = data.filter(item => item.jobCode.startsWith(firstPart));
    setMatchingItems(matchingItems);
  
};

const onFinish = async (value) => {

  const selectedJobCode = value.jobCode;
  const regex = /^[^-]*/;
  const match = selectedJobCode.match(regex);
  const firstPart = match ? match[0] : selectedJobCode;
  console.log("firstPart", firstPart); // "30883"

  const data = await FirebaseService.getOpenOrderData();
  const openOrderInfo = data.filter(item => item.jobCode === selectedJobCode);
  
  const selectedOpenOrderInfo = {
    companyName: openOrderInfo[0]?.companyName || null,
    orderType: openOrderInfo[0]?.orderType || null,
    projectCode: openOrderInfo[0]?.projectCode || null,
    warehouseName: openOrderInfo[0]?.warehouseName || null,
    jobCode:firstPart, 
    isOfferApproved: openOrderInfo[0]?.isOfferApproved  || false,
    isOffer: openOrderInfo[0]?.isOffer  || false,
    isSetup: openOrderInfo[0]?.isSetup  || false,
    isOfferSetupUpdate: openOrderInfo[0]?.isOfferSetupUpdate  || false,
    setupName: openOrderInfo[0]?.setupName,
    stock: openOrderInfo[0]?.stock,
    wastage:openOrderInfo[0]?.wastage,
    deadline: openOrderInfo[0]?.deadline,
    key: openOrderInfo[0]?.key,
    isOfferable: openOrderInfo[0]?.isOfferable || false
  };

  

  console.log("selectedOpenOrderInfo", selectedOpenOrderInfo);

   navigate('/openorderaddcontinue', { state: { record: selectedOpenOrderInfo, jobCode:firstPart} });


  };
  

   return ( <div>

<Form
            name="basic"
            labelCol={{
            span: 4,
            }}
            wrapperCol={{
            span: 20,
            }}
            style={{
            maxWidth: "100%",
            padding:"5% 0 0 5%"
            }}
            initialValues={{
            remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
    
              <Form.Item  
                label="İş Kodu"
                name="jobCode"
                rules={[
                    {
                    required: true,
                    message: 'Lütfen iş kodu seçiniz!',
                    },
                ]}
                >
                <Select
                showSearch
                    onChange={onChange}
                    placeholder="İş Kodu Seçiniz"
                    suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
                    options={jobCodes.map(item => ({ label: item.jobCode, value: item.jobCode }))}
                    className="input-style" 
                    filterOption={(input, option) => 
                      option.label.toLowerCase().includes(input.toLowerCase()) // Arama için filtreleme
                    }
                />
            </Form.Item>


            {matchingItems.length > 0 && (
                <List
                    header={<div style={{color:colors.blue, fontWeight:"bold"}}>Yüklenen Setuplar</div>}
                    bordered
                    dataSource={matchingItems}
                    renderItem={item => (
                        <List.Item>
                            {item.jobCode.length === 5 
                                ? "Setup bulunmamaktadır." 
                                : item.jobCode}
                        </List.Item>
                    )}
                    className="input-style" 
                    style={{ width:"100%"}}
                />
            )}

            <Form.Item
            wrapperCol={{
                offset: 15,
                span: 18,
            }}
            >
            <Button htmlType="submit" style={{backgroundColor:colors.blue,color:"white",fontWeight:"500", float:"right", marginTop:"20px"}}>Gönder</Button>
            </Form.Item>

        </Form>

  

    </div>)

};

OpenOrderSecond.propTypes = {
  onClose: PropTypes.func.isRequired, 
};

  export default OpenOrderSecond;