import React,{useEffect,useState} from "react";
import { Button, Form, Input,Table ,Modal,Tooltip, Popconfirm, Spin} from 'antd';
import NotificationService from "../../../../services/antNotificationService";
import { useLocation  } from 'react-router-dom';
import FirebaseService from "../../../../services/firebaseService";
import { useNavigate } from 'react-router-dom';
import  {colors}  from "../../../../environments/environment"
import SetupAdd from "../setupAdd";
import WaitingOpenOrderUpdateDetail from "./waitingOpenOrderUpdateDetail";
import FirebaseStorage from "../../../../services/storegeService";
import { DownOutlined, UpOutlined } from "@ant-design/icons"; 
import { InfoCircleOutlined } from "@ant-design/icons";


const WaitingOpenOrderDetail = () => {
    const location = useLocation();
    const navigate = useNavigate();
   
    const recordIncoming = location.state?.record;
    const [record, setRecord] = useState(recordIncoming || null);

    const [loading, setLoading] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [recordForModal, setRecordForModal] = useState(null);


    const [isExpanded, setIsExpanded] = useState(false);

    const toggleVisibility = () => {
      setIsExpanded((prev) => !prev);
    };


    const handleRequestRemoval = (record) => {
      setRecordForModal(record);
      setIsModalVisible(true);
  };
  
    const handleModalCancel = () => {
      setIsModalVisible(false);
    };
  
    const handleRequestRemovalFinish = () => {
      setIsModalVisible(false); 
    };
  

    const [isModalVisibleSetup, setIsModalVisibleSetup] = useState(false);
    const [recordForModalSetup, setRecordForModalSetup] = useState(null);


    const handleSetup = (item) => {
      
      if (item.orderType === 'order') {
        if ((!item.stock || item.stock.length === 0) && (!item.wastage || item.wastage.length === 0)) {
          NotificationService.openErrorNotification({
            title: "Hammadde Seçimi Gerekiyor",
            description: "Lütfen hammadde ya da fire seçiniz.",
            placement: "topRight",
          });
          setLoading(false);
          return; 
          
        }
        setRecordForModalSetup(item);
       setIsModalVisibleSetup(true);
      }
      else {
        if (item.data.length === 1) {
            const singleItem = item.data[0];
            setRecordForModalSetup(singleItem);
            setIsModalVisibleSetup(true);
        } else if (item.data.length > 1) {
          const singleItem = item.data[0];
          setRecordForModalSetup(singleItem);
          setIsModalVisibleSetup(true);
          /*
          //burası neden var unuttum
            const filteredItem = item.data.find(item => !item.isSetup);
            if (filteredItem) {
                setRecordForModalSetup(filteredItem);
                setIsModalVisibleSetup(true);
            } else {
                console.log("Tüm itemlar isSetup: true", item.data);
             
            }*/
        }
    }
    
   
  };
  
    const handleModalCancelSetup = () => {
      setIsModalVisibleSetup(false);
    };
  
    const handleFinishSetup = () => {
      setIsModalVisibleSetup(false); 
    };
  
    const handleOpenOrderContinue = (record) => {

     //isOfferApproved vs sonradan eklendi
     const selectedOpenOrderInfo = {
      companyName: record.companyName || null,
      orderType: record.orderType || null,
      projectCode: record.projectCode || null,
      warehouseName: null,
      isOfferApproved: record.isOfferApproved  || false,
      isOffer: record.isOffer  || false,
      isSetup: record.isSetup  || false,
      isOfferSetupUpdate: record.isOfferSetupUpdate  || false,
      setupName: record.setupName,
      stock: record.stock,
      wastage:record.wastage,
      jobCode:record.jobCode,
      deadline: record.deadline,
      key: record.key,
      isOfferable: record.isOfferable || false

    };
    
     navigate('/openorderaddcontinue', { state: { record: selectedOpenOrderInfo} });

    };

    const handleGoOpenOrder = async (record) => {
      setLoading(true);
      if (record.isSetup === false) {
        NotificationService.openErrorNotification({
          title: "Setup Yüklemeden Geçilemez",
          description: "Lütfen setup yükledikten sonra işlemi gerçekleştirin.",
          placement: "topRight",
        });
        setLoading(false);
        return; 
      }
    
      if ((!record.stock || record.stock.length === 0) && (!record.wastage || record.wastage.length === 0)) {
        NotificationService.openErrorNotification({
          title: "Hammadde Seçimi Gerekiyor",
          description: "Lütfen hammadde ya da fire seçiniz.",
          placement: "topRight",
        });
        setLoading(false);
        return; 
      }

      const updateOpenOrderItem = {
        isWaiting: false
      };

      const updateResult = await FirebaseService.updateOpenOrder(
        record.key,
        updateOpenOrderItem
      );

      if (updateResult.success) {
        NotificationService.openSuccessNotification({
          title: "İşlem Başarılı",
          description: "Başarıyla açık siparişe geçirilmiştir.",
          placement: "topRight",
        });
       
        setTimeout(() => {
          window.location.reload();
        }, 10);

      } else {
        NotificationService.openErrorNotification({
          title: "İşlem Başarısız",
          description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
          placement: "topRight",
        });
        setLoading(false);
        return;
      }


    };

    const handleAllGoOpenOrder = async (record) => {
      setLoading(true);

      if (!record || !Array.isArray(record.data)) {
        console.error("Invalid record structure");
        return;
      }

      for (const item of record.data) {
        if (item.isSetup === false) {
          NotificationService.openErrorNotificationLongTime({
            title: "İşlem Başarısız",
            description: `XML yüklenmemiş açık sipariş bulunmaktadır. (İş Kodu: ${item.jobCode}). Lütfen XML yükleyiniz.`,
            placement: "topRight",
          });
          setLoading(false);
          return;
      
        }
      }

      const allAlreadyWaiting = record.data.every(item => item.isWaiting === false);
      if (allAlreadyWaiting) {
        NotificationService.openInfoNotification({
          title: "Bilgilendirme",
          description: "Tüm siparişler zaten açık siparişe geçirilmiş durumda.",
          placement: "topRight",
        });
        setLoading(false);
        return;
      }
      
      let allUpdatesSuccessful = true; 

      for (const item of record.data) {
        const updateOpenOrderItem = {
          isWaiting: false,
        };
      
        const updateResult = await FirebaseService.updateOpenOrder(
          item.key, 
          updateOpenOrderItem
        );
    
        if (!updateResult.success) {
          allUpdatesSuccessful = false;
          NotificationService.openErrorNotification({
            title: "İşlem Başarısız",
            description: `İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz. (İş Kodu: ${item.jobCode || "Belirtilmedi"})`,
            placement: "topRight",
          });
          setLoading(false);
          return; 
        }
      }
    
      if (allUpdatesSuccessful) {
        NotificationService.openSuccessNotification({
          title: "İşlem Başarılı",
          description: "Tüm açık siparişler başarıyla güncellenmiştir.",
          placement: "topRight",
        });
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }

  

    };

    const handleAllGoOffer = async (record) => {
      setLoading(true);

      if (!record || !Array.isArray(record.data)) {
        console.error("Invalid record structure");
        return;
      }

      for (const item of record.data) {
        if (item.isSetup === false) {
          NotificationService.openErrorNotificationLongTime({
            title: "İşlem Başarısız",
            description: `XML yüklenmemiş açık sipariş bulunmaktadır. (İş Kodu: ${item.jobCode}). Lütfen XML yükleyiniz.`,
            placement: "topRight",
          });
          setLoading(false);
          return;
        
        }
      }
      let allUpdatesSuccessful = true; 

      for (const item of record.data) {
        const updateOpenOrderItem = {
          isOfferable:true
        };
      
        const updateResult = await FirebaseService.updateOpenOrder(
          item.key, 
          updateOpenOrderItem
        );
    
        if (!updateResult.success) {
          allUpdatesSuccessful = false;
          NotificationService.openErrorNotification({
            title: "İşlem Başarısız",
            description: `İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz. (İş Kodu: ${item.jobCode || "Belirtilmedi"})`,
            placement: "topRight",
          });
          setLoading(false);
          return; 
        }
      }
    
      if (allUpdatesSuccessful) {
        NotificationService.openSuccessNotification({
          title: "İşlem Başarılı",
          description: "Başarıyla güncellenmiştir.",
          placement: "topRight",
        });
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }

  

    };

    const handleWithdrawOffer = async (record) => {
      setLoading(true);

      if (!record || !Array.isArray(record.data)) {
        console.error("Invalid record structure");
        return;
      }

      for (const item of record.data) {
        if (item.isSetup === false) {
          NotificationService.openErrorNotificationLongTime({
            title: "İşlem Başarısız",
            description: `XML yüklenmemiş açık sipariş bulunmaktadır. (İş Kodu: ${item.jobCode}). Lütfen XML yükleyiniz.`,
            placement: "topRight",
          });
          setLoading(false);
          return;
        
        }
      }
      let allUpdatesSuccessful = true; 

      for (const item of record.data) {
        const updateOpenOrderItem = {
          isOfferable:false
        };
      
        const updateResult = await FirebaseService.updateOpenOrder(
          item.key, 
          updateOpenOrderItem
        );
    
        if (!updateResult.success) {
          allUpdatesSuccessful = false;
          NotificationService.openErrorNotification({
            title: "İşlem Başarısız",
            description: `İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz. (İş Kodu: ${item.jobCode || "Belirtilmedi"})`,
            placement: "topRight",
          });
          setLoading(false);
          return; 
        }
      }
    
      if (allUpdatesSuccessful) {
        NotificationService.openSuccessNotification({
          title: "İşlem Başarılı",
          description: "Başarıyla güncellenmiştir.",
          placement: "topRight",
        });
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }

  

    };

    const [fileURL, setFileURL] = useState("");
  
    const [expandedItems, setExpandedItems] = useState({}); 

    const toggleExpand = (index) => {
      setExpandedItems((prev) => ({
        ...prev,
        [index]: !prev[index], 
      }));
    };
    
    useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
      setLoading(true)

      const folderName = 'openOrderOffers';
      const jobCodePrefix = recordIncoming.jobCode.substring(0, 5);
      const fileName = `Satis_Teklif_Sozlesmesi_${jobCodePrefix}.xlsx`; 

      const url = await FirebaseStorage.getDocumentURL(folderName, fileName);

      if (url) {
         // console.log("URL: ", url);
          setFileURL(url);
      } else {
         // console.log("File URL not found: ", folderName, fileName);
          setFileURL(null);  
      }


      const data = await FirebaseService.getOpenOrderData();

      let matchingRecords = data.filter(item => 
        !item.isDeleted && item.jobCode.substring(0, 5) === record.jobCode.substring(0, 5)
      );
      
      if (matchingRecords.length > 0) {
        const findFirstNonEmptyValue = (key) => {
          const found = matchingRecords.find(item => item[key] !== null && item[key] !== undefined && item[key] !== "");
          return found ? found[key] : null;
        };
      
        const commonRecord = {
          companyName: matchingRecords[0].companyName,  
          jobCode: matchingRecords[0].jobCode,         
          projectCode: matchingRecords[0].projectCode,  
          programmerName: matchingRecords[0].programmerName,  
          deadline: matchingRecords[0].deadline,  
          orderType:matchingRecords[0].orderType, 
          isOfferable :matchingRecords[0].isOfferable,
          isOffer :matchingRecords[0].isOffer,
          noOfferApprovedDescription: findFirstNonEmptyValue("noOfferApprovedDescription") ?? null,
          isOfferSetupUpdate: findFirstNonEmptyValue("isOfferSetupUpdate") ?? false,
        };
      
        commonRecord.data = matchingRecords;
      
        setRecord(commonRecord);
      
        setLoading(false);  
      } else {
        setLoading(false);  
      }
        
    };
  
    fetchData();
   

  }, []);
  
  
 //stok için
  const [isModalVisibleDelete, setIsModalVisibleDelete] = useState(false);
  const [inputValueDelete, setInputValueDelete] = useState(null);
  const [savedRecordDelete, setSavedRecordDelete] = useState(null);
  
  const [inputValueAdd, setInputValueAdd] = useState(null);
  const [isModalVisibleAdd, setIsModalVisibleAdd] = useState(false);
  const [savedRecordAdd, setSavedRecordAdd] = useState(null);

//wastage için
 const [isModalVisibleDeleteWastage, setIsModalVisibleDeleteWastage] = useState(false);
  const [inputValueDeleteWastage, setInputValueDeleteWastage] = useState(null);
  const [savedRecordDeleteWastage, setSavedRecordDeleteWastage] = useState(null);

  const [inputValueAddWastage, setInputValueAddWastage] = useState(null);
  const [isModalVisibleAddWastage, setIsModalVisibleAddWastage] = useState(false);
  const [savedRecordAddWastage, setSavedRecordAddWastage] = useState(null);

  
  const loadOpenOrderDetail = async () => {
    setLoading(true);
    try {
      const data = await FirebaseService.getOpenOrderData();
     
      let matchingRecords = data.filter(item => !item.isDeleted && item.jobCode === location.state?.record.jobCode.substring(0, 5) );
  
      if (matchingRecords.length > 0) {
        const findFirstNonEmptyValue = (key) => {
          const found = matchingRecords.find(item => item[key] !== null && item[key] !== undefined && item[key] !== "");
          return found ? found[key] : null;
        };
        const commonRecord = {
          companyName: matchingRecords[0].companyName,  
          jobCode: matchingRecords[0].jobCode,         
          projectCode: matchingRecords[0].projectCode,  
          programmerName: matchingRecords[0].programmerName,  
          deadline: matchingRecords[0].deadline,
          isOfferable :matchingRecords[0].isOfferable,
          isOffer :matchingRecords[0].isOffer,
          noOfferApprovedDescription: findFirstNonEmptyValue("noOfferApprovedDescription") ?? null,
          isOfferSetupUpdate: findFirstNonEmptyValue("isOfferSetupUpdate") ?? false,
       
        };
  
        commonRecord.data = matchingRecords;
        setRecord(matchingRecords)
        setLoading(false);

      } else {
        setLoading(false);

      }
    } catch (error) {
      console.error('Veriler yüklenirken bir hata oluştu:', error);
    } 
  };
  


  useEffect(() => {
    loadOpenOrderDetail();
  }, []);


  const goDelete = async (recordx, inputValue) => {
    let success = true;
    setLoading(true);
  
    const updateCombinedStockItems = async (stockItems, inputValue) => {
      const { id, isSendPurchase } = stockItems;
      try {
        if (isSendPurchase) {
          const resultPurchase = await FirebaseService.updateStockWaitingPieceRemove(id, inputValue);
          console.log("Result for item", id, ":", resultPurchase);
        } else {
          const result = await FirebaseService.updateStockReservedPieceRemove(id, inputValue);
          console.log("Result for item", id, ":", result);
        }
      } catch (error) {
        success = false;
        console.error(`Error updating stock item ${id}:`, error);
      }
    };

    await updateCombinedStockItems(recordx, inputValue);
    await FirebaseService.updateOpenOrderStockRemove(recordx.key, recordx,inputValue);

    try {
      const purchaseRequests = await FirebaseService.getPurchaseRequestData();
      const filteredPurchaseRequests = purchaseRequests.filter(item => {
        return !item.isDeleted &&
          item.requestedProductInfo &&
          item.requestedProductInfo.some(info => info.jobCode === record.jobCode && info.id === recordx.id);
        });

    
      if (filteredPurchaseRequests.length !== 0) {
        console.log("No matching purchase requests found. Exiting.");
         
      const requestedProductInfo = filteredPurchaseRequests[0].requestedProductInfo;

      const itemToUpdate = requestedProductInfo.find(item => item.id === recordx.id);
     
      //satın alma talebi silincek
      if (itemToUpdate.piece === inputValue) {
        console.log("satın alma talebi silincek")

        try {
          const result = await FirebaseService.deletePurchaseRequestData( filteredPurchaseRequests[0].key);
          if (result) {
            console.log(`Item with keydeleted successfully.`);
          } else {
            success = false;
            console.log(`Failed to delete item with key`);
          }
        } catch (error) {
          success = false;
          console.error(`Error deleting item with key `, error);
        }
      }
    
    //satın alma talebi update geçilcek
    else{
      
      const updatedProductInfo = filteredPurchaseRequests[0].requestedProductInfo.map(item => {
        if (item.id === recordx.id) {
          return { ...item, piece: Number(item.piece)- Number(inputValue) };
        }
        return item; 
      });
      
      // Update item'ı hazırla
      const updateItem = {
        id: recordx.id,
        piece: updatedProductInfo.find(item => item.id === recordx.id).piece,
      };
      
        const result = await FirebaseService.updatePurchaseDataRequestedProductInfo(filteredPurchaseRequests[0].key, updateItem);
  
        if(result){
         console.log("Satın alma talebi update geçildi")
        }
        else{       
          success = false;
          console.log("Error deleting item with key");
        }   
    }
      
      }
   
    } catch (error) {
      success = false;
      console.error("Error fetching or filtering purchase requests:", error);
    }

    if (success) {
      NotificationService.openSuccessNotification({
        title: 'İşlem Başarılı',
        description: 'Başarıyla silindi.',
        placement: 'topRight',
      });
      setTimeout(() => {
        window.location.reload();
      }, 10);
      setIsModalVisibleDelete(false);
      setLoading(false);

    } else {
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: 'Lütfen daha sonra tekrar deneyiniz.',
        placement: 'topRight',
      });
      setLoading(false); 

    }

  };

  const handleDelete = (record) => {
    setSavedRecordDelete(record);
    setIsModalVisibleDelete(true);  
  };

  const handleDeleteOk = async () => {
    setLoading(true);

    if(inputValueDelete < 0){
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: 'Eksi değer girilemez',
        placement: 'topRight'
      }); 
      setLoading(false);
     return;
    }
    if(savedRecordDelete.piece < inputValueDelete){
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: 'Çıkarılmak istenen sayı, rezerve edilen sayıdan büyük olamaz.',
        placement: 'topRight'
      }); 
      setLoading(false);
     return;
    }
    
    await goDelete(savedRecordDelete, inputValueDelete);
    
    
  };

  const handleCancelDelete = () => {
    setIsModalVisibleDelete(false);  
  };

  const handleAdd = (record) => {
    setSavedRecordAdd(record);
    setIsModalVisibleAdd(true);  
  };

  const handleAddOk= async () => {
    setLoading(true);
   
    if(inputValueAdd < 0){
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: 'Eksi değer girilemez',
        placement: 'topRight'
      }); 
      setLoading(false);
     return;
    }

    await goAdd(savedRecordAdd, inputValueAdd);
    setTimeout(() => {
      window.location.reload();
    }, 10);
    setIsModalVisibleAdd(false);
    setLoading(false); 
  };

  const handleCancelAdd = () => {
    setIsModalVisibleAdd(false);  
  };

  const goAdd = async (recordx, inputValue) => {
    let success = true;
    setLoading(true);
  
  
    const updateCombinedStockItems = async (stockItems, inputValue) => {

      const { id, isSendPurchase } = stockItems;
      try {
        if (isSendPurchase) {
          const resultPurchase = await FirebaseService.updateStockWaitingPiece(id, inputValue);
          //satın alma talebi update geçilcek
           const purchaseRequests = await FirebaseService.getPurchaseRequestData();
           const filteredPurchaseRequests = purchaseRequests.filter(item => {
             return !item.isDeleted &&
               item.requestedProductInfo &&
               item.requestedProductInfo.some(info => info.jobCode === record.jobCode && info.id === recordx.id);

           });
         
     
        const updatedProductInfo = filteredPurchaseRequests[0].requestedProductInfo.map(item => {
          if (item.id === recordx.id) {
            return { ...item, piece: Number(item.piece) + Number(inputValue) };
          }
          return item; 
        });
        
        const updateItem = {
          id: recordx.id,
          piece: updatedProductInfo.find(item => item.id === recordx.id).piece,
        };
        
          const result = await FirebaseService.updatePurchaseDataRequestedProductInfo(filteredPurchaseRequests[0].key, updateItem);
    
          if(result){
           console.log("Satın alma talebi update geçildi ekleme yapıldı")
          }
          else{       
            success = false;
            console.log("Error deleting item with key");
          }  
          console.log("Result for item", id, ":", resultPurchase);
        } else {
          const { id } = stockItems;
          const stockItem = await FirebaseService.getStockItemById(id);
          if (!stockItem) {
            console.error(`Stok öğesi bulunamadı: ${id}`);
            return;
          }
          const { piece } = stockItem;
          if (piece >= Number(inputValue)) {
            const result = await FirebaseService.updateStockReservedPiece(id, Number(inputValue));
          } else {
            //yeni satın alma talebi atılcak waitng eklencek
            NotificationService.openErrorNotification({
              title: 'İşlem Başarısız',
              description: 'Rezerve etmek istediğiniz sayı stokta mevcut değildir.',
              placement: 'topRight'
            }); 
            success = false;
          
           return;

          }
        }
        
      } catch (error) {
        success = false;
        console.error(`Error updating stock item ${id}:`, error);
      }


      
     
    };

    await updateCombinedStockItems(recordx, inputValue);

    if (success) {
      await FirebaseService.updateOpenOrderStockAdd(recordx.key, recordx,Number(inputValue));

      NotificationService.openSuccessNotification({
        title: 'İşlem Başarılı',
        description: 'Başarıyla eklendi.',
        placement: 'topRight',
      });

      setTimeout(() => {
        window.location.reload();
      }, 10);
        setIsModalVisibleDelete(false);
      setLoading(false);
    } else {
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: 'Lütfen daha sonra tekrar deneyiniz.',
        placement: 'topRight',
      });
    }
    setLoading(false); 
  };

  const columnsRaw = [
    {
      title: 'Depo',
      dataIndex: 'depotName',
      key: 'quality',
      width: 200,
      render: (text) => <span style={{ color: record.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
    },
    {
      title: 'Kalite',
      dataIndex: 'quality',
      key: 'quality',
      width: 200,
      render: (text) => <span style={{ color: record.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
    },

      {
        title: 'Kalınlık',
        dataIndex: 'thickness',
        key: 'thickness',
        width: 200,
        render: (text) => <span style={{ color: record.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
      },

     
      {
        title: 'En',
        dataIndex: 'width',
        key: 'width',
        width: 200,
        render: (text) => <span style={{ color: record.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
      },
      {
        title: 'Boy',
        dataIndex: 'height',
        key: 'height',
        width: 200,
        render: (text) => <span style={{ color: record.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
      },

      {
        title: 'Yüzey',
        dataIndex: 'surface',
        key: 'surface',
        width: 200,
        render: (text) => <span style={{ color: record.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
      },
 
      {
        title: 'Adet',
        dataIndex: 'piece',
        key: 'piece',
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000"}}>{text}</span>,
        width: 200,
      },
      {
        title : ' ',
        dataIndex : 'isSendPurchaseicon',
        key : 'isSendPurchaseicon',
  
        render: (text, record) => {
            return (
              <>
                {record.isSendPurchase ? (
                  <Tooltip title="Satın Alma Talebi Gönderildi">
                    <div >
                      <img width={20} height={20} alt="logo" src="/images/isSendPurchaseicon.png" style={{ paddingBottom: "5%" }} />
                    </div>
                  </Tooltip>
                ) : null
                }
              </>
            );
          },            
      },
      {
        title: ' ',
        dataIndex: 'add',
        key: 'add',
        render: (text, record) => {
          if (!record.isWaiting || record.isSetup) {
            return null;
          }
          return (
            <>
              <Popconfirm
                title="Ekleme istediğinize emin misiniz"
                
                onConfirm={() => handleAdd(record)}
                okText="Ekle"
                cancelText="Vazgeç"
              >
                <img
                  src="/images/edit.svg"
                  alt="Ekle"
                  style={{ cursor: 'pointer', width: '20px', height: '20px' }} 
                />
              </Popconfirm>
             
              <Modal
                title="Lütfen eklemek istediğiniz adeti giriniz"
                visible={isModalVisibleAdd}        
                onOk={() => handleAddOk(record)}  
                onCancel={handleCancelAdd}
                okText="Onayla"
                cancelText="İptal"
                 className="popup-modal-button"
              >
                <Input 
                  type="number" 
                  value={inputValueAdd} 
                  onChange={(e) => setInputValueAdd(e.target.value)} 
                  placeholder="Sayı giriniz"
                    className="input-style"
                />
                  {loading && (
                <div style={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: 1000,
                }}>
                  <Spin />
                </div>
              )}
              </Modal>
      
            
            </>
          );
        },
      }
      ,
      {
        title: ' ',
        dataIndex: 'delete',
        key: 'delete',
        render: (text, record) => {
          if (!record.isWaiting || record.isSetup) {
            return null; 
          }
          return (
            <>
              <Popconfirm
                title="Silmek istediğinize emin misiniz"
                onConfirm={() => handleDelete(record)}
                okText="Sil"
                cancelText="Vazgeç"
                
              >
                <img
                  src="/images/remove.svg"
                  alt="Delete"
                  style={{ cursor: 'pointer', width: '20px', height: '20px' }} 
                />
              </Popconfirm>
             
              <Modal
                title="Lütfen silmek istediğiniz rezerve sayısını girin"
                visible={isModalVisibleDelete}        
                onOk={() => handleDeleteOk(record)}  
                onCancel={handleCancelDelete}
                okText="Onayla"
                cancelText="İptal"
                className="popup-modal-button"
              >
                <Input 
                  type="number" 
                  value={inputValueDelete} 
                  onChange={(e) => setInputValueDelete(e.target.value)} 
                  placeholder="Sayı giriniz"
                  className="input-style"
                />
                  {loading && (
                <div style={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: 1000,
                }}>
                  <Spin />
                </div>
              )}
              </Modal>
      
            
            </>
          );
        },
      },
    
      
  ];

  const handleAddWastage = (record) => {
    setSavedRecordAddWastage(record);
    setIsModalVisibleAddWastage(true);  
  };

  const handleAddOkWastage= async () => {
    setLoading(true);
   
    if(inputValueAddWastage < 0){
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: 'Eksi değer girilemez',
        placement: 'topRight'
      }); 
      setLoading(false);
     return;
    }

    await goAddWastage(savedRecordAddWastage, inputValueAddWastage);
    setTimeout(() => {
      window.location.reload();
    }, 10);
    setIsModalVisibleAddWastage(false);
    setLoading(false); 
  };

  const handleCancelAddWastage = () => {
    setIsModalVisibleAddWastage(false);  
  };

  const goAddWastage = async (recordx, inputValue) => {
    let success = true;
    setLoading(true);
   
    const updateCombinedWastageItems = async (wastageItems, inputValue) => {
   
          const { id } = wastageItems;
          const wastageItem = await FirebaseService.getWastageItemById(id);
          if (!wastageItem) {
            console.error(`wastage öğesi bulunamadı: ${id}`);
            return;
          }
          const { piece } = wastageItem;
          if (piece >= Number(inputValue)) {
            const result = await FirebaseService.updateWastageReservedPiece(id, inputValue);
          
          } else {
            
            NotificationService.openErrorNotification({
              title: 'İşlem Başarısız',
              description: 'Rezerve etmek istediğiniz sayı firede mevcut değildir.',
              placement: 'topRight'
            }); 
            success = false;
           return;

          } 
      
    }
   
    await updateCombinedWastageItems(recordx, inputValue);

    if (success) {
      await FirebaseService.updateOpenOrderWastageAdd(record.key, recordx,Number(inputValue));

      NotificationService.openSuccessNotification({
        title: 'İşlem Başarılı',
        description: 'Başarıyla eklendi.',
        placement: 'topRight',
      });
    } else {
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: 'Lütfen daha sonra tekrar deneyiniz.',
        placement: 'topRight',
      });
      setLoading(false); 
    }
    
};


const goDeleteWastage = async (recordx, inputValue) => {
  let success = true;
  setLoading(true);

  const updateCombinedWastageItems = async (wastageItems, inputValue) => {
    const { id } = wastageItems;
    const result = await FirebaseService.updateWastageReservedPieceRemove(id, inputValue);
  }
 
  await updateCombinedWastageItems(recordx, inputValue);

  if (success) {
   const result= await FirebaseService.updateOpenOrderWastageRemove(recordx.key, recordx,Number(inputValue));
    NotificationService.openSuccessNotification({
      title: 'İşlem Başarılı',
      description: 'Başarıyla çıkarıldı.',
      placement: 'topRight',
    });
    setTimeout(() => {
      window.location.reload();
    }, 10);

  } else {
    NotificationService.openErrorNotification({
      title: 'İşlem Başarısız',
      description: 'Lütfen daha sonra tekrar deneyiniz.',
      placement: 'topRight',
    });
    setLoading(false); 
  }
  
};

const handleDeleteWastage = (record) => {
  setSavedRecordDeleteWastage(record);
  setIsModalVisibleDeleteWastage(true);  
};

const handleDeleteOkWastage = async () => {
  setLoading(true);

  if(inputValueDeleteWastage < 0){
    NotificationService.openErrorNotification({
      title: 'İşlem Başarısız',
      description: 'Eksi değer girilemez',
      placement: 'topRight'
    }); 
    setLoading(false);
   return;
  }
  if(savedRecordDeleteWastage.piece < inputValueDeleteWastage){
    NotificationService.openErrorNotification({
      title: 'İşlem Başarısız',
      description: 'Çıkarılmak istenen sayı, rezerve edilen sayıdan büyük olamaz.',
      placement: 'topRight'
    }); 
    setLoading(false);
   return;
  }
  await goDeleteWastage(savedRecordDeleteWastage, inputValueDeleteWastage);
 
};

const handleCancelDeleteWastage = () => {
  setIsModalVisibleDeleteWastage(false);  
};


  const columnsWastage = [
    {
      title: 'Depo',
      dataIndex: 'depotName',
      key: 'quality',
      width: 200,
      render: (text) => <span style={{ color: record.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
    },
    {
      title: 'Kalite',
      dataIndex: 'quality',
      key: 'quality',
      width: 200,
      render: (text) => <span style={{ color: record.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
    },

      {
        title: 'Kalınlık',
        dataIndex: 'thickness',
        key: 'thickness',
        width: 200,
        render: (text) => <span style={{ color: record.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
      },

     
      {
        title: 'En',
        dataIndex: 'width',
        key: 'width',
        width: 200,
        render: (text) => <span style={{ color: record.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
      },
      {
        title: 'Boy',
        dataIndex: 'height',
        key: 'height',
        width: 200,
        render: (text) => <span style={{ color: record.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
      },

      {
        title: 'Yüzey',
        dataIndex: 'surface',
        key: 'surface',
        width: 200,
        render: (text) => <span style={{ color: record.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
      },
 
      {
        title: 'Adet',
        dataIndex: 'piece',
        key: 'piece',
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000"}}>{text}</span>,
        width: 200,
      },
     
      {
        title: ' ',
        dataIndex: 'add',
        key: 'add',
        render: (text, record) => {
        
          return (
            <>
              <Popconfirm
                title="Ekleme istediğinize emin misiniz"
                
                onConfirm={() => handleAddWastage(record)}
                okText="Ekle"
                cancelText="Vazgeç"
              >
                <img
                  src="/images/edit.svg"
                  alt="Ekle"
                  style={{ cursor: 'pointer', width: '20px', height: '20px' }} 
                />
              </Popconfirm>
             
              <Modal
                title="Lütfen eklemek istediğiniz adeti giriniz"
                visible={isModalVisibleAddWastage}        
                onOk={() => handleAddOkWastage(record)}  
                onCancel={handleCancelAddWastage}
                okText="Onayla"
                cancelText="İptal"
                 className="popup-modal-button"
              >
                <Input 
                  type="number" 
                  value={inputValueAddWastage} 
                  onChange={(e) => setInputValueAddWastage(e.target.value)} 
                  placeholder="Sayı giriniz"
                    className="input-style"
                />
                  {loading && (
                <div style={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: 1000,
                }}>
                  <Spin />
                </div>
              )}
              </Modal>
      
            
            </>
          );
        },
      }
      ,
      {
        title: ' ',
        dataIndex: 'delete',
        key: 'delete',
        render: (text, record) => {
        
          return (
            <>
              <Popconfirm
                title="Silmek istediğinize emin misiniz"
                onConfirm={() => handleDeleteWastage(record)}
                okText="Sil"
                cancelText="Vazgeç"
                
              >
                <img
                  src="/images/remove.svg"
                  alt="Delete"
                  style={{ cursor: 'pointer', width: '20px', height: '20px' }} 
                />
              </Popconfirm>
             
              <Modal
                title="Lütfen silmek istediğiniz rezerve sayısını girin"
                visible={isModalVisibleDeleteWastage}        
                onOk={() => handleDeleteOkWastage(record)}  
                onCancel={handleCancelDeleteWastage}
                okText="Onayla"
                cancelText="İptal"
                className="popup-modal-button"
              >
                <Input 
                  type="number" 
                  value={inputValueDeleteWastage} 
                  onChange={(e) => setInputValueDeleteWastage(e.target.value)} 
                  placeholder="Sayı giriniz"
                  className="input-style"
                />
                  {loading && (
                <div style={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: 1000,
                }}>
                  <Spin />
                </div>
              )}
              </Modal>
      
            
            </>
          );
        },
      },
    
      
  ];

  const goDeleteOpenOrder = async (deleteItem) => {
    let success = true;
    setLoading(true);
  
    try {
      // Open Order tablosundaki ilgili teklifleri kriterlere göre silme (isDeleted true yapma)
        const allOpenOrder = await FirebaseService.getOpenOrderData();
        const filteredOpenOrder = allOpenOrder.find(item => 
          !item.isDeleted && item.isWaiting && item.jobCode === deleteItem.jobCode
        );
    
        if (filteredOpenOrder) {
          if(deleteItem.orderType ==="offer"){
            const resultOpenOrder = await FirebaseService.deleteOpenOrderDataIsDeleted(filteredOpenOrder.key);
            if (!resultOpenOrder) throw new Error("Open order silinemedi.");
          }
          else{
              const updateOpenOrderItem = {
                isSetup: false,
                setupName:null,
                machine:null
              };
              const updateResult = await FirebaseService.updateOpenOrder(
                filteredOpenOrder.key,
                updateOpenOrderItem
              );
              if (!updateResult) throw new Error("Open order silinemedi.");
           }
        }  

      
      // Setup verisinin tamamen silinmesi
      const setupData = await FirebaseService.getSetupData();
      const filteredSetup = setupData.find(item => 
        item.jobCode === deleteItem.jobCode
      );
  
      if (filteredSetup) {
        const resultSetup = await FirebaseService.deleteSetupData(filteredSetup.key);
        if (!resultSetup) throw new Error("Setup silinemedi.");
      }
  
         // Setup verisinin tamamen silinmesi
       const contractManufacturingData = await FirebaseService.getContractManufacturingData();
         const filteredContractManufacturingData = contractManufacturingData.find(item => 
           item.jobCode === deleteItem.jobCode
         );
     
         if (filteredContractManufacturingData) {
           const resultContractManufacturingData = await FirebaseService.deleteContractManufacturingData(filteredContractManufacturingData.key);
           if (!resultContractManufacturingData) throw new Error("Contract manufactring silinemedi.");
         }
      // İş kodu verisinin tamamen silinmesi
      const jobCodes = await FirebaseService.getJobCodesData();
      const filteredJobCodes = jobCodes.find(item => 
        item.jobCode === deleteItem.jobCode && !item.isDeleted
      );
  
      if (filteredJobCodes) {
        const resultJobCode = await FirebaseService.deleteJobCodeData(filteredJobCodes.key);
        if (!resultJobCode) throw new Error("İş kodu silinemedi.");
      }
       // Parça verisinin silinmesi
       const partsData = await FirebaseService.getPartsData();
       const filteredParts = partsData.filter(
         (item) => !item.isDeleted && item.jobCode === deleteItem.jobCode
       );
 
       await Promise.all(
         filteredParts.map(async (itemx) => {
           await FirebaseService.deletePartData(itemx.key);
         })
       );
  
       //wastage sil
       if(deleteItem.orderType==="order"){
        const wastageData = await FirebaseService.getWastagesData();
        const filteredWastage = wastageData.find(item => 
          item.jobCode === deleteItem.jobCode && !item.isDeleted
        );
    
        if (filteredWastage) {
          const resultWastage = await FirebaseService.deleteWastage(filteredWastage.key);
          if (!resultWastage) throw new Error("Fire silinemedi.");
        }
 
       }
       

      NotificationService.openSuccessNotification({
        title: 'İşlem Başarılı',
        description: 'Başarıyla silindi.',
        placement: 'topRight'
      });
    
      
      if (success) {
        setTimeout(() => {
          window.location.reload();
        }, 10);

     }
      
    } catch (error) {
      success = false;
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: error.message || 'Teklif silme işlemi başarısız oldu.',
        placement: 'topRight'
      });
    } finally {
      setLoading(false);
    }
  };


  const goDeleteOpenOrderFromOpenOrderTable = async (deleteItem) => {
    setLoading(true);
  
    try {
      const resultOpenOrder = await FirebaseService.deleteOpenOrderDataIsDeleted(deleteItem.key);
      if (!resultOpenOrder) {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Silme işlemi başarısız oldu",
          placement: 'topRight'
        });
        setLoading(false);
      }   
      else{
        NotificationService.openSuccessNotification({
          title: 'İşlem Başarılı',
          description: 'Başarıyla silindi.',
          placement: 'topRight'
        });
        setTimeout(() => {
          window.location.reload();
        }, 10);
      }
  
    } catch (error) {
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: error.message || 'Silme işlemi başarısız oldu.',
        placement: 'topRight'
      });
    } finally {
      setLoading(false);
    }
  };
   
   return ( 
    <>
      {loading ? (
    
                        <div style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 1000,
                        }}>
                            <Spin/>
                        </div>
                   
          ) : (

    <div style={{ display: "flex", flexDirection: "column",padding:"10px 100px"}}>
    
        <h2 style={{ fontWeight: "bold", color: colors.blue, textAlign:"left" }}> Bekleyen Sipariş Detay</h2>
            
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
            <p style={{ fontWeight: "bold", color: colors.blue }}>Müşteri Firma</p>
            {record && record.companyName}
          </div>

          <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
            <p style={{ fontWeight: "bold", color: colors.blue }}>İş Kodu</p>
            {record && record.jobCode ? record.jobCode.substring(0, 5) : "Belirtilmemiş"}
          </div>

          <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
            <p style={{ fontWeight: "bold", color: colors.blue }}>Termin Tarihi</p>
            {record && record.deadline && typeof record.deadline === 'object' ?
              new Date(record.deadline.seconds * 1000).toLocaleDateString('tr-TR') :
              record.deadline
            }
          </div>
        {record.orderType === "order" ?
        <> 
        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          {
            Array.isArray(record.data) && record.data.some(item => item.isSetup) && (
              <Button
                style={{ backgroundColor: colors.blue, color: "white", fontWeight: "500" }}
                onClick={() => handleAllGoOpenOrder(record)}>
                Tümünü Açık Siparişe Geçir
              </Button>
            )
          }


           <Button
              style={{ backgroundColor: colors.blue, color: "white", fontWeight: "500", marginRight: "5px" }}
               onClick={() => handleOpenOrderContinue(record)}
              >
               Stok Seçimi Düzenle
           </Button>
          </div>
            </>
            :
            <>
             {record.isOfferSetupUpdate && (
            <div  style={{ textAlign: "right",marginLeft:"10px" }} >
            <Button
              style={{
                backgroundColor: "#9D321A",
                color: "white",
                fontWeight: "500",
                float:"right",
                marginRight:"100px",
                display : record.orderType === "offer" && record.noOfferApprovedDescription !=null ? "flex" : "none"
              }}  
              onClick={() => handleRequestRemoval(record)}
              >
                Güncelleme Detay
            </Button>

            <Modal
              title="Güncelleme Detay"
              visible={isModalVisible}
              onCancel={handleModalCancel}
              footer={null}
            >
              <WaitingOpenOrderUpdateDetail  record={recordForModal}  onClose={handleRequestRemovalFinish} />
            </Modal>
          </div>

            )}


    
            {!record.isOffer &&  !record.isOfferable && (
              <>
                <Button
                  style={{
                    backgroundColor: colors.blue,
                    color: "white",
                    fontWeight: "500",
                    marginRight: "5px",
                  }}
                  onClick={() => handleSetup(record)}
                >
                  Setup Yükle
                </Button>
                <Modal
                  title="Setup Yükle"
                  visible={isModalVisibleSetup}
                  onCancel={handleModalCancelSetup}
                  footer={null}
                >
                  {recordForModalSetup && (
                    <SetupAdd recordx={recordForModalSetup} onClose={handleModalCancelSetup} />
                  )}
                </Modal>
              </>
            )}
          </>
          
              }

          {
            !record.isOffer && record.orderType ==="offer" &&  Array.isArray(record.data) && record.data.some(item => item.isSetup) && (
              record.isOfferable ? (
                <Button
                style={{ backgroundColor: colors.red, color: "white", fontWeight: "500" }}
                onClick={() => handleWithdrawOffer(record)}>
                Teklifi Geri Çek
              </Button>
              ) : (
                <Button
                style={{ backgroundColor: colors.blue, color: "white", fontWeight: "500" }}
                onClick={() => handleAllGoOffer(record)}>
                Teklife Dönüştür
                </Button>
              )
            )
          }

                  
        

        </div>

      {record.jobCode?.length !== 5 ? (
        <>
           <div style={{marginTop:"50px"}}>
          {record.data
            ?.sort((a, b) => {
              const getJobCodeNumber = (jobCode) => {
                const parts = jobCode.split("-");
                return parts[1] ? parseInt(parts[1]) : 0; 
              };
    
              const aNumber = getJobCodeNumber(a.jobCode);
              const bNumber = getJobCodeNumber(b.jobCode);
    
              return aNumber - bNumber; 
            })
          .map((item, index) => (
          <div key={index}>
            <div style={{ padding: "10px", display: "flex", alignItems: "center" }}>
          
              <Button
                style={{ backgroundColor: colors.white, color:colors.blue, fontWeight:"bold", border:"1px solid", borderColor:colors.blue}}
                onClick={() => toggleExpand(index)}
              >
                {item.jobCode} 
                {item.thickness && (
                  <span style={{ marginRight: "8px", fontWeight: "bold" }}>
                    {item.thickness}
                  </span>
                )}
               {record.orderType === "order" && (
                  expandedItems[index] ? (
                    <UpOutlined style={{ fontSize: "16px", color: colors.blue }} />
                  ) : (
                    <DownOutlined style={{ fontSize: "16px", color: colors.blue }} />
                  )
                )}

              
              </Button>
              <div style={{ textAlign: "right",marginLeft:"10px" }}>
                  {item.orderType === "order" && !item.isSetup && item.isWaiting && (
                    <>
                      <Button
                        style={{ backgroundColor: colors.blue, color: "white", fontWeight: "500", marginRight: "5px" }}
                        onClick={() => handleSetup(item)}
                      >
                        Setup Yükle
                      </Button>
                      <Modal
                        title="Setup Yükle"
                        visible={isModalVisibleSetup}
                        onCancel={handleModalCancelSetup}
                        footer={null}
                      >
                       {recordForModalSetup && ( 
                          <SetupAdd recordx={recordForModalSetup} onClose={handleModalCancelSetup} />
                        )}
                      </Modal>
                      <Button
                        style={{ backgroundColor: colors.blue, color: "white", fontWeight: "500", marginRight: "5px" }}
                        onClick={() => handleOpenOrderContinue(item)}
                      >
                        Stok Seçimi Düzenle
                      </Button>
                      
                    </>
                  )}
                </div>
                <div style={{ textAlign: "right", marginLeft: "10px", position: "relative" }}>
                  {item.orderType === "order" && item.mergedJobCodes && (
                    <>
                      <div
                        style={{
                        
                          textAlign: "left",
                          display: "flex",
                          alignItems: "center",
                          color: colors.blue,
                          border: "1px solid",
                          borderColor: colors.blue,
                          borderRadius: "5px",
                          padding: "5px",
                          cursor: "pointer",
                        }}
                        onClick={toggleVisibility}
                      >
                        <Tooltip title="Bu iş başka işlerle birleştirilmiştir">
                          <InfoCircleOutlined style={{ fontSize: "18px", marginRight: "8px" }} />
                        </Tooltip>
                        <p style={{ margin: 0, fontWeight: "500" }}>Birleşen İşler</p>
                      </div>

                      {isExpanded && (
                        <div
                          style={{
                            position: "absolute",
                            bottom: "calc(100% + 5px)", // Position above the icon
                            left: "0",
                            backgroundColor: "white",
                            border: `1px solid ${colors.blue}`,
                            borderRadius: "5px",
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                            padding: "10px",
                            zIndex: 1000,
                            minWidth: "200px",
                          }}
                        >
                          <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
                            {item.mergedCompanyNames.map((company, index) => (
                              <li key={index} style={{ marginBottom: "5px" }}>
                            {item.mergedJobCodes[index] || "N/A"} - {company} 
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div style={{ textAlign: "right",marginLeft:"10px" }}>
                  {item.orderType === "order" && item.isSetup && item.isWaiting && (
                    <>
                   <Button
                        style={{ backgroundColor: colors.white, color: colors.blue, fontWeight: "500", borderColor:colors.blue,marginRight:"10px"}}
                        disabled
                      >
                     Setup İsmi : {item.setupName}
                      </Button>

                      <Button
                        style={{ backgroundColor: colors.white, color: colors.blue, fontWeight: "500", borderColor:colors.blue,marginRight:"10px"}}
                        onClick={() => goDeleteOpenOrder(item)}
                         

                     >
                    Setup Sil 
                      </Button> 
                      <Button
                        style={{ backgroundColor: colors.blue, color: "white", fontWeight: "500" }}
                        onClick={() => handleGoOpenOrder(item)}
                      >
                        Açık Siparişe Geçir
                      </Button>

                      
                    </>
                  )}
                </div>
                <div style={{ textAlign: "right",marginLeft:"10px" }}>
                  {item.orderType === "order" && !item.isSetup && item.isWaiting && item.stock?.length ===0  && item.wastage?.length ===0 &&  (
                    <>
                      <Button
                        style={{ backgroundColor: colors.white, color: colors.blue, fontWeight: "500", borderColor:colors.blue,marginRight:"10px"}}
                        onClick={() => goDeleteOpenOrderFromOpenOrderTable(item)}
                     >
                    Açık Siparişi Sil 
                      </Button> 
                    

                      
                    </>
                  )}
                </div>

                <div style={{ textAlign: "right",marginLeft:"10px" }}>
                  {item.orderType === "order" && item.isSetup && !item.isWaiting && (
                    <>
                   <Button
                        style={{ backgroundColor: colors.white, color: colors.blue, fontWeight: "500", borderColor:colors.blue,marginRight:"10px"}}
                        disabled
                      >
                      Açık Siparişe Geçirilmiştir
                      </Button>

                   
                    </>
                  )}
                </div>

                <div style={{ textAlign: "right",marginLeft:"10px" }}>
                  {item.orderType === "offer" && item.isSetup && item.isWaiting && (
                    <>
                   <Button
                        style={{ backgroundColor: colors.white, color: colors.blue, fontWeight: "500", borderColor:colors.blue,marginRight:"10px"}}
                        disabled
                      >
                     Setup İsmi : {item.setupName}
                      </Button>

                   
                        
                    </>
                  )}
                </div>

                <div style={{ textAlign: "right", marginLeft: "10px" }}>
                  {(
                  (item.orderType === "offer" &&
                    item.isSetup &&
                    item.isWaiting &&
                    !item.isOfferApproved &&
                    !item.isOffer &&
                    !item.isOfferable) ||
                  (item.orderType === "offer" && item.isOfferSetupUpdate && !item.isOfferable)
                ) && (
                  <>
                    <Button
                      style={{
                        backgroundColor: colors.white,
                        color: colors.blue,
                        fontWeight: "500",
                        borderColor: colors.blue,
                        marginRight: "10px",
                      }}
                      onClick={() => goDeleteOpenOrder(item)}
                    >
                      Setup Sil
                    </Button>
                  </>
                )}
              </div>



          


             
            </div>

            {expandedItems[index] &&  item.orderType === "order"  && (
              <div style={{ padding: "10px" }}>
                {item.stock?.length > 0 ? (
                  <>
                    <div style={{ fontWeight: "bold", color: colors.blue, marginTop: "10px" }}>
                      Seçilen Hammadeler
                    </div>
                    <Table
                      style={{ width: "70%", margin: "auto" }}
                      locale={{ emptyText: "Seçilen hammade bulunmamaktadır..." }}
                      dataSource={item.stock.map(stockItem => ({
                        ...stockItem,
                        jobCode: item.jobCode,
                        key: item.key,
                        isWaiting : item.isWaiting,
                        isSetup: item.isSetup
                      }))}
                      columns={columnsRaw}
                      showHeader={true}
                      pagination={false}
                      className="custom-news-table"
                      rowClassName="custom-news-row"
                    />
                  </>
                ) : (
                  <div style={{ fontWeight: "bold", color: "gray", marginTop: "10px" }}>
                    Seçilen hammade bulunmamaktadır...
                  </div>
                )}

                {item.wastage?.length > 0 ? (
                  <>
                    <div style={{ fontWeight: "bold", color: colors.blue, marginTop: "10px" }}>
                      Seçilen Fireler
                    </div>
                    <Table
                      style={{ width: "70%", margin: "auto" }}
                      locale={{ emptyText: "Seçilen fire bulunmamaktadır..." }}
                      dataSource={item.wastage.map(wastageItem => ({
                        ...wastageItem,
                        jobCode: item.jobCode,
                        key: item.key,
                        isWaiting : item.isWaiting,
                        isSetup: item.isSetup
                      }))}
                      columns={columnsWastage}
                      showHeader={true}
                      pagination={false}
                      className="custom-news-table"
                      rowClassName="custom-news-row"
                    />
                  </>
                ) : (
                  <div style={{ fontWeight: "bold", color: "gray", marginTop: "10px" }}>
                    Seçilen fire bulunmamaktadır...
                  </div>
                )}
              </div>
            )}
          </div>
          ))}
        </div>
        </>
      ) : (
        <></>
      )}


    
    </div>
    
       )}
         {loading && (
                       <div style={{
                           position: 'fixed',
                           top: 0,
                           left: 0,
                           width: '100%',
                           height: '100%',
                           backgroundColor: 'rgba(0, 0, 0, 0.5)',
                           display: 'flex',
                           alignItems: 'center',
                           justifyContent: 'center',
                           zIndex: 1000,
                       }}>
                           <Spin/>
                       </div>
                   )}
   </>
   
  )};
export default WaitingOpenOrderDetail;

/*
    <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>Satış Sözleşmesi</p>
        {fileURL ? (
          <>
            <Button href={fileURL} target="_blank" rel="noopener noreferrer" style={{border: `2px solid #2B7199`,color:colors.blue,fontWeight:"bold"}}>
              İndir
            </Button>
          
          </>
        ) : (
          <p>-</p> 
        )}
        
          </div>



            {record.isOfferApproved && (
            <Button style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}} onClick={() => handleOpenOrderContinue(record)} >
              Siparişe Başla
            </Button>
          )}



            {(record.orderType === "offer" && !record.isSetup && record.isWaiting && !record.isOfferApproved ) && (
            <>
            <Button style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}} onClick={() => handleSetup(record)} >
              Setup Yükle
            </Button>
              <Modal
              title="Setup Yükle"
              visible={isModalVisibleSetup}
              onCancel={handleModalCancelSetup}
              footer={null}
            >
              <SetupAdd  recordx={recordForModalSetup}  onClose={handleFinishSetup} />
            </Modal></>
          
          )}

             {record.isOfferSetupUpdate && (
            <>
            <Button style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}} onClick={() => handleSetup(record)} >
              Setup Yükle
            </Button>
              <Modal
              title="Setup Yükle"
              visible={isModalVisibleSetup}
              onCancel={handleModalCancelSetup}
              footer={null}
            >
              <SetupAdd  recordx={recordForModalSetup}  onClose={handleFinishSetup} />
            </Modal></>
          
          )}
              <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
            <p style={{ fontWeight: "bold", color: colors.blue }}>Durum</p>
            {record && record.status}
          </div>

 

          
*/