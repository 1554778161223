import React,{useEffect,useState} from "react";
import { Table, Button,Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../../services/firebaseService";
import  { colors}  from "../../../../environments/environment"


const WaitingOfferOpenOrderListLimited = () => {
  const [items, setItems] = useState([]);
  const [itemsLimited, setItemsLimited] = useState([]);


  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
   
    
    fetchData();
  }, []);

  function isEqual(obj1, obj2) {
    // Tüm özelliklerin eşit olup olmadığını karşılaştır
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }
 
  function pad(num) {
    return num.toString().padStart(2, '0');
  }
  function addTimes(time1, time2) {
    const [h1, m1, s1] = time1.split(':').map(Number);
    const [h2, m2, s2] = time2.split(':').map(Number);
  
    let seconds = s1 + s2;
    let minutes = m1 + m2;
    let hours = h1 + h2;
  
    if (seconds >= 60) {
      seconds -= 60;
      minutes++;
    }
    if (minutes >= 60) {
      minutes -= 60;
      hours++;
    }
  
    return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
  }
  const fetchData = async () => {
    const data = await FirebaseService.getOpenOrderData();
    const filteredData = data.filter(item => !item.isDeleted && item.isWaiting && item.orderType === "offer" && !item.isOfferApproved && item.setupName != null && !item.isOffer && item.isOfferable);
    const setupData = await FirebaseService.getSetupData();

    const updatedData = [];

    filteredData.forEach(item => {
      const setupItems = setupData.filter(setup => setup.jobCode === item.jobCode);
      if (setupItems.length > 0) {
       
         const addedJobCodes = new Set();
    
        setupItems.forEach(setupItem => {
          // Aynı jobCode ve firstTransactionDate zaten eklenmişse ekleme
          const isDuplicate = updatedData.some(updatedItem => (
            updatedItem.jobCode === setupItem.jobCode &&
            isEqual(updatedItem.firstTransactionDate, setupItem.firstTransactionDate)
          ));
  
          if (!isDuplicate) {
            const updatedItem = { ...item, ...setupItem };
            updatedData.push(updatedItem);
            addedJobCodes.add(setupItem.jobCode);
          }


          
        });
      } 
      else if(setupItems.length === 0){
        console.log("setupı yok")
      }
       else {
        updatedData.push(item);
      }
      
    });
    
    
    
      const groupedByJobCodePrefix = updatedData.reduce((acc, item) => {
      const prefix = item.jobCode.split('-')[0];
      if (!acc[prefix]) {
        acc[prefix] = []; 
      }
      acc[prefix].push(item);
      return acc;
    }, {});
  
    const uniqueLimitedData = Object.entries(groupedByJobCodePrefix).map(([key, value]) => {
    const sampleItem = value[0]; 


 const mergedItem = value.reduce((acc, item) => {
  return {
    ...acc,
    programmerNames: [...acc.programmerNames, item.programmerName],
    totalKgs: acc.totalKgs + item.totalKg,
    setupTimes: {
      ...acc.setupTimes,
      [`${item.machine}-${item.fuel}`]: acc.setupTimes[`${item.machine}-${item.fuel}`]
        ? addTimes(acc.setupTimes[`${item.machine}-${item.fuel}`], item.setupTime)
        : item.setupTime,
    },
    totalKg: {
      ...acc.totalKg,
      [item.quality]: acc.totalKg[item.quality] ? acc.totalKg[item.quality] + item.totalKg : item.totalKg,
    },
    deadlines: [...acc.deadlines, item.deadline],
    parts: [...acc.parts, ...item.parts],
  };
}, { programmerNames: [], totalKgs: 0, setupTimes: {}, totalKg: {}, deadlines: [], parts: [] });


      return {
        firstTransactionDate: sampleItem.firstTransactionDate,
        firstUserId: sampleItem.firstUserId,
        programmerUserId:sampleItem.programmerUserId,
        noOfferApprovedDescription:sampleItem.noOfferApprovedDescription,
        jobCode: key,    
        isDeleted: sampleItem.isDeleted,
        companyName: sampleItem.companyName,
        projectCode: sampleItem.projectCode,
        isOffer: sampleItem.isOffer,
        isOfferApproved: sampleItem.isOfferApproved,
        isOfferSetupUpdate: sampleItem.isOfferSetupUpdate,
        isOfferChange: sampleItem.isOfferChange,
        isOfferDenied: sampleItem.isOfferDenied,
        orderType: sampleItem.orderType,
        isWaiting: sampleItem.isWaiting,
        isSetup: sampleItem.isSetup,
        isChangedProgrammer: sampleItem.isChangedProgrammer,
        programmerName:mergedItem.programmerNames,
        totalKgs: mergedItem.totalKgs,
        setupTime: mergedItem.setupTimes,
        totalKg: mergedItem.totalKg,
        deadline: mergedItem.deadlines, 
        parts: mergedItem.parts, 

        items: value.map(item => ({
          ...item,
        })),
      };
    });


  if (uniqueLimitedData) {
    let updatedItems = uniqueLimitedData.map(item => {
      let newStatus = "Unknown";
      if (item.isWaiting && !item.isSetup && item.orderType === "order") {
        newStatus = "Setup Bekleniyor";
      } else if (item.isWaiting && !item.isSetup && item.orderType === "offer") {
        newStatus = "Setup Bekleniyor";
      } else if (item.isWaiting && item.isSetup && item.orderType === "offer" && !item.isOffer) {
        newStatus = "Teklif Bekleniyor";
      } else if (item.isWaiting && item.isSetup && item.orderType === "offer" && item.isOffer && !item.isOfferApproved && !item.isOfferChange && !item.isOfferSetupUpdate && !item.isOfferDenied) {
        newStatus = "Teklif Verildi";
      } else if (item.isWaiting && item.isSetup && item.orderType === "offer" && item.isOffer && item.isOfferApproved) {
        newStatus = "Onaylandı";
      } else if (item.isWaiting && item.isSetup && item.orderType === "offer" && item.isOffer && !item.isOfferApproved && item.isOfferChange && !item.isOfferSetupUpdate && !item.isOfferDenied) {
        newStatus = "Onaylanmadı,Teklif Değişikliği";
      } else if (item.isWaiting && item.isSetup && item.orderType === "offer" && item.isOffer && !item.isOfferApproved && !item.isOfferChange && item.isOfferSetupUpdate && !item.isOfferDenied) {
        newStatus = "Onaylanmadı,Setup Düzenleme";
      } else if (item.isWaiting && item.isSetup && item.orderType === "offer" && item.isOffer && !item.isOfferApproved && !item.isOfferChange && !item.isOfferSetupUpdate && item.isOfferDenied) {
        newStatus = "Onaylanmadı,Reddedildi";
      }
      
      return { ...item, status: newStatus };
    });
    updatedItems.sort((a, b) => {
      // "Onaylanmadı, Reddedildi" durumu en altta göstermek için özel sıralama
      if (a.status === "Onaylanmadı,Reddedildi" && b.status !== "Onaylanmadı,Reddedildi") {
        return 1; // a'yı sonra göster
      }
      if (a.status !== "Onaylanmadı,Reddedildi" && b.status === "Onaylanmadı,Reddedildi") {
        return -1; // a'yı önce göster
      }
  
      // Durumlar aynıysa tarihe göre ters sırala
      const dateA = new Date(a.firstTransactionDate);
      const dateB = new Date(b.firstTransactionDate);
      return dateB - dateA; // En son tarih en üstte olacak şekilde sırala
    });
  
  
    setItems(updatedItems);
    setItemsLimited(updatedItems)
  }
  
  };
  const goPage = (record) => {
    navigate('/waitingofferopenorderlist', { state: { record } })
  };

  const columns = [
    {
      title: 'Müşteri Firma',
      dataIndex: 'companyName',
      key: 'companyName',
      
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
  },
    {
      title: 'İş Kodu',
      dataIndex: 'jobCode',
      key: 'jobCode',
    
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text}
      </span>,
    },
    {
        title: 'Proje Kodu',
        dataIndex: 'projectCode',
        key: 'projectCode',
       
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text ? text : "-"}
        </span>,
      },
    {
      title: 'Durum',
      dataIndex: 'status',
      key: 'status',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text}</span>

    },
   /*   
    {
        title: ' ',
        dataIndex: 'edit',
        key: 'edit',
        render: (text, record) => (
          <Button onClick={()=>goEditPage(record)} style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}}>
          Detayı Gör
       </Button>
        ),
      }*/
  ];

  return  <div style={{  
       // width:"50%",
       
      }}
      className="limited-list-border">
      <Button onClick={()=>goPage(items)} className="title-limited-list">
         Bekleyen Teklifler
       </Button>

       <Table
            locale={{
              emptyText: 'Henüz bekleyen teklif bulunmamaktadır...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
            dataSource={itemsLimited}
            columns={columns}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />
      
     
    </div>

  };
  export default WaitingOfferOpenOrderListLimited;

    