import React,{useEffect,useState} from "react";
import { Button, Form, Input,Select,DatePicker, Upload,Modal} from 'antd';
import NotificationService from "../../../services/antNotificationService";
import FirebaseService from "../../../services/firebaseService";
import  { parsCollections, colors,openOrderType}  from "../../../environments/environment"
import { useNavigate } from 'react-router-dom';
import OpenOrderAdd from "./openOrderAdd";
import OpenOrderSecond from "./openOrderSecond";
import OpenOrderSecondOffer from "./openOrderSecondOffer";
import OpenOrderMergeJobCodes from "./mergeJobCodes/openOrderMergeJobCodes";

const OpenOrderFirst = () => {

    useEffect(() => {
      const fetchData = async () => {
       
        
      };
      fetchData();
    }, []);
    
const [isModalVisibleOpenOrder, setIsModalVisibleOpenOrder] = useState(false);

  const handleOpenOrder = () => {
    setIsModalVisibleOpenOrder(true);
  };

  const handleModalCancelOpenOrder = () => {
    setIsModalVisibleOpenOrder(false);
    setTimeout(() => {
      window.location.reload();
  }, 500);
 

  };

  const handleOpenOrderAddFinish = () => {
    setIsModalVisibleOpenOrder(false); 
  };


  const [isModalVisibleOpenOrderSecond, setIsModalVisibleOpenOrderSecond] = useState(false);

  const handleOpenOrderSecond = () => {
    setIsModalVisibleOpenOrderSecond(true);
  };

  const handleModalCancelOpenOrderSecond = () => {
    setIsModalVisibleOpenOrderSecond(false);
  };

  const handleOpenOrderAddFinishSecond = () => {
    setIsModalVisibleOpenOrderSecond(false); 
  };



  const [isModalVisibleOpenOrderSecondOffer, setIsModalVisibleOpenOrderSecondOffer] = useState(false);

  const handleOpenOrderSecondOffer = () => {
    setIsModalVisibleOpenOrderSecondOffer(true);
  };

  const handleModalCancelOpenOrderSecondOffer = () => {
    setIsModalVisibleOpenOrderSecondOffer(false);
  };

  const handleOpenOrderAddFinishSecondOffer = () => {
    setIsModalVisibleOpenOrderSecondOffer(false); 
  };


  const [isModalVisibleOpenOrderMergeJobCode, setIsModalVisibleOpenOrderMergeJobCode] = useState(false);

  const handleOpenOrderMergeJobCode = () => {
    setIsModalVisibleOpenOrderMergeJobCode(true);
  };

  const handleModalCancelOpenOrderMergeJobCode = () => {
    setIsModalVisibleOpenOrderMergeJobCode(false);
  };

  const handleOpenOrderAddFinishMergeJobCode = () => {
    setIsModalVisibleOpenOrderMergeJobCode(false); 
  };


   return ( 
    <div  style={{padding:"40px", display:"block"}}>
      <div style={{ display:"flex", justifyContent:"space-between"}}>
        <Button 
            style={{background:colors.blue,color:"white", marginRight:"5px"}}
            onClick={handleOpenOrderSecond}>
             Mevcut Siparişe Setup Ekle
        </Button>
        <Modal
            title=" Mevcut Siparişe Setup Ekle"
            visible={isModalVisibleOpenOrderSecond}
            onCancel={handleModalCancelOpenOrderSecond}
            footer={null}>
            <OpenOrderSecond onClose={handleOpenOrderAddFinishSecond} />
        </Modal>

       
        <Button 
            style={{background:colors.blue,color:"white", marginRight:"5px"}}
            onClick={handleOpenOrderSecondOffer}>
             Mevcut Teklife Setup Ekle
        </Button>
        <Modal
            title=" Mevcut Teklife Setup Ekle"
            visible={isModalVisibleOpenOrderSecondOffer}
            onCancel={handleModalCancelOpenOrderSecondOffer}
            footer={null}>
            <OpenOrderSecondOffer onClose={handleOpenOrderAddFinishSecondOffer} />
        </Modal>
        </div>
      
       <div style={{marginTop:"20px", display:"flex", justifyContent:"space-between"}}>
        <Button 
            style={{background:colors.blue,color:"white"}}
            onClick={handleOpenOrder}>
            Yeni İş Emri Oluştur
        </Button>
         <Modal
            title="Yeni İş Emri Oluştur"
            visible={isModalVisibleOpenOrder}
            onCancel={handleModalCancelOpenOrder}
            footer={null}
            className="open-order-popup"

            >
            <OpenOrderAdd onClose={handleOpenOrderAddFinish} />
       </Modal>

    
     
      
       </div> 

    </div>
    )

};

export default OpenOrderFirst;


/*
  <Button 
            style={{background:colors.blue,color:"white", marginRight:"25px"}}
            onClick={handleOpenOrderMergeJobCode}>
            İş Kodlarını Birleştir
        </Button>
         <Modal
            title="İş Kodlarını Birleştir"
            visible={isModalVisibleOpenOrderMergeJobCode}
            onCancel={handleModalCancelOpenOrderMergeJobCode}
            footer={null}
          //  className="open-order-popup"
            >
            <OpenOrderMergeJobCodes onClose={handleOpenOrderAddFinishMergeJobCode} />
       </Modal>

  
*/