import './App.css';
import React, { useEffect, useState } from "react";

import Verify from './web/auth/verify';
import Login from './admin/Auth/login';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
//admin
import UserList from './admin/User/userlist';
import UserAdd from './admin/User/useradd';
import UserEdit from './admin/User/useredit';

import TitleList from './admin/Titles/titleList';
import TitleAdd from './admin/Titles/titleAdd';
import TitleEdit from './admin/Titles/titleEdit';

import UnitList from './admin/Units/unitList';
import UnitAdd from './admin/Units/unitAdd';
import UnitEdit from './admin/Units/unitEdit';

import CompanyList from './admin/Companies/companyList';
import CompanyAdd from './admin/Companies/companyAdd';
import CompanyEdit from './admin/Companies/companyEdit';

import DayList from './admin/Days/dayList';
import DayListDetail from './admin/Days/dayListDetail';

import AllItemList from './admin/AllItems/allItemList';
import AllItemAdd from './admin/AllItems/allItemAdd';

import MailList from './admin/Mail/mailList';
import MailAdd from './admin/Mail/mailAdd';
import MailEdit from './admin/Mail/mailEdit'

import ContractManufacturingProcessList from './admin/ContractManufacturings/contractManufacturingProcessList';
import ContractManufacturingProcessAdd from './admin/ContractManufacturings/contractManufacturingProcessAdd';

import SupplierList from './admin/Suppliers/supplierList';
import SupplierAdd from './admin/Suppliers/supplierAdd';


import Dashboard from './layouts/dashboars';
import PrivateComponent from './layouts/privatePage';

//user
import Register from './web/auth/register';
import Profile from './web/auth/profile';
import ChangePassword from './web/auth/changepassword';
import ForgotPassword from './web/auth/forgotPassword';

import PrivateWebPageComponent from './layouts/privateWebPage';



import PurchaseRequestList from './web/layouts/purchaseRequests/purchaseRequestList';
import PurchaseRequestDetail from './web/layouts/purchaseRequests/purchaseRequestDetail';
import PurchaseRequestSendMail from './web/layouts/purchaseRequests/purchaseRequestSendMail';

import ContractManufacturingAdd from './web/layouts/contractManufacturings/contractManufacturingAdd';
import ContractManufacturingList from './web/layouts/contractManufacturings/contractManufacturingList';

import AlarmList from './web/layouts/alarms/alarmList';
import AlarmDetail from './web/layouts/alarms/alarmDetail';
import OpenOrderAddContinue from './web/layouts/openOrders/openOrderAddContinue';
import OpenOrderList from './web/layouts/openOrders/openOrderList';
import OpenOrderDetail from './web/layouts/openOrders/openOrderDetail';
import StockList from './web/layouts/stock/stockList';
import CheckCurrentPriceList from './web/layouts/checkCurrentPrice/checkCurrentPriceList';
import CheckCurrentPriceSendMail from './web/layouts/checkCurrentPrice/checkCurrentPriceSendMail';
import FutureRawList from './web/layouts/futureRaw/futureRawList';
import FutureRawDetail from './web/layouts/futureRaw/futureRawDetail';
import AddRequestsList from './admin/AddRequests/addRequetsList';
import ProgrammerPage from './web/layouts/pages/programmerPage';
import BuyerPage from './web/layouts/pages/buyerPage';
import SalesmanPage from './web/layouts/pages/salesmanPage';
import WantedOfferList from './web/layouts/wantedOffers/wantedOfferList';
import SetupPartsDetail from './web/layouts/openOrders/setupPartsDetail';
import SetupPlatesDetail from './web/layouts/openOrders/setupPlatesDetail';

import DensityList from './admin/Densities/densityList';
import DensityAdd from './admin/Densities/densityAdd';
import DensityEdit from './admin/Densities/densityEdit';

import ReturnRawList from './web/layouts/returnRaw/returnRawList';
import ManagerPage from './web/layouts/pages/managerPage';
import ExitRawList from './web/layouts/exitRaw/exitRawList';
import WaitingOfferOpenOrderList from './web/layouts/openOrders/offer/waitingOfferOpenOrderList';
import WaitingOfferOpenOrderListLimited from './web/layouts/openOrders/offer/waitingOfferOpenOrderListLimited';
import ProductionManagerPage from './web/layouts/pages/productionManagerPage';
import SortEditList from './web/layouts/sortEdit/sortEditList';
import Machine10List from './web/layouts/sortEdit/machine10List';
import Machine5List from './web/layouts/sortEdit/machine5List';
import OpenOrderSortList from './web/layouts/openOrders/openOrderSortList';
import PaymentMethodList from './admin/PaymentMethods/paymentMethodList';
import PaymentMethodAdd from './admin/PaymentMethods/paymentMethodAdd';
import ArchieveOpenOrderList from './web/layouts/openOrders/archieveOpenOrderList';
import WantedOfferView from './web/layouts/wantedOffers/wantedOfferView';
import AbkantList from './web/layouts/sortEdit/abkantList';
import ContractManufacturingEdit from './web/layouts/contractManufacturings/contractManufacturingEdit';
import ContractManufacturingListEdit from './web/layouts/contractManufacturings/contractManufacturingListEdit';
import OfferDetail from './web/layouts/openOrders/offer/offerDetail';
import MachineList from './admin/Machines/machineList';
import MachineAdd from './admin/Machines/machineAdd';
import FaultList from './web/layouts/fault/faultList';
import ApprovedOfferOpenOrderList from './web/layouts/openOrders/offer/approvedOfferOpenOrderList';
import PersonnelPerfomanceList from './web/layouts/personnelPerfomance/personnelPerfomanceList';
import UnitPriceCustomerPage from './web/layouts/openOrders/offer/unitPriceCustomerPage';
import TotalPriceCustomerPage from './web/layouts/openOrders/offer/totalPriceCustomerPage';
import OfferOperationPrice from './web/layouts/openOrders/offer/offerOperationPrice';
import ContractManufacturingDisplayList from './web/layouts/contractManufacturings/contractManufacturingDisplayList';
import ProcessStatusList from './web/layouts/processStatus/processStatusList';
import ThicknessPriceCustomerPage from './web/layouts/openOrders/offer/thicknessPriceCustomerPage';
import AccountingPage from './web/layouts/pages/accountingPage';

import HomePage from './web/layouts/pages/homePage';

import ScrapList from './web/layouts/scraps/scrapList';
import WaitingOrderOpenOrderList from './web/layouts/openOrders/order/waitingOrderOpenOrderList';

import OrderDetail from './web/layouts/openOrders/order/orderDetail';
import OrderOperationPrice from './web/layouts/openOrders/order/orderOperationPrice';
import TotalPriceOrderCustomerPage from './web/layouts/openOrders/order/totalPriceOrderCustomerPage';
import UnitPriceOrderCustomerPage from './web/layouts/openOrders/order/unitPriceOrderCustomerPage';
import ThicknessPriceOrderCustomerPage from './web/layouts/openOrders/order/thicknessPriceOrderCustomerPage';
import InvoicePurchaseList from './web/layouts/invoices/invoicePurchaseList';
import InvoicePurchaseDetail from './web/layouts/invoices/invoicePurchaseDetail';
import InvoiceCategoryList from './admin/Invoices/invoiceCategoryList';
import InvoiceCategoryAdd from './admin/Invoices/invoiceCategoryAdd';
import InvoiceCategoryContentList from './admin/Invoices/invoiceCategoryContentList';
import InvoiceCategoryContentAdd from './admin/Invoices/invoiceCategoryContentAdd';
import ExpenseList from './web/layouts/invoices/expenseList';
import ExpenseAddContinue from './web/layouts/invoices/expenseAddContinue';
import ExpenseEdit from './web/layouts/invoices/expenseEdit';
import ExpenseDisplay from './web/layouts/invoices/expenseDisplay';
import ExpenseDisplayList from './web/layouts/invoices/expenseDisplayList';
import InvoicePurchaseListLimited from './web/layouts/invoices/invoicePurchaseListLimited';
import OpenOrderGroupList from './web/layouts/openOrders/openOrderGroupList';
import OpenOrderGroupListLimited from './web/layouts/openOrders/openOrderGroupListLimited';
import InvoiceOpenOrdersList from './web/layouts/invoices/invoiceOpenOrdersList';
import InvoiceScrapsList from './web/layouts/invoices/invoiceScrapsList';
import InvoiceReturnRawList from './web/layouts/invoices/invoiceReturnRawList';
import InvoiceContractManufacturingList from './web/layouts/invoices/invoiceContractManufacturingList';
import SetupPlatesDetailMergedJobCodes from './web/layouts/openOrders/mergeJobCodes/setupPlatesDetailMergedJobCodes';
import OpenOrderAddContinueMergeJobCodes from './web/layouts/openOrders/mergeJobCodes/openOrderAddContinueMergeJobCodes';
import SurfaceList from './admin/Surfaces/surfaceList';
import SurfaceAdd from './admin/Surfaces/surfaceAdd';
import SurfaceEdit from './admin/Surfaces/surfaceEdit'
import ProductionOpenOrderList from './web/layouts/openOrders/productionOpenOrder/productionOpenOrderList';
import ProductionOpenOrderGroupList from './web/layouts/openOrders/productionOpenOrder/productionOpenOrderGroupList';
import OpenOrderListDelete from './web/layouts/openOrders/deleteOpenOrder/openOrderListDelete';
import OpenOrderGroupListDelete from './web/layouts/openOrders/deleteOpenOrder/openOrderGroupListDelete';
import WaitingOpenOrderDetail from './web/layouts/openOrders/waitingOpenOrder/waitingOpenOrderDetail';
import WaitingOpenOrderGroupList from './web/layouts/openOrders/waitingOpenOrder/waitingOpenOrderGroupList';
import WaitingOpenOrderGroupListLimited from './web/layouts/openOrders/waitingOpenOrder/waitingOpenOrderGroupListLimited';
import NewList from './admin/News/newList';
import NewAdd from './admin/News/newAdd';
import NewEdit from './admin/News/newEdit';

import AnnouncementList from './admin/Announcements/announcementList';
import AnnouncementAdd from './admin/Announcements/announcementAdd';

import BirthdayList from './web/layouts/birthdays/birthdayList';
import BirthdayListLimited from './web/layouts/birthdays/birthdayListLimited';
import NewsList from './web/layouts/news/newsList';
import NewsListLimited from './web/layouts/news/newsListLimited';
import AnnouncementsListLimited from './web/layouts/announcements/announcementsListLimited';
import AnnouncementsList from './web/layouts/announcements/announcementsList';
import MenusList from './web/layouts/menus/menusList'
import PersonnelList from './web/layouts/personnels/personnelList';
import OperationDetailProductManager from './web/layouts/openOrders/operationDetailProductManager';

import StockListAdmin from './admin/Stock/stockListAdmin';
import StockListAdminEdit from './admin/Stock/stockListAdminEdit';
import WastageListAdminEdit from './admin/Stock/wastageListAdminEdit';
import OffersGivenList from './web/layouts/openOrders/offersGiven/offersGivenList';
import OffersGivenGroupList from './web/layouts/openOrders/offersGiven/offersGivenGroupList';
import OfferDetailView from './web/layouts/openOrders/offer/offerDetailView';
import OfferOperationPriceView from './web/layouts/openOrders/offer/offerOperationPriceView';
import GivenOfferOpenOrderList from './web/layouts/openOrders/offer/givenOfferOpenOrderList';
import GivenOrderOpenOrderList from './web/layouts/openOrders/order/givenOrderOpenOrderList';
import OrderDetailView from './web/layouts/openOrders/order/orderDetailView';
import OrderOperationPriceView from './web/layouts/openOrders/order/orderOperationPriceView';
import SetupPartsView from './web/layouts/openOrders/setupPartsView';
import SetupPlatesView from './web/layouts/openOrders/setupPlatesView';
import SetupPartsEdit from './web/layouts/openOrders/setupPartsEdit';




function App() {
  const [auth, setAuth] = useState(localStorage.getItem('accessToken'));

  useEffect(() => {
    setAuth(localStorage.getItem('accessToken'));
  }, []);

  return (
    <div className="App">
      <Router>
       
            <Routes>
              <Route element={<PrivateComponent/>}>
                <Route path="/" element={<HomePage />} />
                <Route path="/adminpanel" element={<Dashboard />} />

                <Route path="/useradd" element={<UserAdd />} />
                <Route path="/useredit" element={<UserEdit />} />
                <Route path='/userlist'element={<UserList/>}/>

                <Route path='/titlelist'element={<TitleList/>}/>
                <Route path='/titleadd'element={<TitleAdd/>}/>
                <Route path='/titleedit'element={<TitleEdit/>}/>

                <Route path='/surfacelist'element={<SurfaceList/>}/>
                <Route path='/surfaceadd'element={<SurfaceAdd/>}/>
                <Route path='/surfaceedit'element={<SurfaceEdit/>}/>

                <Route path='/unitlist'element={<UnitList/>}/>
                <Route path='/unitadd'element={<UnitAdd/>}/>
                <Route path='/unitedit'element={<UnitEdit/>}/>

                <Route path='/companylist'element={<CompanyList/>}/>
                <Route path='/companyadd'element={<CompanyAdd/>}/>
                <Route path='/companyedit'element={<CompanyEdit/>}/>

                <Route path='/allitemlist'element={<AllItemList/>}/>
                <Route path='/allitemadd'element={<AllItemAdd/>}/>
               
                <Route path='/maillist'element={<MailList/>}/>
                <Route path='/mailadd'element={<MailAdd/>}/>
                <Route path='/mailedit'element={<MailEdit/>}/>


                <Route path='/daylist'element={<DayList/>}/>
                <Route path='/daylistdetail'element={<DayListDetail/>}/>

                <Route path='/addrequestslist'element={<AddRequestsList/>}/>


                <Route path='/contractmanufacturingprocessadd'element={<ContractManufacturingProcessAdd/>}/>
                <Route path='/contractmanufacturingprocesslist'element={<ContractManufacturingProcessList/>}/>

       
                <Route path='/supplierlist'element={<SupplierList/>}/>
                <Route path='/supplieradd'element={<SupplierAdd/>}/>

                <Route path='/densitylist'element={<DensityList/>}/>
                <Route path='/densityadd'element={<DensityAdd/>}/>
                <Route path='/densityedit'element={<DensityEdit/>}/>

                <Route path='/paymentmethodlist'element={<PaymentMethodList/>}/>
                <Route path='/paymentmethodadd'element={<PaymentMethodAdd/>}/>

                <Route path='/machinelist'element={<MachineList/>}/>
                <Route path='/machineadd'element={<MachineAdd/>}/>
              
              
                <Route path='/invoicecategorylist'element={<InvoiceCategoryList/>}/>
                <Route path='/invoicecategoryadd'element={<InvoiceCategoryAdd/>}/>
          
                <Route path='/invoicecategorycontentlist'element={<InvoiceCategoryContentList/>}/>
                <Route path='/invoicecategorycontentyadd'element={<InvoiceCategoryContentAdd/>}/>
             
                <Route path='/newlist'element={<NewList/>}/>
                <Route path='/newadd'element={<NewAdd/>}/>
                <Route path='/newedit'element={<NewEdit/>}/>

                <Route path='/announcementlist'element={<AnnouncementList/>}/>
                <Route path='/announcementadd'element={<AnnouncementAdd/>}/>

                
                <Route path='/stocklistadmin' element={<StockListAdmin/>}/>
                <Route path='/stocklistadminedit' element={<StockListAdminEdit/>}/>
                <Route path='/wastagelistadminedit' element={<WastageListAdminEdit/>}/>


              </Route>
                <Route path="/login" element={<Login />} />
                <Route path='/forgotpassword'element={<ForgotPassword />}/>
                <Route path="/register" element={<Register />} />
                <Route path="/verify" element={<Verify />} />

                <Route path="/changepassword" element={<ChangePassword/>} /> 


              <Route element={<PrivateWebPageComponent/>}>
       
                <Route path="/home" element={<HomePage />} />
                <Route path="/programmerpage" element={<ProgrammerPage />} />
                <Route path="/buyerpage" element={<BuyerPage/>} />
                <Route path="/salesmanpage" element={<SalesmanPage/>} />
                <Route path="/managerpage" element={<ManagerPage/>} />
                <Route path="/productionmanagerpage" element={<ProductionManagerPage/>} />
                <Route path="/accountingpage" element={<AccountingPage/>} />
                <Route path="/homepage" element={<HomePage/>} />

           
                <Route path="/profile" element={<Profile />} /> 
                <Route path='/purchaserequestlist'element={<PurchaseRequestList/>}/>
                <Route path='/purchaserequestdetail'element={<PurchaseRequestDetail/>}/>
                <Route path='/purchaserequestsendmail'element={<PurchaseRequestSendMail/>}/>

                <Route path='/contractmanufacturingadd'element={<ContractManufacturingAdd/>}/>
                <Route path='/contractmanufacturingedit'element={<ContractManufacturingEdit/>}/>

                <Route path='/contractmanufacturinglist'element={<ContractManufacturingList/>}/>
                <Route path='/contractmanufacturingdisplaylist'element={<ContractManufacturingDisplayList/>}/>
                <Route path='/contractmanufacturinglistedit'element={<ContractManufacturingListEdit/>}/>


                <Route path='/wantedofferlist'element={<WantedOfferList/>}/>
                <Route path='/wantedofferview'element={<WantedOfferView/>}/>


                <Route path='/alarmlist'element={<AlarmList/>}/>
                <Route path='/alarmdetail'element={<AlarmDetail/>}/>
               
                <Route path='/openorderaddcontinue'element={<OpenOrderAddContinue/>}/>
                <Route path='/waitingopenorderdetail'element={<WaitingOpenOrderDetail/>}/>


                <Route path='/waitingofferopenorderlist'element={<WaitingOfferOpenOrderList/>}/>
                <Route path='/waitingofferopenorderlistlimited'element={<WaitingOfferOpenOrderListLimited/>}/>
                <Route path='/offerdetail'element={<OfferDetail/>}/>
                <Route path='/offerdetailview'element={<OfferDetailView/>}/>
                <Route path='/offeroperationprice'element={<OfferOperationPrice/>}/>
                <Route path='/offeroperationpriceview'element={<OfferOperationPriceView/>}/>

                <Route path='/unitpricecustomerpage'element={<UnitPriceCustomerPage/>}/>
                <Route path='/totalpricecustomerpage'element={<TotalPriceCustomerPage/>}/>
                <Route path='/thicknesspricecustomerpage'element={<ThicknessPriceCustomerPage/>}/>

                
                <Route path='/approvedofferopenorderList'element={<ApprovedOfferOpenOrderList/>}/>
              
              
                <Route path='/waitingorderopenorderlist'element={<WaitingOrderOpenOrderList/>}/>
               
                <Route path='/waitingopenordergrouplist'element={<WaitingOpenOrderGroupList/>}/>
                <Route path='/waitingorderopengrouplistlimited'element={<WaitingOpenOrderGroupListLimited/>}/>


                <Route path='/orderdetail'element={<OrderDetail/>}/>
                <Route path='/orderoperationprice'element={<OrderOperationPrice/>}/>

                <Route path='/totalpriceordercustomerpage'element={<TotalPriceOrderCustomerPage/>}/>
                <Route path='/unitpriceordercustomerpage'element={<UnitPriceOrderCustomerPage/>}/>
                <Route path='/thicknesspriceordercustomerpage'element={<ThicknessPriceOrderCustomerPage/>}/>


                <Route path='/openorderlist'element={<OpenOrderList/>}/>
                <Route path='/openordergrouplist'element={<OpenOrderGroupList/>}/>
                <Route path='/openordergrouplistlimited'element={<OpenOrderGroupListLimited/>}/>

                <Route path='/openorderlistdelete'element={<OpenOrderListDelete/>}/>
                <Route path='/openordergrouplistdelete'element={<OpenOrderGroupListDelete/>}/>

                <Route path='/openorderdetail'element={<OpenOrderDetail/>}/>
                <Route path='/setuppartsdetail'element={<SetupPartsDetail/>}/>
                <Route path='/setupplatesdetail'element={<SetupPlatesDetail/>}/>
                <Route path='/setupplatesdetailmergedjobcodes'element={<SetupPlatesDetailMergedJobCodes/>}/>

                <Route path='/stocklist'element={<StockList/>}/>
                <Route path='/checkcurrentpricelist'element={<CheckCurrentPriceList/>}/>
                <Route path='/checkcurrentpricesendmail'element={<CheckCurrentPriceSendMail/>}/>

                <Route path='/futurerawlist'element={<FutureRawList/>}/>
                <Route path='/futurerawdetail'element={<FutureRawDetail/>}/>
               

                <Route path='/returnrawlist'element={<ReturnRawList/>}/>

                <Route path='/exitrawlist'element={<ExitRawList/>}/>
                <Route path='/faultlist'element={<FaultList/>}/>

              
                <Route path='/sorteditlist'element={<SortEditList/>}/>
                <Route path='/machine10list'element={<Machine10List/>}/>
                <Route path='/machine5list'element={<Machine5List/>}/>
                <Route path='/abkantlist'element={<AbkantList/>}/>


                <Route path='/openordersortlist'element={<OpenOrderSortList/>}/>
                <Route path='/productionopenorderlist'element={<ProductionOpenOrderList/>}/>
                <Route path='/productionopenordergrouplist'element={<ProductionOpenOrderGroupList/>}/>

                <Route path='/archieveopenorderlist'element={<ArchieveOpenOrderList/>}/>
                <Route path='/personnelperformancelist'element={<PersonnelPerfomanceList/>}/>

                <Route path='/processstatus'element={<ProcessStatusList/>}/>
                <Route path='/scraplist'element={<ScrapList/>}/>

                <Route path='/invoiceopenorderslist'element={<InvoiceOpenOrdersList/>}/>
                <Route path='/invoicecontractmanufacturinglist'element={<InvoiceContractManufacturingList/>}/>

                <Route path='/invoicescrapslist'element={<InvoiceScrapsList/>}/>
                <Route path='/invoicereturnrawlist'element={<InvoiceReturnRawList/>}/>

                <Route path='/invoicepurchaselist'element={<InvoicePurchaseList/>}/>
                <Route path='/invoicepurchaselistlimited'element={<InvoicePurchaseListLimited/>}/>

                <Route path='/invoicepurchasedetail'element={<InvoicePurchaseDetail/>}/>

                <Route path='/expenselist'element={<ExpenseList/>}/>
                <Route path='/expenseaddcontinue'element={<ExpenseAddContinue/>}/>
                <Route path='/expenseedit'element={<ExpenseEdit/>}/>
                <Route path='/expensedisplay'element={<ExpenseDisplay/>}/>
                <Route path='/expensedisplaylist'element={<ExpenseDisplayList/>}/>

                <Route path='/offersgivenlist'element={<OffersGivenList/>}/>
                <Route path='/offersgivengrouplist'element={<OffersGivenGroupList/>}/>

                <Route path='/openorderaddcontinuemergejobcodes'element={<OpenOrderAddContinueMergeJobCodes />}/>

                <Route path='/birthdaylist' element={<BirthdayList/>}/>
                <Route path='/birthdaylistlimited' element={<BirthdayListLimited/>}/>

                <Route path='/newslist' element={<NewsList/>}/>
                <Route path='/newslistlimited' element={<NewsListLimited/>}/>

                <Route path='/announcementslist' element={<AnnouncementsList/>}/>
                <Route path='/announcementslistlimited' element={<AnnouncementsListLimited/>}/>
                <Route path='/personnellist' element={<PersonnelList/>}/>


                <Route path='/menuslist' element={<MenusList/>}/>


                <Route path='/operationdetailproductmanager' element={<OperationDetailProductManager/>}/>
              
                <Route path='/givenofferopenorderlist'element={<GivenOfferOpenOrderList/>}/>
                <Route path='/givenorderopenorderlist'element={<GivenOrderOpenOrderList/>}/>
                <Route path='/orderdetailview'element={<OrderDetailView/>}/>
                <Route path='/orderoperationpriceview'element={<OrderOperationPriceView/>}/>

                <Route path='/setuppartsview'element={<SetupPartsView/>}/>
                <Route path='/setupplatesview'element={<SetupPlatesView/>}/>
                <Route path='/setuppartsedit'element={<SetupPartsEdit/>}/>

                

              </Route>

            
            </Routes>
           
      </Router>
    </div>
  );
}

export default App;
/* 

  <Route element={<PrivateWebContactPageComponent/>}>
              

              </Route>

              */