import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { colors } from "../../../environments/environment";
import FirebaseService from "../../../services/firebaseService";
import { Button, Checkbox, Spin } from "antd";
const SetupPartsEdit = () => {
  const location = useLocation();
  const [filteredSetup, setFilteredSetup] = useState(location.state?.filteredSetup || null);
  const [processes, setProcesses] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const processesData = await FirebaseService.getContractManufacturingProcessesData();
        const filteredProcesses = processesData.filter((item) => !item.isDeleted);
        setProcesses(filteredProcesses);
      } catch (error) {
        console.error("Operasyon verileri alınırken hata oluştu:", error);
      }
    };
    fetchData();
  }, []);
  const handleOperationChange = (partIndex, processName, checked) => {
    const updatedParts = [...filteredSetup.parts];
    const part = updatedParts[partIndex];
    part.operations = part.operations || [];
    if (checked) {
      if (!part.operations.includes(processName)) {
        part.operations = [...part.operations, processName];
      }
    } else {
      if (part.operations.includes(processName)) {
        part.operations = part.operations.filter((op) => op !== processName);
      }
    }
    setFilteredSetup({ ...filteredSetup, parts: updatedParts });
  };
  const handleSaveAndExit = async () => {
    try {
      const updatedParts = filteredSetup.parts.map((part) => ({
        ...part,
        operations: part.operations || [],
      }));
      const updatedSetup = { ...filteredSetup, parts: updatedParts };
      console.log("updated Setup", updatedSetup);
      const response = await FirebaseService.updateOperations([updatedSetup]);
      console.log("response", response);
      if (response.success) {
        setLoading(true);
        navigate('/openorderdetail', { state: { record: updatedSetup } });
      } else {
        alert(response.message);
      }
    } catch (error) {
      console.error("Kaydetme sırasında bir hata oluştu:", error);
      alert("Kaydetme sırasında bir hata oluştu.");
    }
  };
  if (!filteredSetup) {
    return <p>Setup bilgisi yüklenemedi.</p>;
  }
  return (
    <>
      <div
        style={{
          maxWidth: "100%",
          padding: "5% 5% 0 5%",
          display: "block",
        }}
      >
        <h2 style={{ fontWeight: "bold", color: colors.blue, textAlign: "left" }}>
          Parça Düzenle
        </h2>
        <div className="setup-item-container" style={{ marginTop: "30px" }}>
          {filteredSetup.parts?.map((part, index) => (
            <div
              className="setup-item"
              key={index}
              style={{
                display: "flex",
                flexDirection: "row",
                border: "1px solid",
                borderColor: colors.blue,
                padding: "10px 30px",
                marginBottom: "10px",
                height: "auto", // Sabit yükseklik kaldırıldı
              }}
            >
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {part.imageFileName ? (
                    <img
                      src={part.imageUrl} // Dinamik yol düzenlemesi
                      alt={part.imageFileName}
                      style={{ width: "200px", height: "200px" }}
                    />
                  ) : (
                    <div
                      style={{
                        width: "200px",
                        height: "200px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "1px solid gray",
                      }}
                    >
                      <span>No Image Available</span>
                    </div>
                  )}
                </div>
                <div style={{ flex: 1, padding: "10px" }}>
                  <strong>Operasyonlar:</strong>
                  {processes.map((process, opIndex) => (
                    <div
                      key={opIndex}
                      style={{ display: "flex", alignItems: "center", marginTop: "5px" }}
                    >
                      <Checkbox
                        checked={part.operations?.includes(process.processName)}
                        onChange={(e) =>
                          handleOperationChange(index, process.processName, e.target.checked)
                        }
                      >
                        {process.processName}
                      </Checkbox>
                    </div>
                  ))}
                </div>
              </div>
              <div style={{ flex: 1, marginLeft: "20px" }}>
                <p>
                  <strong>Part No:</strong> {part.partNo || "Belirtilmemiş"}
                </p>
                <p>
                  <strong>Firma:</strong> {part.customerName || "Belirtilmemiş"}
                </p>
                <p>
                  <strong>Parça Ağırlığı:</strong> {part.weightSetup || "Belirtilmemiş"} {part.weightUnit || ""}
                </p>
                <p>
                  <strong>Parça Sayısı:</strong> {part.piece || "Belirtilmemiş"}
                </p>
                <p>
                  <strong>Ebat:</strong> {part.height || "Belirtilmemiş"} x {part.width || "Belirtilmemiş"} {part.unit || ""}
                </p>
                <p>
                  <strong>Süre:</strong> {part.time || "Belirtilmemiş"}
                </p>
              </div>
            </div>
          ))}
        </div>
        <div>
          <Button
            style={{
              backgroundColor: colors.blue,
              color: "white",
              fontWeight: "500",
              float: "right",
              marginRight: "120px",
              marginTop: "30px",
            }}
            onClick={handleSaveAndExit}
          >
            Kaydet ve Çık
          </Button>
        </div>
        {loading && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 1000,
            }}
          >
            <Spin />
          </div>
        )}
      </div>
    </>
  );
};
export default SetupPartsEdit;