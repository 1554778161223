import React,{useEffect,useState} from "react";
import { Button, Form, Input,Table ,Modal,Tooltip, Popconfirm, Spin} from 'antd';
import { useLocation  } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { DownOutlined, UpOutlined } from "@ant-design/icons"; 
import NotificationService from "../../../services/antNotificationService";
import FirebaseService from "../../../services/firebaseService";
import { colors } from "../../../environments/environment";


const OpenOrderDetail = () => {
    const location = useLocation();
    const navigate = useNavigate();
   
    const recordIncoming = location.state?.record;
    const [record, setRecord] = useState(recordIncoming || null);
    const [depotNames, setDepotNames] = useState(new Set());

    const [loading, setLoading] = useState(false);
  
    const handleViewDetail = (record) => {

     const selectedOpenOrderInfo = {
      companyName: record.companyName || null,
      orderType: record.orderType || null,
      projectCode: record.projectCode || null,
      warehouseName: null,
      isOfferApproved: record.isOfferApproved  || false,
      isOffer: record.isOffer  || false,
      isSetup: record.isSetup  || false,
      isOfferSetupUpdate: record.isOfferSetupUpdate  || false,
      setupName: record.setupName,
      stock: record.stock,
      wastage:record.wastage,
      jobCode:record.jobCode,
      deadline: record.deadline,
      key: record.key
    };
    
     navigate('/setuppartsview', { state: { record: selectedOpenOrderInfo} });

    };

    const handleGoWaitingOpenOrder = async (record) => {
      setLoading(true);
 
    
      const updateOpenOrderItem = {
        isSetup: false,
        isWaiting: true,
      };

      const updateResult = await FirebaseService.updateOpenOrder(
        record.key,
        updateOpenOrderItem
      );

      if (updateResult.success) {
         // Setup verisinin tamamen silinmesi
         const setupData = await FirebaseService.getSetupData();
         const filteredSetup = setupData.find(item => 
           !item.isDeleted &&  item.jobCode && item.jobCode === record.jobCode 
         );
         if (filteredSetup) {
           try {
             const resultSetup = await FirebaseService.deleteSetupData(filteredSetup.key);
           } catch (error) {
             console.error('Setup silinirken hata oluştu:', error);
           }
         } else {
           console.error('Eşleşen setup verisi bulunamadı.');
         }    
 
         // fason üretim silme
         const processes = await FirebaseService.getContractManufacturingData();
         const filteredProcesses = processes.filter(item => {
           return !item.isDeleted && item.jobCode && item.jobCode===record.jobCode;
         });
          
         for (const item of filteredProcesses) {
           try {
             const result = await FirebaseService.deleteContractManufacturingData(item.key);
             if (result) {
               console.log(`Item with key ${item.key} deleted successfully.`);
             } else {
               
               console.log(`Failed to delete item with key ${item.key}.`);
             }
           } catch (error) {
           
             console.error(`Error deleting item with key ${item.key}:`, error);
           }
         }
 
          //part silme kısmı
          const partsData = await FirebaseService.getPartsData();
          const filteredPart = partsData.filter(item => {
            return  !item.isDeleted && item.jobCode=== record.jobCode  && item.jobCode
          });
  
          
          for (const item of filteredPart) {
            try {
              const result = await FirebaseService.deletePartData(item.key);
              if (result) {
                console.log(`Item with key ${item.key} deleted successfully.`);
              } else {
               
                console.log(`Failed to delete item with key ${item.key}.`);
              }
            } catch (error) {
             
              console.error(`Error deleting item with key ${item.key}:`, error);
            }
          }
     
 
          //fire silme
           const wastagesData = await FirebaseService.getWastagesData();
           const filteredWastage = wastagesData.filter(item => {
             return  !item.isDeleted && item.jobCode=== record.jobCode && item.jobCode
           });
   
           
           for (const item of filteredWastage) {
             try {
               const result = await FirebaseService.deleteWastageData(item.key);
               if (result) {
                 console.log(`Item with key ${item.key} deleted successfully.`);
               } else {
                
                 console.log(`Failed to delete item with key ${item.key}.`);
               }
             } catch (error) {
              
               console.error(`Error deleting item with key ${item.key}:`, error);
             }
           }
    
           NotificationService.openSuccessNotification({
            title: "İşlem Başarılı",
            description: "Başarıyla bekleyen siparişe geçirilmiştir.",
            placement: "topRight",
          });
          setTimeout(() => {
            window.location.reload();
          }, 500);

      } else {
        NotificationService.openErrorNotification({
          title: "İşlem Başarısız",
          description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
          placement: "topRight",
        });
        setLoading(false);
        return;
      }

      

    };

   const handleAllGoWaitingOpenOrder = async (record) => {
    setLoading(true);

    if (!record || !Array.isArray(record.data)) {
      setLoading(false);
      return;
    }

    try {
      let allUpdatesSuccessful = true;

      const updatePromises = record.data.map(async (itemx) => {
        const updateResult = await FirebaseService.updateOpenOrder(itemx.key, {
          isWaiting: true,
          isSetup:false,     
        });

        if (!updateResult.success) {
          allUpdatesSuccessful = false;
          NotificationService.openErrorNotification({
            title: "İşlem Başarısız",
            description: `İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz. (İş Kodu: ${itemx.jobCode || "Belirtilmedi"})`,
            placement: "topRight",
          });
        }

          // Setup verisinin silinmesi
      const setupData = await FirebaseService.getSetupData();
      const setupToDelete = setupData.find(
        (item) => !item.isDeleted && item.jobCode === itemx.jobCode
      );

      if (setupToDelete) {
        await FirebaseService.deleteSetupData(setupToDelete.key);
      } else {
        console.warn("Eşleşen setup verisi bulunamadı");
      }

      // Fason üretim verisinin silinmesi
      const contractData = await FirebaseService.getContractManufacturingData();
      const filteredProcesses = contractData.filter(
        (item) => !item.isDeleted && item.jobCode === itemx.jobCode
      );

      await Promise.all(
        filteredProcesses.map(async (item) => {
          await FirebaseService.deleteContractManufacturingData(itemx.key);
        })
      );

      // Parça verisinin silinmesi
      const partsData = await FirebaseService.getPartsData();
      const filteredParts = partsData.filter(
        (item) => !item.isDeleted && item.jobCode === itemx.jobCode
      );

      await Promise.all(
        filteredParts.map(async (itemx) => {
          await FirebaseService.deletePartData(itemx.key);
        })
      );

      // Fire verisinin silinmesi
      const wastagesData = await FirebaseService.getWastagesData();
      const filteredWastages = wastagesData.filter(
        (item) => !item.isDeleted && item.jobCode === itemx.jobCode
      );

      await Promise.all(
        filteredWastages.map(async (itemx) => {
          await FirebaseService.deleteWastageData(itemx.key);
        })
      );

      });
    

      await Promise.all(updatePromises);

    
      if (allUpdatesSuccessful) {
        NotificationService.openSuccessNotification({
          title: "İşlem Başarılı",
          description: "Tüm açık siparişler başarıyla güncellenmiştir.",
          placement: "topRight",
        });
      }
    } catch (error) {
      NotificationService.openErrorNotification({
        title: "İşlem Başarısız",
        description: "Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin.",
        placement: "topRight",
      });
    } finally {
   
      setTimeout(() => {
        navigate("/programmerpage");
      }, 10);
    }
    };

    const [expandedItems, setExpandedItems] = useState({});

    const toggleExpand = (index) => {
      setExpandedItems((prev) => ({
        ...prev,
        [index]: !prev[index], 
      }));
    };
    
    useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
      setLoading(true)

      const data = await FirebaseService.getOpenOrderData();

      let matchingRecords = data.filter(item => 
        !item.isDeleted && item.jobCode.substring(0, 5) === record.jobCode.substring(0, 5)
      );
      
      if (matchingRecords.length > 0) {
        const commonRecord = {
          companyName: matchingRecords[0].companyName,  
          jobCode: matchingRecords[0].jobCode,         
          projectCode: matchingRecords[0].projectCode,  
          programmerName: matchingRecords[0].programmerName,  
          deadline: matchingRecords[0].deadline       
        };
      
        commonRecord.data = matchingRecords;
      
        setRecord(commonRecord);
      
        setLoading(false);  
      } else {
        setLoading(false);  
      }
    


      const newDepotNames = new Set();

      // Stock dizisi
      if (record && record.stock) {
        record.stock.forEach(item => {
          if (item.depotName) {
            newDepotNames.add(item.depotName);
          }
        });
      }

      // Wastage dizisi
      if (record && record.wastage) {
        record.wastage.forEach(item => {
          if (item.depotName) {
            newDepotNames.add(item.depotName);
          }
        });
      }

      setDepotNames(newDepotNames);      
    };
  
    fetchData();
   

    }, []);
    
    const loadOpenOrderDetail = async () => {
      setLoading(true);
      try {
        const data = await FirebaseService.getOpenOrderData();      
        let matchingRecords = data.filter(item => !item.isDeleted && item.jobCode === location.state?.record.jobCode.substring(0, 5) );
    
        if (matchingRecords.length > 0) {
          const commonRecord = {
            companyName: matchingRecords[0].companyName,  
            jobCode: matchingRecords[0].jobCode,         
            projectCode: matchingRecords[0].projectCode,  
            programmerName: matchingRecords[0].programmerName,  
            deadline: matchingRecords[0].deadline         
          };
    
          commonRecord.data = matchingRecords;
          setRecord(matchingRecords)
          setLoading(false);

        } else {
          setLoading(false);

        }
      } catch (error) {
        console.error('Veriler yüklenirken bir hata oluştu:', error);
      } 
    };
    


  useEffect(() => {
    loadOpenOrderDetail();
  }, []);


  const columns= [
    {
      title: 'Kalite',
      dataIndex: 'quality',
      key: 'quality',
      width: 200,
      render: (text,record) => <span style={{ color: record.isQrScan ? '#84BB4C' : '#000000' }}>{text}</span>,
    },

      {
        title: 'Kalınlık',
        dataIndex: 'thickness',
        key: 'thickness',
        width: 200,
        render: (text,record) => <span style={{ color: record.isQrScan ? '#84BB4C' : '#000000' }}>{text}</span>,
      },

     
      {
        title: 'En',
        dataIndex: 'width',
        key: 'width',
        width: 200,
        render: (text,record) => <span style={{ color: record.isQrScan ? '#84BB4C' : '#000000' }}>{text}</span>,
      },
      {
        title: 'Boy',
        dataIndex: 'height',
        key: 'height',
        width: 200,
        render: (text,record) => <span style={{ color: record.isQrScan ? '#84BB4C' : '#000000' }}>{text}</span>,
      },

      {
        title: 'Yüzey',
        dataIndex: 'surface',
        key: 'surface',
        width: 200,
        render: (text,record) => <span style={{ color: record.isQrScan ? '#84BB4C' : '#000000' }}>{text}</span>,
      },
 
      {
        title: 'Adet',
        dataIndex: 'piece',
        key: 'piece',
        render: (text,record) => <span style={{color: record.isQrScan ? "#84BB4C" : "#000000"}}>{text}</span>,
        width: 200,
      },
      {
      title : ' ',
      dataIndex : 'isSendPurchaseicon',
      key : 'isSendPurchaseicon',

render: (text, record) => {
    return (
      <>
        {record.isSendPurchase ? (
          <Tooltip title="Satın Alma Talebi Gönderildi">
            <div >
              <img width={20} height={20} alt="logo" src="/images/isSendPurchaseicon.png" style={{ paddingBottom: "5%" }} />
            </div>
          </Tooltip>
        ) : null
         
      }
      </>
    );
  },
           
    },
    
    ];

   
   return ( 
    <>
      {loading ? (
    
                        <div style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 1000,
                        }}>
                            <Spin/>
                        </div>
                   
          ) : (

    <div style={{ display: "flex", flexDirection: "column",padding:"10px 100px"}}>
    
        <h2 style={{ fontWeight: "bold", color: colors.blue, textAlign:"left" }}> Açık Sipariş Detay</h2>
            
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
            <p style={{ fontWeight: "bold", color: colors.blue }}>Müşteri Firma</p>
            {record && record.companyName}
          </div>

          <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>Depo Firma</p>
          {Array.from(depotNames).length > 0 ? (
          Array.from(depotNames).map((depotName, index) => (
            <p key={index}>{depotName}</p>
          ))
        ) : (
          <p>-</p> // Eğer set boşsa '-' yazdır
        )}
        </div> 

          <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
            <p style={{ fontWeight: "bold", color: colors.blue }}>İş Kodu</p>
            {record && record.jobCode ? record.jobCode.substring(0, 5) : "Belirtilmemiş"}
          </div>

          <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
            <p style={{ fontWeight: "bold", color: colors.blue }}>Termin Tarihi</p>
            {record && record.deadline && typeof record.deadline === 'object' ?
              new Date(record.deadline.seconds * 1000).toLocaleDateString('tr-TR') :
              record.deadline
            }
          </div>

          <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
  <p style={{ fontWeight: "bold", color: colors.blue }}>Durum</p>
  {
    (() => {
      let statusColor = "#000000"; // Varsayılan renk
      let currentStatus = "Başlamadı"; // Varsayılan durum

      // Lazer durumu
      let allLaserFinish = false;
      let halfLaserFinish = false;

      // record.plates'in var olup olmadığını kontrol et
      if (record.plates && Array.isArray(record.plates)) {
        allLaserFinish = record.plates.every(plate => plate.isFinish);
        halfLaserFinish = record.plates.some(plate => plate.isFinish) && !allLaserFinish;
      }

      if (halfLaserFinish) {
        currentStatus = "Lazerde";
      }
      if (allLaserFinish) {
        currentStatus = "Lazer bitti";
      }

      // Operasyonları kontrol etme
      const operationSet = new Set();
      let otherOperationsOngoing = [];
      const parts = record.parts || [];

      if (parts.length > 0) {
        record.parts.forEach(part => {
          const operations = part.operations || [];
          operations.forEach(operation => {
            if (!operationSet.has(operation)) {
              operationSet.add(operation);

              if (!operation.isFinish) {
                otherOperationsOngoing.push(operation);
              }
            }
          });
        });
      }

      if (allLaserFinish && otherOperationsOngoing.some(op => op === "Abkant")) {
        currentStatus = "Abkantta";
      }

      if (allLaserFinish && otherOperationsOngoing.length > 0 && !otherOperationsOngoing.includes("Abkant")) {
        currentStatus = `${otherOperationsOngoing[0]} işleminde`; // İlk devam eden işlemi göster
      }

      return (
        <span style={{ color: record.isDeleted ? "#00000080" : statusColor }}>
          {currentStatus}
        </span>
      );
    })()
  }
</div>


          <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <Button
              style={{ backgroundColor: colors.blue, color: "white", fontWeight: "500" }}
              onClick={() => handleAllGoWaitingOpenOrder(record)}>
              Tümünü Bekleyen Siparişe Döndür
          </Button>
          </div>
        

        </div>

      {record.jobCode?.length !== 5 ? (
        <>
           <div style={{marginTop:"50px"}}>
          {record.data
          ?.sort((a, b) => {
            const getJobCodeNumber = (jobCode) => {
              const parts = jobCode.split("-");
              return parts[1] ? parseInt(parts[1]) : 0; 
            };
  
            const aNumber = getJobCodeNumber(a.jobCode);
            const bNumber = getJobCodeNumber(b.jobCode);
  
            return aNumber - bNumber; 
          })
          .map((item, index) => (
          <div key={index}>
            <div style={{ padding: "10px", display: "flex", alignItems: "center" }}>
          
              <Button
                style={{ backgroundColor: colors.white, color:colors.blue, fontWeight:"bold", border:"1px solid", borderColor:colors.blue}}
                onClick={() => toggleExpand(index)}
              >
                {item.jobCode} 
                {item.thickness && (
                  <span style={{ marginRight: "8px", fontWeight: "bold" }}>
                    {item.thickness}
                  </span>
                )}
                {expandedItems[index] ? (
                  <UpOutlined style={{ fontSize: "16px", color: colors.blue }} />
                ) : (
                  <DownOutlined style={{ fontSize: "16px", color: colors.blue }} />
                )}    
                
              
              </Button>
              <div style={{ textAlign: "right",marginLeft:"10px" }}>
                  {item.orderType === "order" && item.isSetup && !item.isWaiting && (
                    <>
                  
                      <Button
                        style={{ backgroundColor: colors.blue, color: "white", fontWeight: "500", marginRight: "5px" }}
                        onClick={() => handleViewDetail(item)}
                      >
                        Detayı Gör 
                      </Button>
                      <Button
                        style={{ backgroundColor: colors.blue, color: "white", fontWeight: "500" }}
                        onClick={() => handleGoWaitingOpenOrder(item)}
                      >
                        Bekleyen Siparişe Döndür
                      </Button>
                    </>
                  )}
                </div>
            </div>

            {expandedItems[index] && (
              <div style={{ padding: "10px" }}>
                {item.stock?.length > 0 ? (
                  <>
                    <div style={{ fontWeight: "bold", color: colors.blue, marginTop: "10px" }}>
                      Seçilen Hammadeler
                    </div>
                    <Table
                      style={{ width: "70%", margin: "auto" }}
                      locale={{ emptyText: "Seçilen hammade bulunmamaktadır..." }}
                      dataSource={item.stock}
                      columns={columns}
                      showHeader={true}
                      pagination={false}
                      className="custom-news-table"
                      rowClassName="custom-news-row"
                    />
                  </>
                ) : (
                  <div style={{ fontWeight: "bold", color: "gray", marginTop: "10px" }}>
                    Seçilen hammade bulunmamaktadır...
                  </div>
                )}

                {item.wastage?.length > 0 ? (
                  <>
                    <div style={{ fontWeight: "bold", color: colors.blue, marginTop: "10px" }}>
                      Seçilen Fireler
                    </div>
                    <Table
                      style={{ width: "70%", margin: "auto" }}
                      locale={{ emptyText: "Seçilen fire bulunmamaktadır..." }}
                      dataSource={item.wastage}
                      columns={columns}
                      showHeader={true}
                      pagination={false}
                      className="custom-news-table"
                      rowClassName="custom-news-row"
                    />
                  </>
                ) : (
                  <div style={{ fontWeight: "bold", color: "gray", marginTop: "10px" }}>
                    Seçilen fire bulunmamaktadır...
                  </div>
                )}
              </div>
            )}
          </div>
          ))}
        </div>
        </>
      ) : (
        <></>
      )}

    </div>
    
       )}
         {loading && (
                       <div style={{
                           position: 'fixed',
                           top: 0,
                           left: 0,
                           width: '100%',
                           height: '100%',
                           backgroundColor: 'rgba(0, 0, 0, 0.5)',
                           display: 'flex',
                           alignItems: 'center',
                           justifyContent: 'center',
                           zIndex: 1000,
                       }}>
                           <Spin/>
                       </div>
                   )}
   </>
   
  )};
export default OpenOrderDetail;
