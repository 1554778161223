import React,{useEffect,useState} from "react";
import { Button, Form, Input,Table ,Checkbox, DatePicker,Progress, Spin} from 'antd';
import NotificationService from "../../../services/antNotificationService";
import { useLocation  } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import { useNavigate } from 'react-router-dom';
import  {colors,parsCollections}  from "../../../environments/environment"
import OneSignalService from "../../../services/oneSignalService";
import moment from 'moment';

const SetupPlatesDetail = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isScrapDelivery, setIsScrapDelivery] = useState(false);
    const [qualities, setQualities] = useState([]); 
    const [code, setCode] = useState(''); 
    const [loading, setLoading] = useState(false);
    const today = moment().startOf('day'); 
    const [plates, setPlates] = useState(null); 

    const disabledDate = (current) => {
      return current && current < today;
    }
    const data = location.state && location.state.data;
    const xmlInfo = location.state && location.state.xmlInfo;
     
    const parts = location.state && location.state.xmlInfo.parts;
   const priorityAbkant = parts && parts.some(part => 
        part.operations && part.operations.some(operation => operation.toLowerCase() === "abkant")
  ) ? 999999999 : null;

    const setupDataa = location.state && location.state.setupData;
    const record = location.state && location.state.record;

   const [additionalContents, setAdditionalContents] = useState([]);
   const [isLoading, setIsLoading] = useState(true);
  
     
    useEffect(() => {
      window.scrollTo(0, 0);
      setIsLoading(true); 
        const fetchData = async () => {
          const qualities = await FirebaseService.getDensitiesData();
          const filteredQualities = qualities.filter(item => !item.isDeleted);
          setQualities(filteredQualities);
          const code= generateRandomCode(5);
          setCode(code);

          if (setupDataa && setupDataa.plates) {
              const hasImageUrl = setupDataa.plates.some(plate => plate.imageUrl);
          
              if (hasImageUrl) {
                  setPlates(setupDataa.plates);
              } else {
                  const plates = location.state && location.state.xmlInfo.plates;
                  setPlates(plates);
              }
          } else {
              const plates = location.state && location.state.xmlInfo.plates;
              setPlates(plates);
          }
          
          setIsLoading(false);

        };
        fetchData();
    }, []);

    function generateRandomCode(length) {
      const characters = '0123456789';
      let result = '';
    
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters[randomIndex];
      }
    
      return result;
    }


    const addAdditionalContent = (plateIndex) => {
      setAdditionalContents((prevContents) => {
        const newContents = [...prevContents];
        newContents[plateIndex] = [...(newContents[plateIndex] || []), { width: '', height: '', number: 1 }];
        return newContents;
      });
    };
  
    const removeAdditionalContent = (plateIndex, contentIndex) => {
      setAdditionalContents((prevContents) => {
        const newContents = [...prevContents];
        newContents[plateIndex].splice(contentIndex, 1);
        return newContents;
      });
    }
    
    const isDateBeforeToday = (dateString) => {
      const selectedDate = new Date(dateString);
      const today = new Date(); 
        return selectedDate < today;
    };

    const getOneSignalPlayerIds = async () => {
      try {
        const dataCollection = await FirebaseService.getUserData();
        const filteredUsers = dataCollection.filter(user => !user.isDeleted && user.userUnit.includes("satış"));
                
        const oneSignalPlayerIds = [];
        filteredUsers.forEach(user => {
          if (user.oneSignalPlayerId) {
            oneSignalPlayerIds.push(user.oneSignalPlayerId);
          }
        });
                
        return oneSignalPlayerIds;
      } catch (error) {
        return [];
      }
    };

    const getOneSignalUserIds = async () => {
      try {
        const dataCollection = await FirebaseService.getUserData();
        const filteredUsers = dataCollection.filter(user => !user.isDeleted && user.userUnit.includes("satış"));
        
        const oneSignalPlayerIds = [];
        filteredUsers.forEach(user => {
          if (user.key) {
            oneSignalPlayerIds.push(user.key);
          }
        });
                
        return oneSignalPlayerIds;
      } catch (error) {
        return [];
      }
    };

    const getOneSignalPlayerIdsArchieve = async () => {
      try {
        const dataCollection = await FirebaseService.getUserData();
        const filteredUsers = dataCollection.filter(user => !user.isDeleted && user.userUnit.includes("depo"));
        
        
        const oneSignalPlayerIds = [];
        filteredUsers.forEach(user => {
          if (user.oneSignalPlayerId) {
            oneSignalPlayerIds.push(user.oneSignalPlayerId);
          }
        });
                
        return oneSignalPlayerIds;
      } catch (error) {
        return [];
      }
    };

    const getOneSignalUserIdsArchieve = async () => {
      try {
        const dataCollection = await FirebaseService.getUserData();
        const filteredUsers = dataCollection.filter(user => !user.isDeleted && user.userUnit.includes("depo"));
        
        
        const oneSignalPlayerIds = [];
        filteredUsers.forEach(user => {
          if (user.key) {
            oneSignalPlayerIds.push(user.key);
          }
        });
        
        
        return oneSignalPlayerIds;
      } catch (error) {
        return [];
      }
    }; 

    const getOneSignalPlayerIdsBuyer= async () => {
      try {
        const dataCollection = await FirebaseService.getUserData();
        const filteredUsers = dataCollection.filter(user => !user.isDeleted && user.userUnit.includes("satın alma"));
        
        
        const oneSignalPlayerIds = [];
        filteredUsers.forEach(user => {
          if (user.oneSignalPlayerId) {
            oneSignalPlayerIds.push(user.oneSignalPlayerId);
          }
        });
        
        
        return oneSignalPlayerIds;
      } catch (error) {
        console.error("Error getting OneSignal Player IDs:", error);
        return [];
      }
    };

    const getOneSignalUserIdsBuyer= async () => {
      try {
        const dataCollection = await FirebaseService.getUserData();
        const filteredUsers = dataCollection.filter(user => !user.isDeleted && user.userUnit.includes("satın alma"));
        
        
        const oneSignalPlayerIds = [];
        filteredUsers.forEach(user => {
          if (user.key) {
            oneSignalPlayerIds.push(user.key);
          }
        });
        
        
        return oneSignalPlayerIds;
      } catch (error) {
        console.error("Error getting OneSignal Player IDs:", error);
        return [];
      }
    };

    const checkItems = async (record) => {
    
      let isAllSuccessful = true;
    
      // Check and update stock items
      for (const stockItem of record.stock) {
        const { id } = stockItem;
    
        const stockTableItem = await FirebaseService.getStockItemById(id);
    
        if (stockTableItem) {
        
          if (stockTableItem.reservedPiece <= 0 ||  (stockTableItem.reservedPiece < stockItem.piece)  ) {            
            if (stockTableItem.piece <= 0 || stockTableItem.piece < stockItem.piece) {
              isAllSuccessful = false;
              break; 
            }
      
            const result = await FirebaseService.updateStockReservedPiece(id, stockItem.piece);
            if (result.success) {
              console.log("Stock item successfully reserved.");
            } else {
              NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: `Parça ID: ${id} güncellenemedi. Hata: ${result.message}`,
                placement: 'topRight',
              });
              isAllSuccessful = false;
              break;
            }
          }
        } else {
          console.log(`Warning: No item found in stock table for id ${id}`);
          isAllSuccessful = false;
          break; 
        }
      }
    
      if (!isAllSuccessful) return false;
    
    for (const wastageItem of record.wastage) {
        const { id } = wastageItem;
    
        const wastageTableItem = await FirebaseService.getWastageItemById(id);
    
        if (wastageTableItem) {
          
          const totalAvailable = wastageTableItem.piece + wastageTableItem.comingPiece; 
          const totalReserved = wastageTableItem.reservedPiece + wastageTableItem.reservedComingPiece; 


          if ((wastageTableItem.reservedPiece <= 0  &&  wastageTableItem.reservedComingPiece <= 0)  || (totalReserved  < wastageItem.piece) ) {
            console.log(`Warning: The reservedPiece for wastage item with id ${id} is not greater than 0.`);
    
            if (totalAvailable <= 0 &&  totalAvailable < wastageItem.piece ) {
              isAllSuccessful = false;
              break; 
            }

            const result = await FirebaseService.updateWastageReservedPiece(id, wastageItem.piece);
            if (result.success) {
              console.log("wastage başarılı reserve güncellensin");
            } else {
              NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: `Parça ID: ${id} güncellenemedi. Hata: ${result.message}`,
                placement: 'topRight',
              });
              isAllSuccessful = false;
              break; 
            }
          }
        } else {
          console.log(`Warning: No item found in wastage table for id ${id}`);
          isAllSuccessful = false;
          break; 
        }
      }
    
      return isAllSuccessful;
    };
    
    async function getPartData(jobCode, partNo, customerName) {
      const partsData = await FirebaseService.getPartsData(); 
    
      return partsData.find(
        part =>
          part.jobCode === jobCode &&
          part.partNo === partNo &&
          part.customerName === customerName
      );
    }
    

    const onFinish = async (values) => {   
      setLoading(true);
    
  
      let onlyJobCode = record.jobCode.split('-')[0];
      const allOpenOrder = await FirebaseService.getOpenOrderData();
      const filteredOpenOrder = allOpenOrder.find(item => 
        !item.isDeleted && 
        item.isWaiting && 
        item.isOffer && 
        item.isOfferApproved && 
        item.orderType === "offer" && 
        item.jobCode.includes(onlyJobCode)
      );
        

      const filteredOpenOrderFirstOffer = allOpenOrder.find(item => 
        !item.isDeleted && 
        item.isWaiting && 
        item.orderType === "offer" && 
        item.jobCode ===onlyJobCode &&
        !item.isSetup &&
        item.setupName ===null

      );      

      const filteredOpenOrderSame = allOpenOrder.find(item => 
        !item.isDeleted && 
        item.isWaiting && 
        item.jobCode ===record.jobCode 
      );  
      
      //  sipariş seçip tüm adımları yaptysa stoktan düşme vs veya teklif onaylandıysa 
      if (
        (record.isSetup !== false && record.orderType === "order") || 
        ((filteredOpenOrder &&  filteredOpenOrder.isOfferApproved && record.orderType === "offer"))
      )
        {
        const requestedItems = record.stock.filter(stockItem =>
          !record.checkedItemsStockExcess.some(item =>
            item.id === stockItem.id &&
            item.quality === stockItem.quality &&
            item.height === stockItem.height &&
            item.width === stockItem.width &&
            item.piece === stockItem.piece &&
            item.thickness === stockItem.thickness &&
            item.surface === stockItem.surface
          )
        );

        if (record.isReserved === false || record.isReserved === undefined) { 
          let updatedCheckedItemsStock;
          
          if (requestedItems && requestedItems.length > 0) {
            for (const x of requestedItems) {
              const { id, piece } = x;
              const result = await FirebaseService.updateStockReservedPiece(id, piece);
              if (result.success) {
                x.isReserved = true; 
              }
              else {
              NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: `Hata: ${result.message}`,
                placement: 'topRight',
              });
              setLoading(false);
              return; 
            }
            }
            updatedCheckedItemsStock = requestedItems.map(stockItem => ({
              ...stockItem,
              isReserved: stockItem.isReserved,
            
            }));
            
          }
        
          let updatedCheckedItemsWastage;
          
          if (record.wastage && record.wastage.length > 0) {
            for (const x of record.wastage) {
              const { id, piece } = x;
              const result = await FirebaseService.updateWastageReservedPiece(id, piece);
              if (result.success) {
                x.isReserved = true; 
              }
              else {
                NotificationService.openErrorNotification({
                  title: 'İşlem Başarısız',
                  description: `Hata: ${result.message}`,
                  placement: 'topRight',
                });
                setLoading(false);
                return; 
              }
            }
        
            updatedCheckedItemsWastage =  record.wastage.map(wastageItem => ({
              ...wastageItem,
              isReserved: wastageItem.isReserved, // Apply the latest `isReserved` state
            
            }));
          }
        } else {
          console.log("daha önce rezerve edilmiş bu adım geçiliyor")
        }
        

      }

      if( record.orderType === "order"){
        //tekrar kontrol edilyor rezerveleer
        const againCheckReserve =  await checkItems(record)
        if(!againCheckReserve){
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "Rezerve edilemedi. Rezerve etmek istediğiniz tükenmiş olabilir lütfen başka seçiniz.",
            placement: 'topRight'
        });
        setLoading(false);
        return;
        }
    }
      const currentDate = new Date();
      const groupedParts = {};


      //fason üretime ekleme alanı hem siparişse hemde teklif onaylandıysa 
      if (record.orderType === "order" || (filteredOpenOrder && filteredOpenOrder.isOfferApproved)) {

        parts.forEach((part) => {
          const { partNo, operations } = part;
            operations.forEach((operation) => {
            if (!groupedParts[operation]) {
              groupedParts[operation] = {
                operation: operation, 
                parts: [], 
              };
            }
            groupedParts[operation].parts.push({
              partCode:part.partNo,
              piece: part.piece,
              unitPrice:null,
              profit:null
              
            });
          });
        });
    
    
        Object.values(groupedParts).forEach(async (group) => {
        
          if (group.operation.toLowerCase() === "abkant") {
            return; 
          }
          
          const data = {
            firstTransactionDate: currentDate,
            firstUserId: localStorage.getItem("uid"),
            isDeleted: false,
            companyName: record.companyName,
            supplierName: null,
            jobCode: record.jobCode,
            projectCode: record.projectCode,
            deadline: null,
            openOrderDeadline: new Date(record.deadline.seconds * 1000),
            processName: group.operation,
            isContractManufacturing: false,
            isShipment: false,
            isRequestCompleted: false,
            parts: group.parts, 
            isCompleted:false,
            code:code,
            invoiceNumber:null
          };
    
          try {
            const result = await FirebaseService.addRecording(parsCollections.contractManufacturing, data);
            if (result.success) {
           
              const ids = await getOneSignalPlayerIdsBuyer();
              const userIds = await getOneSignalUserIdsBuyer();

              const sentUserIdsMap = new Map();
              userIds.forEach(userId => {
                sentUserIdsMap.set(userId, false);
              });
              
              const sentUserIds = Object.fromEntries(sentUserIdsMap);

              if (ids.length > 0) {
                  const oneSignalResult = await OneSignalService.sendNotification("", "Fason içeren Açık Sipariş", ids);
                 
                  const data = {
                    firstTransactionDate: currentDate,
                    firstUserId: localStorage.getItem("uid"),
                    isDeleted: false,
                    sentUserIds:sentUserIds,
                    firstUserNameSurname: localStorage.getItem('name') + " " +localStorage.getItem('surname'),
                    unitName:localStorage.getItem('userUnit'),
                    title:"Fason içeren Açık Sipariş",
                    companyName:record.companyName,
                    jobCode:record.jobCode,
                    projectCode:record.projectCode,
                    machine:null,
                    type:null,
                    notice:"Fason içeren açık sipariş yüklenmiştir, kontrol ediniz.",
                    description:null

                  };

                  const result = await FirebaseService.addRecording(parsCollections.notifications,data);
              } else {
                  console.log("No OneSignal Player IDs found.");
              }


            } else {
              NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
                placement: 'topRight'
              });
              return;
            }
          } catch (error) {
            NotificationService.openErrorNotification({
              title: 'İşlem Başarısız',
              description: "Bilinmeyen bir hata ile karşılaşıldı.",
              placement: 'topRight'
            });
            setLoading(false);
            return;
          }
          
        });
    
        if(record.isPlazma){
          const data = {
            firstTransactionDate: currentDate,
            firstUserId: localStorage.getItem("uid"),
            isDeleted: false,
            companyName: record.companyName,
            supplierName: null,
            jobCode: record.jobCode,
            projectCode: record.projectCode,
            deadline: null,
            openOrderDeadline: new Date(record.deadline.seconds * 1000),
            processName: "plazma",
            isContractManufacturing: false,
            isShipment: false,
            isRequestCompleted: false,
            parts: null, 
            isCompleted:false,
            code:code,
            invoiceNumber:null

          };
    
          try {
            const result = await FirebaseService.addRecording(parsCollections.contractManufacturing, data);
            if (result.success) {
              const ids = await getOneSignalPlayerIdsBuyer();
              const userIds = await getOneSignalUserIdsBuyer();
              const sentUserIdsMap = new Map();
              userIds.forEach(userId => {
                sentUserIdsMap.set(userId, false);
              });
              
              const sentUserIds = Object.fromEntries(sentUserIdsMap);
              if (ids.length > 0) {
                  const oneSignalResult = await OneSignalService.sendNotification("", "Fason içeren Açık Sipariş", ids);
                 
                  const data = {
                    firstTransactionDate: currentDate,
                    firstUserId: localStorage.getItem("uid"),
                    isDeleted: false,
                    sentUserIds:sentUserIds,
                    firstUserNameSurname: localStorage.getItem('name') + " " +localStorage.getItem('surname'),
                    unitName:localStorage.getItem('userUnit'),
                    title:"Fason içeren Açık Sipariş",
                    companyName:record.companyName,
                    jobCode:record.jobCode,
                    projectCode:record.projectCode,
                    machine:null,
                    type:null,
                    notice:"Fason içeren açık sipariş yüklenmiştir, kontrol ediniz.",
                    description:null

                  };
                  const result = await FirebaseService.addRecording(parsCollections.notifications,data);
              } else {
                  console.log("No OneSignal Player IDs found.");
              }
    
            } else {
              NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
                placement: 'topRight'
              });
              setLoading(false);
              return;
            }
          } catch (error) {
            NotificationService.openErrorNotification({
              title: 'İşlem Başarısız',
              description: "Bilinmeyen bir hata ile karşılaşıldı.",
              placement: 'topRight'
            });
            setLoading(false);
            return;
          }
        }
    
      }

         
      const expectedWastages = [];
      let density = 1;
      const selectedQuality = qualities.find(item => item.quality === data.quality);
      if (selectedQuality) {
          density = selectedQuality.density;
      }
  
      const handleFinish = async (plateIndex) => {
        const plate = plates[plateIndex];
        const contents = additionalContents[plateIndex]?.length || 0; 
        let wastagesKg = 0; 
    
        for (let contentIndex = 0; contentIndex < contents; contentIndex++) {
            const widthValues = values[`width-${plateIndex}-${contentIndex}-programNo-${plate.programNo}`];
            const heightValues = values[`height-${plateIndex}-${contentIndex}-programNo-${plate.programNo}`];
            const numberValues = values[`number-${plateIndex}-${contentIndex}-programNo-${plate.programNo}`];

           
    
            const area = widthValues * heightValues;
            const weight = area * Number(data.thickness) * density; 
            const weightInKg = (weight / 1000000) * plate.piece * Number(numberValues); 

            if (!isNaN(weightInKg) && weightInKg !== 0) {
              expectedWastages.push({
                  width: Number(widthValues),
                  height: Number(heightValues),
                  programNo: plate.programNo,
                  kg: weightInKg,
                  piece: plate.piece * Number(numberValues),
                  depotName:plate.depotName ? plate.depotName  : null
              });
      
          
              wastagesKg += weightInKg;
          } else {
              console.log(`Invalid weight calculated for plate ${plate.programNo}`);
          }
        }
    
        const matchingPlates = plates.filter(p => p.programNo === plate.programNo);
        matchingPlates.forEach(matchingPlate => {
          matchingPlate.wastagesKg = wastagesKg; 
          matchingPlate.kg =  matchingPlate.kg-wastagesKg; 
          matchingPlate.wastage = expectedWastages.filter(w => w.programNo === plate.programNo); 
          matchingPlate.jobCode =  record.jobCode; 

        });
      };
     
      let totalKg = 0;
      const plateCount = plates.length;
      let descriptionValues = null;
      for (let plateIndex = 0; plateIndex < plateCount; plateIndex++) {
            await handleFinish(plateIndex);
            const plate = plates[plateIndex];
            descriptionValues = values[`description-${plateIndex}-${plate.programNo}`];
            plates[plateIndex].description = descriptionValues ? descriptionValues : null;
            plates[plateIndex].status = "Kesilecek";
            plates[plateIndex].isFinish = false;
            totalKg +=  plates[plateIndex].kg 

      }

      //parts tablsouna eklemek
      const updatedParts = xmlInfo.parts.map(item => ({
        ...item,
       // jobCode :record.jobCode,
       // projectCode :record.projectCode ? record.projectCode : null ,
        //companyName :record.companyName,
        operations: item.operations.map(operation => ({
          processName: operation,
          isContractManufacturing: false,
          isCompleted: false,
          isQrScan:false,
          firstUserNameSurname: null,
          firstUserId:null
        }))
      }));    
          
        //teklifse wastage tablosuna eklenmicek. sadece siparişse eklencek yada teklif onaylandıysa 
      if(record.orderType === "order" ||   ( (filteredOpenOrder && filteredOpenOrder.isOfferApproved && record.orderType === "offer")) ){
          const wastageData = await FirebaseService.getWastagesData();
          for (const wastage of expectedWastages) {
            const width = Number(wastage.width);
            const height = Number(wastage.height);
            const thickness = Number(data.thickness);
            const unitKilo = (width * height * thickness * density) / 1000000;
            const totalKilo = unitKilo * wastage.piece;
          
            const existingWastage = wastageData.find(
              (item) =>
                item.surface === data.surface &&
                item.width === width &&
                item.height === height &&
                item.depotName === wastage.depotName &&
                item.thickness === thickness &&
                item.quality === data.quality &&
                item.companyName === record.companyName
            );
          
            if (existingWastage) {
              // Update the existing record
              const updatedPiece = existingWastage.piece + wastage.piece;
              const updatedTotalKilo = existingWastage.unitKilo * updatedPiece;
          
              await FirebaseService.updateRecording(parsCollections.wastages, existingWastage.key, {
               // piece: updatedPiece,
               // totalKilo: updatedTotalKilo,
                comingPiece: wastage.piece,
                isQRScanned: false
              });
            } else {

              const wastageItem = {
                firstTransactionDate: currentDate,
                firstUserId: localStorage.getItem("uid"),
                firstUserNameSurname: localStorage.getItem('name') + " " + localStorage.getItem('surname'),
                isDeleted: false,
                isQRScanned: false,
                companyName: record.companyName,
                depotName: wastage.depotName || null,
                jobCode: record.jobCode,
                width,
                height,
                thickness,
                quality: data.quality,
                surface: data.surface,
                imageUrl: null,
                description: "Teknik ressam tarafından setuptan eklenmiştir.",
                piece: 0,
                reservedPiece: 0,
                unitKilo,
                totalKilo,
                comingPiece: wastage.piece,
                reservedComingPiece: 0
              };

              const result = await FirebaseService.addRecording(parsCollections.wastages, wastageItem);
              wastage.docId = result.docId;
            }
          }
          
         
      }
       
      const priorityMachine = 999999999 ;

      let abkantBreaks= {
        userId:null,
        nameSurname:null,
        reason:null,
        firstTransactionDate:null,
        lastTransactionDate:null,
        description:null
      }
     

      const item = {
          firstTransactionDate: currentDate,
          firstUserId: localStorage.getItem("uid"),
          programmerName: localStorage.getItem("name") + " " + localStorage.getItem("surname"),
          isDeleted: false, 
          thickness :data.thickness,
          quality :data.quality,
          surface :data.surface,
          fuel: data.fuel,
          plateCount:plateCount,
          setupTime :xmlInfo.totalRuntime,
          setupName :record.jobCode.substring(6),
          machine :xmlInfo.machine,
          jobCode :record.jobCode,
          projectCode :record.projectCode ? record.projectCode : null ,
          companyName :record.companyName,
          stock:record.stock ? record.stock : [] , //eğer teklifse stock boş gelir 
          wastage:record.wastage ? record.wastage : [] , //eğer teklifse wastage boş gelir 
          description: values.description ?  values.description : null,
          isScrapDelivery : isScrapDelivery,
          parts:setupDataa.parts,
          plates:plates,
          expectedWastages:expectedWastages, 
          documentUrl : setupDataa.documentUrl ?  setupDataa.documentUrl : null,  //boş olabilir 
          priorityMachine: priorityMachine,
          priorityAbkant:priorityAbkant,
          isCustomerRaw: data.isCustomerRaw,
          isPlazma : record.isPlazma,
          totalKg:totalKg,
          abkantBreaks:abkantBreaks,
          abkantTwistShape:null,
          abkantUserCount:0,
          abkantActualTime:null,
          abkantIsFinish:false,
          setupDescription:values.description ?  values.description :null,
          isShipment:false,
          isMerge:false,
          deadline: new Date(record.deadline.seconds * 1000),
      };

      
    //usera perfomans ekleme setup eklediğinde 
    await FirebaseService.updateUserPerformance(localStorage.getItem("uid"), new Date(), xmlInfo.totalRuntime)
      .then(response => console.log(response))
      .catch(error => console.error(error));
    
     
   const result = await FirebaseService.updateRecording(parsCollections.setup, setupDataa.key, item);
   if (result.success) {
   //parçalar eklencek
     for (const part of setupDataa.parts) {  
              try {
                const existingPart = await getPartData(part.jobCode, part.partNo, part.customerName);
                
                if (existingPart) {
                  let resultPart= await FirebaseService.updateRecording(
                    parsCollections.parts,
                    existingPart.key,
                    { ...part,
                      operations: (part.operations && Array.isArray(part.operations)) 
                      ? part.operations.map(operation => ({
                          processName: operation,
                          isContractManufacturing: false,
                          isCompleted: false,
                          isQrScan: false,
                          firstUserNameSurname: null,
                          firstUserId: null
                        }))
                      : [], 
                      firstTransactionDate:new Date()
                    } 

                  );

                  if (resultPart) {
                    console.log(`Part with jobCode ${part.jobCode}, partNo ${part.partNo}, and customerName ${part.customerName} successfully updated.`);
                  } else {
                    console.log(`Failed to update part with jobCode ${part.jobCode}, partNo ${part.partNo}, and customerName ${part.customerName}.`);
                  }
                } else {
                  console.log(`No part found with jobCode ${part.jobCode}, partNo ${part.partNo}, and customerName ${part.customerName} to update.`);
                }
              } catch (error) {
                console.error(`Error updating part with jobCode ${part.jobCode}, partNo ${part.partNo}, and customerName ${part.customerName}:`, error);
              }
     }
        
    } else {
          
            NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.2",
                placement: 'topRight'
            });
            setLoading(false);
            
   }
      


   //open order için update geçilmeli sadece
    
   if(filteredOpenOrderSame){
        let updateOpenOrderItem = {
            isSetup:true,
           
            setupName:record.jobCode.substring(6),
            jobCode :record.jobCode,//5 hane +setup ismi olması lazım
            machine: xmlInfo.machine

      };

      const resultx = await FirebaseService.updateOpenOrder(filteredOpenOrderSame.key, updateOpenOrderItem);
      if(resultx){
        
          NotificationService.openSuccessNotification({
            title: 'İşlem Başarılı',
            description:"Başarıyla kaydedilmiştir.",
            placement: 'topRight'
          });
          setLoading(false);
          setTimeout(() => {
           // navigate("/programmerpage");
            navigate('/waitingopenorderdetail', { state: { record } })

          }, 500);
          
      }
      else{
          
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.1",
            placement: 'topRight'
          });
          
          setLoading(false);
      }  
      
      
    }

    else if(filteredOpenOrderFirstOffer){

      let updateOpenOrderItem = {
            isSetup:true,
            setupName:record.jobCode.substring(6),
            jobCode :record.jobCode,
            machine: xmlInfo.machine

          };
        
        

      const resultx = await FirebaseService.updateOpenOrder(filteredOpenOrderFirstOffer.key, updateOpenOrderItem);
      if(resultx){
        
          NotificationService.openSuccessNotification({
            title: 'İşlem Başarılı',
            description:"Başarıyla kaydedilmiştir.",
            placement: 'topRight'
          });
          setLoading(false);
          setTimeout(() => {
           // navigate("/programmerpage");
            navigate('/waitingopenorderdetail', { state: { record } })

          }, 500);
          
      }
      else{
          
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.1",
            placement: 'topRight'
          });
          
          setLoading(false);
      }  
    }
  
        
  }; 
  const openPdf = () => {
    if (setupDataa.documentUrl) {
      window.open(setupDataa.documentUrl, '_blank'); 
    } else {
      console.error("PDF URL bulunamadı.");
    }
  };

   return ( 
    <>
    {isLoading ? (
      <> Yükleniyor.. </>
      ) : (
      
      <Form
    name="basic"
    labelCol={{
    span: 4,
    }}
    wrapperCol={{
    span: 20,
    }}
    style={{
    maxWidth: "100%",
    padding:"5% 5% 0 5%"
    }}
    initialValues={{
    remember: true,
    }}
    onFinish={onFinish}
   // onFinishFailed={onFinishFailed}
    autoComplete="off"
>


    <div style={{ display: "flex", flexDirection: "column",}}>
  
    <h2 style={{ fontWeight: "bold", color: colors.blue, textAlign:"left" }}>Yerleşimler</h2>
          
    <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>Firma</p>
          {record && record.companyName}
        </div>

        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>İş Kodu</p>
          {record && record.jobCode}
        </div>


        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>Setup İsmi</p>
          {xmlInfo && xmlInfo.setupName}
        </div>

        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>Genel Açıklama Ekle </p>
          <Form.Item name="description" >
             <Input  className="input-style"   placeholder="" />
           </Form.Item>  
        </div>

       
        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>
          <Form.Item
              name="isScrapDelivery">
              <Checkbox   onChange={() => setIsScrapDelivery(!isScrapDelivery)} style={{fontWeight:"500"}}>
              Hurda Teslim
              </Checkbox>
            </Form.Item>
          </p>
         
        </div>   
      </div>    

        <div style={{marginTop:"20px",}}>
            {plates.map((plate, plateIndex) => (          
              <>
                <div className="setup-item-plates" key={plateIndex} style={{border:"1px solid", borderColor:colors.blue, justifyContent:"space-between", textAlign:"left"}}>
                  <img src={plate.imageUrl}  alt={plate.imageFileName} style={{ width: '500px', height:"300px" }} />

                   <div style={{display:"flex"}}>
                      <div>
                        <p style={{borderBottom:"1px solid", borderColor:colors.blue}}><strong>Program No:</strong> {plate.programNo}</p>
                        <p style={{borderBottom:"1px solid", borderColor:colors.blue}}><strong>Ebat:</strong> {plate.height} x {plate.width} x {data.thickness} {plate.unit}</p>
                        <p style={{borderBottom:"1px solid", borderColor:colors.blue}}><strong>Süre:</strong> {plate.processingTime} </p>
                        <p style={{borderBottom:"1px solid", borderColor:colors.blue}}><strong>Kg (Plaka Ağırlığı):</strong> {plate.kg}</p>

                        <p style={{borderBottom:"1px solid", borderColor:colors.blue}}><strong>Adet:</strong> {plate.piece} </p> 
                      {/*  <p style={{borderBottom:"1px solid", borderColor:colors.blue}}><strong>Fire Kg:</strong> </p>*/} 

                      <Form.Item
                        name={`description-${plateIndex}-${plate.programNo}`}
                      // label="Açıklama"
                      >
                      <Input.TextArea
                          placeholder="Açıklama Girebilirsiniz"
                          style={{ width: '250px', height: '80px', borderColor: "#1A446C" }}
                        />                      
                        </Form.Item>
                      </div> 

                   </div>     
                       


                       
                   <div style={{border:"1px solid", borderColor:colors.blue, padding:"5px 20px", width:"250px"}}>
                    <p style={{color:colors.blue, fontWeight:"bold"}}>Fire Ekle</p>

                    {additionalContents[plateIndex]?.map((fireItem, contentIndex) => (
                  <div style={{ display: "flex" }} key={contentIndex}>
                    <Form.Item
                      name={`width-${plateIndex}-${contentIndex}-programNo-${plates[plateIndex].programNo}`}
                     
                      rules={[
                        { pattern: /^\d+$/, message: "Sadece rakam girişi yapınız." },
                        { required: true, message: "En girmek zorunludur." },

                        {
                          validator: (_, value) => {
                            if (value && parseInt(value) > plate.width) {
                              return Promise.reject(new Error("En değeri plakanın genişliğinden büyük olamaz."));
                            }
                            return Promise.resolve();
                          }
                        }
                      ]}
                    >
                      <Input style={{ borderRadius: 0, borderColor: "#1A446C80", width: "70px" }} placeholder="En" />
                    </Form.Item>

                    <Form.Item
                      name={`height-${plateIndex}-${contentIndex}-programNo-${plates[plateIndex].programNo}`}
                      rules={[
                        { pattern: /^\d+$/, message: "Sadece rakam girişi yapınız." },
                        { required: true, message: "Boy girmek zorunludur." },

                        {
                          validator: (_, value) => {
                            if (value && parseInt(value) > plate.height) {
                              return Promise.reject(new Error("Boy değeri plakanın uzunluğundan büyük olamaz."));
                            }
                            return Promise.resolve();
                          }
                        }
                      ]}
                    >
                      <Input style={{ borderRadius: 0, borderColor: "#1A446C80", width: "70px" }} placeholder="Boy" />
                    </Form.Item>

                    <Form.Item
                      name={`number-${plateIndex}-${contentIndex}-programNo-${plates[plateIndex].programNo}`}
                      rules={[
                        { pattern: /^\d+$/, message: "Sadece rakam girişi yapınız." },
                        
                      ]}
                      initialValue={1} 
                    >
                      <Input style={{ borderRadius: 0, borderColor: "#1A446C80", width: "70px" }} placeholder="Adet" />
                    </Form.Item>

                    <Button onClick={() => removeAdditionalContent(plateIndex, contentIndex)}>-</Button>
                  </div>
                ))}
                 

                <Form.Item style={{ textAlign: "center" }}>
                  <Button onClick={() => addAdditionalContent(plateIndex)}>+</Button>
                </Form.Item>


                  </div>


                </div>
                
             </>   
            ))}
        </div>
        <div style={{display:"flex",marginTop:"20px", justifyContent:"end"}}>
        <Button onClick={openPdf} style={{marginTop:"20px", backgroundColor:colors.blue,color:"white",fontWeight:"500"}}>Pdf Görüntüle</Button>

      <Form.Item style={{textAlign:"right",marginTop:"20px"}}>
          <Button htmlType="submit" style={{backgroundColor:colors.blue,color:"white",fontWeight:"500"}}>Kaydet</Button>
      </Form.Item>
        </div>
       
         {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}

    </div>
      </Form>
        
       )}
   </>

  )};
export default SetupPlatesDetail;


/*
 <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
        <p style={{ fontWeight: "bold", color: colors.blue }}>Termin Tarihi</p>
             <Form.Item
                name="deadline"   >
                <DatePicker   className="input-style"   style={{ width: '100%' }} placeholder="Teslim Tarihi" format="DD-MM-YYYY" disabledDate={disabledDate}  />
             </Form.Item>
        </div>

*/
