import React, { useState, useEffect } from "react";
import { Form, Input, Button, Select, Spin, InputNumber, Row, Col } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import NotificationService from "../../../services/antNotificationService";
import { colors, parsCollections, stockOrWastagee } from "../../../environments/environment";

const AddStockOrWastage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  
  // Stok verilerinden dinamik olarak çekilecek seçenekler
  const [surfaceOptions, setSurfaceOptions] = useState([]);
  const [qualityOptions, setQualityOptions] = useState([]);
  const [companyNameOptions, setCompanyNameOptions] = useState([]);
  const [supplierNameOptions, setSupplierNameOptions] = useState([]);
  const [depotNameOptions, setDepotNameOptions] = useState([]);
  
  // Stok verilerini yükleme
  useEffect(() => {
    const fetchStockData = async () => {
      const stockData = await FirebaseService.getStocksData();
      const densitiesData = await FirebaseService.getDensitiesData(); // Density verilerini çek
      const companiesData = await FirebaseService.getCompaniesData(); // Company ve depot verileri
      const mailsData = await FirebaseService.getMailsData(); // Supplier verileri

      console.log("mailsData", mailsData)

      // Benzersiz surface değerlerini alalım
      const surfaceSet = new Set();
      const qualitySet = new Set();
      const companyNameSet = new Set();
      const supplierNameSet = new Set();
      const depotNameSet = new Set();

      stockData.forEach(item => {
        if (item.surface) surfaceSet.add(item.surface);
        if (item.quality) {
          qualitySet.add(item.quality); // Kalite seçeneklerini al
        }
      });

      companiesData.forEach(item => {
        if (item.companyName && !item.isDeleted) companyNameSet.add(item.companyName);
        if (item.companyName && !item.isDeleted) depotNameSet.add(item.companyName);
      });

      // supplierName verilerini mailsData'dan al
      mailsData.forEach(item => {
        if (item.companyName && !item.isDeleted) supplierNameSet.add(item.companyName);
      });

      // Density değerlerini kaliteye göre eşle
      const densityValues = densitiesData.reduce((acc, item) => {
        acc[item.quality] = item.density; // item.quality ve item.density'yi kullan
        return acc;
      }, {});

      const qualityOptionsArray = Array.from(qualitySet).map(quality => ({
        quality,
        density: densityValues[quality] // Varsayılan density
      }));

      setSurfaceOptions([...surfaceSet]);     // Yüzey seçenekleri
      setQualityOptions(qualityOptionsArray);  // Kalite seçenekleri
      setCompanyNameOptions([...companyNameSet]);
      setSupplierNameOptions([...supplierNameSet]);
      setDepotNameOptions([...depotNameSet]);
    };

    fetchStockData();
  }, []);

  const calculateUnitKg = (thickness, width, height, density) => {
    return (width * height * thickness * density) / 1000000; // kg cinsinden
  };


  function generateRandomCode(length) {
    const characters = '0123456789';
    let result = '';
  
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }
    return result;
  }

  const onFinish = async (values) => {
    setLoading(true);

    const unitKg = calculateUnitKg(values.thickness, values.width, values.height, values.density);
    
    const currentDate =new Date()
    const data = {
      firstTransactionDate: currentDate,
      firstUserId: localStorage.getItem("uid"),
      isDeleted: false,
      code: generateRandomCode(5),
      companyName : values.companyName  ? values.companyName : null,
      supplierName : values.supplierName  ? values.supplierName :  values.companyName,
      depotName : values.depotName  ? values.depotName :  null,
      thickness: values.thickness || 0,
      surface: values.surface ? values.surface : null,
      quality: values.quality ? values.quality : null,
      width: values.width  ? values.width :  0,
      height: values.height  ? values.height :  0,
      piece: values.piece  ? values.piece :  0,
      reservedPiece:  0,
      waitingPiece:  0,
      minPiece :0,
      unitKilo:unitKg ,
      totalKilo:0,
      waybillNumber:0,
      problemDescription: values.description  ? values.description : null,
      isProblem:true,
      problemImage:null,
      problemType:"İş için - seçmeyiniz",
      reservedComingPiece: 0
    //  comingPiece  oalrak ekle . piece 0 olcak  stok ve wastage aynı
    };



    try {

      if(values.productType.toLowerCase() === "stock"){
        await FirebaseService.addRecording(parsCollections.stock, data); 
        NotificationService.openSuccessNotification({
          title: 'Başarılı',
          description: 'Hammade başarıyla eklendi.',
          placement: 'topRight'
        });
        setLoading(false);
        navigate('/programmerpage');
      }
      else{
        await FirebaseService.addRecording(parsCollections.wastages, data); 
        NotificationService.openSuccessNotification({
          title: 'Başarılı',
          description: 'Fire başarıyla eklendi.',
          placement: 'topRight'
        });
        setLoading(false);
        navigate('/programmerpage');

      }

   
    } catch (error) {
      NotificationService.openErrorNotification({
        title: 'Hata',
        description: 'Başarısız. Lütfen tekrar deneyin.',
        placement: 'topRight'
      });
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    NotificationService.openErrorNotification({
      title: 'Geçersiz Form',
      description: 'Lütfen zorunlu alanları doldurunuz.',
      placement: 'topRight'
    });
  };

  return (
    <div>
     <Form
  name="basic"
  labelCol={{ span: 10 }}
  wrapperCol={{ span: 15 }}
  style={{ maxWidth: "100%", padding: "5% 0 0 0%" ,}}
  onFinish={onFinish}
  onFinishFailed={onFinishFailed}
  autoComplete="off"
>
  <Row gutter={16}>
    {/* Firma */}
    <Col span={8}>
      <Form.Item
        label="Müşteri Firma"
        name="companyName"
        rules={[{ required: true, message: 'Lütfen müşteri firma seçiniz!' }]}
      >
        <Select
          showSearch
          placeholder="Firma Seçiniz"
          style={{ width: "100%" }}
          filterOption={(input, option) =>
            option.label.toLowerCase().includes(input.toLowerCase())
          }
          className="input-style" 
        >
          {companyNameOptions.map(option => (
            <Select.Option key={option} value={option} label={option}>
              {option}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Col>

    {/* Tedarikçi */}
    <Col span={8}>
      <Form.Item
        label="Tedarikçi"
        name="supplierName"
      >
        <Select
          showSearch
          placeholder="Tedarikçi Seçiniz"
          style={{ width: "100%" }}
          filterOption={(input, option) =>
            option.label.toLowerCase().includes(input.toLowerCase())
          }
          className="input-style" 
        >
          {supplierNameOptions.map(option => (
            <Select.Option key={option} value={option} label={option}>
              {option}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Col>

    {/* Depo */}
    <Col span={8}>
      <Form.Item
        label="Depo"
        name="depotName"
        rules={[{ required: true, message: 'Lütfen depo seçiniz!' }]}
      >
        <Select
          showSearch
          placeholder="Depo Seçiniz"
          style={{ width: "100%" }}
          filterOption={(input, option) =>
            option.label.toLowerCase().includes(input.toLowerCase())
          }
          className="input-style" 
        >
          {depotNameOptions.map(option => (
            <Select.Option key={option} value={option} label={option}>
              {option}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Col>

    <Col span={8}>
      <Form.Item
            label="Hammade/Fire"
            name="productType"
            rules={[{ required: true, message: 'Lütfen tür giriniz!' }]}
          >
            <Select
            showSearch
              className="input-style" 
              placeholder="Hammade/Fire Seçiniz"
              suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
              options={stockOrWastagee.map(item => ({ label: item.label, value: item.value }))}
              filterOption={(input, option) => 
                option.label.toLowerCase().includes(input.toLowerCase()) // Arama için filtreleme
              }
            />
      </Form.Item>
     </Col>


  </Row>

  <Row gutter={16}>
    {/* Kalınlık */}
    <Col span={8}>
      <Form.Item
        label="Kalınlık"
        name="thickness"
        rules={[{ required: true, message: 'Lütfen kalınlık giriniz!' }]}
      >
        <InputNumber style={{ width: "100%" }} placeholder="Kalınlık (mm)"   className="input-style" />
      </Form.Item>
    </Col>

    {/* Kalite */}
    <Col span={8}>
      <Form.Item
        label="Kalite"
        name="quality"
        rules={[{ required: true, message: 'Lütfen kalite seçiniz!' }]}
      >
        <Select
          showSearch
          placeholder="Kalite Seçiniz"
          style={{ width: "100%" }}
          filterOption={(input, option) =>
            option.label.toLowerCase().includes(input.toLowerCase())
          }
          className="input-style" 
        >
          {qualityOptions.map(option => (
            <Select.Option key={option.quality} value={option.quality}>
              {option.quality}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Col>

    {/* Yüzey */}
    <Col span={8}>
      <Form.Item
        label="Yüzey"
        name="surface"
        rules={[{ required: true, message: 'Lütfen yüzey seçiniz!' }]}
      >
        <Select
          showSearch
          placeholder="Yüzey Seçiniz"
          style={{ width: "100%" }}
          filterOption={(input, option) =>
            option.label.toLowerCase().includes(input.toLowerCase())
          }
          className="input-style" 
        >
          {surfaceOptions.map(option => (
            <Select.Option key={option} value={option}>
              {option}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Col>
  </Row>

  {/* Diğer Alanlar */}
  <Row gutter={16}>
    <Col span={8}>
      <Form.Item
        label="En"
        name="width"
        rules={[{ required: true, message: 'Lütfen giriniz!' }]}
      >
        <InputNumber style={{ width: "100%" }} placeholder="En"   className="input-style" />
      </Form.Item>
    </Col>
    <Col span={8}>
      <Form.Item
        label="Boy"
        name="height"
        rules={[{ required: true, message: 'Lütfen giriniz!' }]}
      >
        <InputNumber style={{ width: "100%" }} placeholder="Boy"  className="input-style"  />
      </Form.Item>
    </Col>
    <Col span={8}>
      <Form.Item
        label="Adet"
        name="piece"
        rules={[{ required: true, message: 'Lütfen giriniz!' }]}
      >
        <InputNumber style={{ width: "100%" }} placeholder="Adet"  className="input-style"  />
      </Form.Item>
    </Col>
  </Row>

  <Row gutter={16}>

  </Row>
  <Form.Item
        label="Açıklama"
        name="description"
        rules={[{ required: true, message: 'Lütfen açıklama giriniz!' }]}
      >
        <Input.TextArea style={{ width: "100%" }} placeholder="Açıklama"  className="input-style" />
      </Form.Item>
    

  <Form.Item wrapperCol={{ offset: 15, span: 18 }}>
    <Button
      htmlType="submit"
      loading={loading}
      style={{backgroundColor:colors.blue,color:"white",fontWeight:"500", textAlign:"right"}}
    >
      Kaydet
    </Button>
  </Form.Item>
      </Form>
      {loading && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000,
        }}>
          <Spin />
        </div>
      )}
    </div>
  );
};

export default AddStockOrWastage;


/*

    <Col span={8}>
      <Form.Item
        label="Rezerve Adet"
        name="reservedPiece"
        rules={[{ required: true, message: 'Lütfen giriniz!' }]}
      >
        <InputNumber style={{ width: "100%" }} placeholder="Rezerve Adet"  className="input-style"  />
      </Form.Item>
    </Col>
    <Col span={8}>
      <Form.Item
        label="Beklenen Adet"
        name="waitingPiece"
        rules={[{ required: true, message: 'Lütfen giriniz!' }]}
      >
        <InputNumber style={{ width: "100%" }} placeholder="Beklenen Adet"   className="input-style" />
      </Form.Item>
    </Col>
     
     

*/