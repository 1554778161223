
import React,{useEffect,useState} from "react";
import { Button, Form, Input,Select,DatePicker, Modal, message,Spin} from 'antd';
import NotificationService from "../../../services/antNotificationService";
import FirebaseService from "../../../services/firebaseService";
import  { parsCollections,colors}  from "../../../environments/environment"
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import AddRequestAdminSupplier from "../addRequestAdmin/addRequestAdminSupplier";
import AddRequestAdminCompany from "../addRequestAdmin/addRequestAdminCompany";
import AddRequestAdminContractManufacturingProcess from "../addRequestAdmin/addRequestAdminContractManufacturingProcess";

const ContractManufacturingProdcutWithAdd = ({ onClose }) => {
    const navigate = useNavigate();
    const [companies, setCompanies] = useState([]); 
    const [process, setProcess] = useState([]); 
    const [jobCodes, setJobCodes] = useState([]); 
    const [code, setCode] = useState(''); 
    const [loading, setLoading] = useState(false);
    const currentDate =new Date()


    useEffect(() => {
      const fetchData = async () => {
        const companies = await FirebaseService.getCompaniesData();
        const filteredCompanies = companies.filter(item => !item.isDeleted);
        setCompanies(filteredCompanies);

        const processes = await FirebaseService.getContractManufacturingProcessesData();
        const filteredProcesses = processes.filter(item => !item.isDeleted);
        setProcess(filteredProcesses);

  
        const jobCodes = await FirebaseService.getOpenOrderData();
        const uniqueJobCodes = new Map();
        const existingPrefixes = new Set(); 

        jobCodes.forEach((item) => {
          
          if (item.jobCode) { 
            const prefix = item.jobCode.substring(0, 5); 
            if (!item.isDeleted && !existingPrefixes.has(prefix)) {
              uniqueJobCodes.set(item.jobCode, item);
              existingPrefixes.add(prefix); 
            }
          }
        });

        const filteredJobCodes = Array.from(uniqueJobCodes.values());
        setJobCodes(filteredJobCodes);

        const code= generateRandomCode(5);
        setCode(code);

      };
      fetchData();
    }, []);
    
    const onFinishFailed = (errorInfo) => {
      NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen zorunlu alanları doldurunuz.',placement:'topRight'})
    };

  function generateRandomCode(length) {
    const characters = '0123456789';
    let result = '';
  
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }
  
    return result;
  }
    
    const onFinish = async (values) => {
      setLoading(true)
      if (values.companyName === "newCompany") {
        NotificationService.openErrorNotification({
          title: 'Hata',
          description: 'Lütfen var olan bir firma seçiniz.',
          placement: 'topRight'
        });
        setLoading(false)
        return;
      }
      if (values.processName === "newContactManufacturingProcess") {
        NotificationService.openErrorNotification({
          title: 'Hata',
          description: 'Lütfen var olan bir süreç seçiniz.',
          placement: 'topRight'
        });
        setLoading(false)
        return;
      }


      const parts = additionalContents.map((content, index) => ({
        partCode: values[`partCode-${index}`] || '',
        unitPrice: values[`unitPrice-${index}`] || null, 
        piece: values[`piece-${index}`] || '', 
        profit: values[`profit-${index}`] || null, 
        processName: values[`processName-${index}`] || '', 


      }));



      const newParts = additionalContents.map((content, index) => {
        const processNames = values[`processName-${index}`] || [];
    
        const operations = processNames.map(processName => ({
            processName: processName.trim(),
            isContractManufacturing: false,
            isCompleted: false,
            isQrScan: false,
            firstUserId: localStorage.getItem("uid") || '',
            firstUserNameSurname:  localStorage.getItem('name') + " " + localStorage.getItem('surname')|| ''
        }));
    
        return {
            partNo: values[`partCode-${index}`] || null,
            jobCode: values.jobCode || null,
            companyName: values.companyName || null,
            piece: Number(values[`piece-${index}`]) || 0,
            operations: operations,
            firstTransactionDate: currentDate,
            projectCode: values.projectCode || null,


            programNo:null,
            width:0,
            thickness:0,   
            customerName:null,
            cadFilePath:null,
            cadFileName:null,
            imageFilePath:null,
            imageFileName:null,
            weightUnit:null,
            timeSetup:0,
            unit:null,
            thicknessSetup:0,
            weightSetup:0,
            quality:null,
            surface:null,
            setupName:null,
            height:0
        };
    });
       
    

      if (parts.length === 0) {
        NotificationService.openErrorNotification({
          title: 'Hata',
          description: 'Lütfen parça seçiniz.',
          placement: 'topRight'
        });
        setLoading(false)
        return;
      }


      for (const part of newParts) {
        
        try {
         let result = await FirebaseService.addRecording(parsCollections.parts, part);
          if (result) {
            console.log(`part with key ${part.key}  successfully.`);
          } else {
            console.log(`Failed to item with key ${part.key}.`);
          }
        } catch (error) {
          console.error(`Error  item with key ${part.key}:`, error);
        }
      }


      const groupByProcess = (data) => {
            return data.reduce((grouped, item) => {
              item.processName.forEach(process => {
                if (!grouped[process]) {
                  grouped[process] = [];
                }
                grouped[process].push(item);
              });
              return grouped;
            }, {});
       };
          
      const groupedParts = groupByProcess(parts);
          
      console.log('Grouped Parts by Process:', groupedParts);
          
     const processPromises = Object.entries(groupedParts).map(async ([processName, parts]) => {
            console.log("Process Name:", processName, "Parts:", parts);


            const partsWithoutProcessName = parts.map(part => {
              const { processName, piece, ...rest } = part; // processName ve piece'i çıkart
              return { ...rest, piece: Number(piece) }; // piece'i number tipine çevir ve geri döndür
          });
          
            const data = {
              firstTransactionDate: currentDate,
              firstUserId: localStorage.getItem("uid"),
              isDeleted: false,
              companyName: values.companyName,
              projectCode: values.projectCode,
              supplierName: null,
              jobCode: values.jobCode ? values.jobCode : null,
              deadline: null,
              openOrderDeadline : null,
              processName: processName, 
              isContractManufacturing: false,
              isShipment: false,
              isRequestCompleted: false,
              parts: partsWithoutProcessName,
              isCompleted: false,
              code:code,
              invoiceNumber:null
            };
          
           
            try {
              const result = await FirebaseService.addRecording(parsCollections.contractManufacturing, data);
              if (result.success) {
                  return { success: true };
              } else {
                  return { success: false, message: result.message };
              }
            } catch (error) {
                return { success: false, message: "Bilinmeyen bir hata ile karşılaşıldı." };
            }
          

          });

          
          
          Promise.all(processPromises)
            .then(results => {
              const allSuccess = results.every((result) => result.success);
              if (allSuccess) {
                  NotificationService.openSuccessNotification({
                      title: 'İşlem Başarılı',
                      description: 'Tüm işlemler tamamlandı.',
                      placement: 'topRight'
                  });
                  setLoading(false)
                  onClose();
                  setTimeout(() => {
                      window.location.reload();
                  }, 2000);
                  
              } else {
                  NotificationService.openErrorNotification({
                      title: 'İşlem Başarısız',
                      description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
                      placement: 'topRight'
                  });
                  setLoading(false)
              }
            })
            .catch(error => {
              NotificationService.openErrorNotification({
                  title: 'İşlem Başarısız',
                  description: "Bilinmeyen bir hata ile karşılaşıldı.",
                  placement: 'topRight'

              });
            setLoading(false)
          });

          

   
    }; 

    const onChange = (value) => {
     if (value === 'newCompany') {
        setIsModalVisibleCompany(true);
      }
     else if (value === 'newContactManufacturingProcess') {
      setIsModalVisibleProcess(true);
    }
      else{
        
      }
  };
 

  const [isModalVisibleCompany, setIsModalVisibleCompany] = useState(false);

  const handleModalCancelCompany = () => {
      setIsModalVisibleCompany(false);
  };

  const handleAddFinishCompany = () => {
      setIsModalVisibleCompany(false);
  };


  const [isModalVisibleProcess, setIsModalVisibleProcess] = useState(false);

  const handleModalCancelProcess = () => {
      setIsModalVisibleProcess(false);
  };

  const handleAddFinishProcess = () => {
      setIsModalVisibleProcess(false);
  };


  const [additionalContents, setAdditionalContents] = useState([]);

  const addAdditionalContent = () => {
    const newContent = {
      partCode: '',
      unitPrice: null,
      piece: '',
      profit:null,
      processName:''
    };
    setAdditionalContents([...additionalContents, newContent]);
  };
  
  const removeAdditionalContent = (contentIndex) => {
    const updatedContents = additionalContents.filter((content, index) => index !== contentIndex);
    setAdditionalContents(updatedContents);
  };
  

   return ( <div>  
         <Form
            name="basic"
            labelCol={{
            span: 8,
            }}
            wrapperCol={{
            span: 15,
            }}
            style={{
            maxWidth: "100%",
            padding:"5% 0 0 5%"
            }}
            initialValues={{
            remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >

        
            <Form.Item
            label="Müşteri Firma"
            name="companyName"
            rules={[
                {
                required: true,
                message: 'Lütfen firma adı giriniz!',
                },
            ]}
            >
            <Select
            showSearch
                onChange={onChange}
                placeholder="Firma Adı Seçiniz"
                suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
                options={[
                  ...companies.map(item => ({ label: item.companyName, value: item.companyName })),
                  { label: 'Yeni Firma Ekle', value: 'newCompany' },
                ]}
                className="input-style" 
                filterOption={(input, option) => 
                  option.label.toLowerCase().includes(input.toLowerCase()) // Arama için filtreleme
                }    

            />
            </Form.Item>

           
            <Modal
                title="Firma Ekleme Talebi"
                visible={isModalVisibleCompany}
                onCancel={handleModalCancelCompany}
                footer={null}
              >
              <AddRequestAdminCompany onClose={handleAddFinishCompany} />
            </Modal>
            
            <Form.Item
             label="İş Kodu"
                name="jobCode"  
                >
                <Select
                showSearch
                    onChange={onChange}
                    placeholder="İş Kodu Seçiniz"
                    suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
                    options={jobCodes.map(item => ({ label: item.jobCode, value: item.jobCode }))}
                    className="input-style" 
                    filterOption={(input, option) => 
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }    

                />
            </Form.Item>

            <Form.Item
            label="Proje Kodu"
             rules={[
              {
              required: true,
              message: 'Lütfen proje kodu giriniz!',
              },
              ]}
                name="projectCode" >
            <Input   
            placeholder="Proje Kodu"
            className="input-style"   
            style={{ width: "100%" }}   

            />
            </Form.Item>

            <Modal
                title="Süreç Ekleme Talebi"
                visible={isModalVisibleProcess}
                onCancel={handleModalCancelProcess}
                footer={null}
              >
              <AddRequestAdminContractManufacturingProcess onClose={handleAddFinishProcess} />
              </Modal>
{/*  
            <Form.Item
            name="description"
            rules={[
                {
                required: true,
                message: 'Lütfen açıklama giriniz!',
                },
            ]}
          
            >
           <Input style={{borderRadius:0, borderColor:"#1A446C80"}} placeholder="Açıklama"/>
            </Form.Item>

     */}       

        <div style={{  }}>
          <p style={{ color: colors.blue, fontWeight: "bold" }}>Parça Ekle</p>

          {additionalContents?.map((content, index) => (
            <div style={{ display: "flex" }} key={index}>
              <Form.Item
                name={`partCode-${index}`}
                rules={[{ required: true, message: "Parça adı" }]}
              >
              
                  <Input
                      onChange={onChange}
                      placeholder="Parça Adı"
                      style={{ width: "200px" }}
                      className="input-style"
                      list="partNo-list"
                    />

              </Form.Item>

              <Form.Item
                name={`piece-${index}`}
                rules={[{ pattern: /^\d+$/, message: "Sadece rakam girişi yapınız.",required: true, }]}
              >
                <Input style={{  width: "70px" }} placeholder="Adet" className="input-style"     />
              </Form.Item>

              <Form.Item
                name={`processName-${index}`}
                rules={[
                    {
                    required: true,
                    message: 'Lütfen proses giriniz!',
                    },
                ]}
                >
                <Select
                showSearch
                mode="tags"
                onChange={onChange}
                placeholder="Proses Seçiniz"
                suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
                /*
                options={[
                  ...process.map(item => ({ label: item.processName, value: item.processName })),
                  { label: 'Yeni Süreç Ekle', value: 'newContactManufacturingProcess' },
                ]}
                */
                options={
                  process.map(item => ({ label: item.processName, value: item.processName }))
                }
                className="input-style"  
                style={{width:"150px"}}  
                filterOption={(input, option) => 
                  option.label.toLowerCase().includes(input.toLowerCase()) // Arama için filtreleme
                } 

            />
            </Form.Item>

              
              <Button onClick={() => removeAdditionalContent(index)}>-</Button>
            </div>
          ))}

          <Form.Item style={{ textAlign: "center" }}>
            <Button onClick={addAdditionalContent}>+</Button>
          </Form.Item>
        </div>

        {code &&  
             <div style={{display:"block"}}>
              <div style={{ color:colors.blue}}> Atanan İş Kodu</div>
              <div style={{ textAlign: 'center', border:"2px solid #2B7199", width:"100px", borderRadius:"10px",padding:"3px 20px", marginRight:"10px"}}> {code}</div>
             </div>
             }

        <Form.Item
            wrapperCol={{
                offset: 15,
                span: 18,
            }}
            style={{textAlign:"right"}}
            >
            <Button htmlType="submit" style={{backgroundColor:colors.blue,color:"white",fontWeight:"500"}}>Gönder</Button>
            </Form.Item>

        </Form>
        {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}
    </div>)

};

ContractManufacturingProdcutWithAdd.propTypes = {
  onClose: PropTypes.func.isRequired, 
};

  export default ContractManufacturingProdcutWithAdd;