import React, { useEffect, useState } from "react";
import { Table, Button, Input, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import NotificationService from "../../../services/antNotificationService";
import { colors } from "../../../environments/environment";
import moment from 'moment';
import { SearchOutlined } from "@ant-design/icons";

const InvoicePurchaseList = () => {
  const [items, setItems] = useState([]);
  const navigate = useNavigate();
  const [groupedItems, setGroupedItems] = useState([]);

  const groupByOfferJobCode = (data) => {
    return data.reduce((groupedData, item) => {
      const { offerJobCode, companyName, deadline, totalKg, supplierName, ...rest } = item;
      const newItem = { offerJobCode, companyName, deadline, totalKg, supplierName, data: [{ ...rest }] };
      if (!groupedData[offerJobCode]) {
        groupedData[offerJobCode] = newItem;
      } else {
        groupedData[offerJobCode].data.push({ ...rest });
      }
      return groupedData;
    }, {});
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      const data = await FirebaseService.getInvoicePurchaseData();
      const filteredData = data.filter((item) => !item.isDeleted);
      const groupedData = Object.values(groupByOfferJobCode(filteredData));
      setItems(filteredData);
      setGroupedItems(groupedData);
    };
    fetchData();
  }, []);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    const value = selectedKeys[0]?.toLowerCase();
    const filteredData = groupedItems.filter((group) => {
      if (dataIndex === 'totalPrice') {
        const totalPrice = group.data?.reduce((acc, dataItem) => acc + (parseFloat(dataItem.price) || 0), 0);
        return totalPrice.toString().toLowerCase().includes(value);
      }
      const fieldValue = group[dataIndex]?.toString().toLowerCase();
      return fieldValue?.includes(value);
    });
    setGroupedItems(filteredData);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setGroupedItems(Object.values(groupByOfferJobCode(items)));
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          id="search-input"
          placeholder={`Ara`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Ara
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Sıfırla
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890FF' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => document.getElementById('search-input').select(), 100);
      }
    },
  });

  const columns = [
    {
      title: 'Sipariş Kod',
      dataIndex: 'offerJobCode',
      key: 'offerJobCode',
      ...getColumnSearchProps('offerJobCode'),
      sorter: (a, b) => a.offerJobCode.localeCompare(b.offerJobCode),
    },
    {
      title: 'Firma',
      dataIndex: 'companyName',
      key: 'companyName',
      ...getColumnSearchProps('companyName'),
      sorter: (a, b) => a.companyName.localeCompare(b.companyName),
    },
    {
      title: 'Tedarikçi',
      dataIndex: 'supplierName',
      key: 'supplierName',
      ...getColumnSearchProps('supplierName'),
      sorter: (a, b) => a.supplierName.localeCompare(b.supplierName),
    },
    {
      title: 'Termin Tarihi',
      dataIndex: 'deadline',
      key: 'deadline',
      render: (text, record) => {
        const formattedDeadline = record.deadline ? new Date(record.deadline.seconds * 1000).toLocaleDateString('tr-TR') : '';
        return <span>{formattedDeadline}</span>;
      },
      sorter: (a, b) => {
        const aDate = a.deadline ? new Date(a.deadline.seconds * 1000) : new Date(0);
        const bDate = b.deadline ? new Date(b.deadline.seconds * 1000) : new Date(0);
        return aDate - bDate;
      },
    },
    {
      title: 'Toplam Fiyat',
      key: 'totalPrice',
      ...getColumnSearchProps('totalPrice'),
      render: (text, record) => {
        const totalPrice = record.data?.reduce((acc, dataItem) => acc + (parseFloat(dataItem.price) || 0), 0);
        return <span>{totalPrice ? totalPrice : 0} TL</span>;
      },
      sorter: (a, b) => {
        const totalPriceA = a.data?.reduce((acc, dataItem) => acc + (parseFloat(dataItem.price) || 0), 0);
        const totalPriceB = b.data?.reduce((acc, dataItem) => acc + (parseFloat(dataItem.price) || 0), 0);
        return totalPriceA - totalPriceB;
      },
    },
    {
      title: ' ',
      dataIndex: 'edit',
      key: 'edit',
      render: (text, record) => (
        <Button
          onClick={() => goDetail(record)}
          style={{ backgroundColor: 'white', color: colors.blue, fontWeight: '500', border: 'none' }}
        >
          Detaya Git
        </Button>
      ),
    },
  ];

  const goDetail = (record) => {
    navigate('/invoicepurchasedetail', { state: { record } });
  };

  return (
    <div
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '94vh',
        padding: '3%',
      }}
    >
      <h3 style={{ textAlign: 'left', color: colors.blue }}>Satın Alma Faturaları</h3>
      <Table
        locale={{
          emptyText: 'Henüz bulunmamaktadır...',
          filterReset: 'Sıfırla',
          filterTitle: 'Filtre Menüsü',
          selectAll: 'Hepsini Seç',
          selectInvert: 'Tersini Seç',
          selectionAll: 'Tümünü Seç',
          sortTitle: 'Sıralama',
          triggerDesc: 'Azalan sıralama için tıklayın',
          triggerAsc: 'Artan sıralama için tıklayın',
          cancelSort: 'Sıralamayı iptal etmek için tıklayın',
        }}
        dataSource={groupedItems}
        columns={columns}
        showHeader={true}
        pagination={false}
        className="custom-news-table"
        rowClassName="custom-news-row"
      />
    </div>
  );
};

export default InvoicePurchaseList;
